import { createSelector } from '@reduxjs/toolkit'

import formsSelectors from '@nickel/forms/redux/selectors'

import { REOPENING_MATCH_ZERO } from '../../screens/ReopeningMatchZero/constants'
import { ReopeningMatchZeroState } from '../../screens/ReopeningMatchZero/types'

/* ------------- Selectors ------------- */

const getReopening = formsSelectors.getFieldValue<ReopeningMatchZeroState>(REOPENING_MATCH_ZERO)

const getReopeningDocumentNumber = createSelector([getReopening], (state) => state?.documentNumber)

const getReopeningDocumentType = createSelector([getReopening], (state) => state?.documentType)

export const selectors = {
    getReopeningDocumentNumber,
    getReopeningDocumentType
}
