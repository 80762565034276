import React, { useCallback, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import Button from '@nickel/core-ui/components/Button'
import { SupportedCountryISO2 } from '@nickel/l10n/types'
import useL10n from '@nickel/l10n/useL10n'

import { getQuitApplicationModalData } from '../../constants/modals/quitApplication'
import { goToExternal, useLocationChangeHandler } from '../../hooks/useNavigation'
import { selectors as navigationSelectors } from '../../redux/navigation'
import { isRootRoute } from '../../redux/navigation/utils'
import { useHomeLocation } from '../../utils'
import useUiModal from '../CustomModal/useModal'

import BackButton from './BackButton'
import Styled from './style'

const Header = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const { handleLocationChanged } = useLocationChangeHandler()
    const isFirstStep = useSelector(navigationSelectors.getIsFirstStep)
    const homeLocation = useHomeLocation()
    const { ISO2 } = useL10n()
    const [route, setRoute] = useState<Record<string, string>>({
        to: location.pathname,
        from: location.pathname
    })

    const { showModal } = useUiModal()
    const onLeave = useCallback(() => {
        if (isFirstStep) goToExternal(homeLocation)
        else showModal(getQuitApplicationModalData(homeLocation))
    }, [homeLocation, isFirstStep, showModal])

    useEffect(() => {
        setRoute((prev: Record<string, string>) => ({ to: location.pathname, from: prev.to }))
    }, [location])

    useEffect(() => {
        if (isRootRoute(location?.pathname) || route.to !== route.from) handleLocationChanged()
    }, [handleLocationChanged, route, location.pathname])

    return (
        <Styled.Header>
            <BackButton />
            {ISO2 === SupportedCountryISO2.PT ? (
                <Styled.LogoPT>
                    <img src="/img/logo-PT.svg" alt="Logo" />
                </Styled.LogoPT>
            ) : (
                <img src="/img/logo.png" alt="Logo" />
            )}
            <Button onClick={onLeave} testId="leaveButton" text={t('quit-application:header-button')} variant="ghost" />
        </Styled.Header>
    )
}

export default Header
