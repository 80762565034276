import { PayloadAction } from '@reduxjs/toolkit'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { actions as stimulusActions, createStimulus, isStimulus, stimulusToUiData } from '@nickel/stimulus/stimulus'
import { actions as uiActions } from '@nickel/stimulus/ui'
import { isTerminal } from '@nickel/utils/lib/web'

import { selectors as barcodeSelectors } from '../../screens/Barcode/slice'
import { InitializeCustomerReopeningView } from '../../services'
import { adultRegistrationFormResourceApi, reopeningCustomerResourceApi } from '../../services/utils'
import { getDeviceType } from '../../utils'
import { actions as authActions } from '../auth'
import { selectors as mailSelectors } from '../mail'
import { actions as navigationActions } from '../navigation'
import { actions as reopeningKycActions } from '../reopeningKyc'
import { http } from '../utils/sagas'

import { actions as reopeningActions, InitializeReopeningPayload } from '.'

function* initialize(action: PayloadAction<InitializeReopeningPayload>) {
    yield put(reopeningKycActions.reset())

    try {
        const { token } = action.payload
        const terminalToken = localStorage.getItem('terminalToken') ?? undefined
        const { accessToken, registrationFormId }: InitializeCustomerReopeningView = yield call(
            http(),
            reopeningCustomerResourceApi.initialize,
            `Bearer ${token}`,
            getDeviceType(),
            terminalToken
        )

        if (accessToken && registrationFormId) {
            yield put(authActions.createRegistrationFormSuccess({ accessToken, registrationFormId }))
            // re-update barcode and Email if exists
            if (isTerminal) yield reSendBarcode(registrationFormId)
            yield reSendEmailAddress(registrationFormId)
            yield put(navigationActions.goToNextStep())
        }
    } catch (err) {
        if (isStimulus(err)) {
            yield put(stimulusActions.handleStimulus(err))
        } else {
            yield put(uiActions.showModal(stimulusToUiData(createStimulus())))
        }
    }
}

function* reSendBarcode(registrationFormId: string) {
    const barcode: string = yield select(barcodeSelectors.getBarcode)
    yield call(http(), adultRegistrationFormResourceApi.updateBarcode1, registrationFormId, { barcode })
}

function* reSendEmailAddress(registrationFormId: string) {
    const emailAddress: string = yield select(mailSelectors.getEmail)
    if (emailAddress)
        yield call(http(), adultRegistrationFormResourceApi.updateEmailAddress, registrationFormId, { emailAddress })
}

export default function* root() {
    yield all([takeEvery(reopeningActions.initialize, initialize)])
}
