import { createSelector } from '@reduxjs/toolkit'

import formsSelectors from '@nickel/forms/redux/selectors'
import { REGISTRATION_TYPE } from '@nickel/kyc/fields/names'
import { RegistrationType } from '@nickel/kyc/fields/registrationType/types'

import { OWNER_ROLE_MAPPER } from '../../constants/roles'
import { isDefined } from '../../utils'

/* ------------- Selectors ------------- */

const getRegistrationType = createSelector(
    [formsSelectors.getFieldValue<RegistrationType | ''>(REGISTRATION_TYPE)],
    (registrationType) => {
        if (registrationType === '') return undefined
        return registrationType
    }
)

const getIsCnj = createSelector(
    [getRegistrationType],
    (type) => isDefined(type) && type === RegistrationType.COMPTE_NICKEL_JEUNE
)

const getOwnerRole = createSelector(
    [getRegistrationType],
    (registrationType) => registrationType && OWNER_ROLE_MAPPER[registrationType]
)

const isRegistrationTypeValid = createSelector(
    [getRegistrationType],
    (type) => isDefined(type) && (type === RegistrationType.ADULTE || type === RegistrationType.COMPTE_NICKEL_JEUNE)
)

export const selectors = {
    getRegistrationType,
    getIsCnj,
    getOwnerRole,
    isRegistrationTypeValid
}
