import { useEffect } from 'react'

import { Dispatch } from '@reduxjs/toolkit'
import { Location } from 'history'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { RegistrationType } from '@nickel/kyc/fields/registrationType/types'
import { getGlobalISO2 } from '@nickel/l10n/config'
import { init as initGtm, push as pushToGtm } from '@nickel/tracking/gtm'
import { get as getGclid, init as initGclid, push as pushGclid } from '@nickel/tracking/gtm/gclid'
import { init as initUtmCampain } from '@nickel/tracking/gtm/utmCampain'
import { init as initUtmSource } from '@nickel/tracking/gtm/utmSource'

import { FeatureFlag, isSyncFeatureEnabled } from '../config/features'
import { isRootRoute } from '../redux/navigation/utils'
import { actions, selectors, TrackEvent } from '../redux/tracking'
import { getDeviceType } from '../utils'

import usePageRefreshTracking from './usePageRefreshTracking'

const trackLocationChange = (location: Location) => {
    const { pathname } = location
    if (isRootRoute(pathname)) {
        // Reset dataLayer
        window.dataLayer = undefined
        // Init dataLayer
        initGtm(window.REACT_APP_GTM_ACCOUNT)
        initGclid()
        initUtmCampain()
        initUtmSource()

        const gclid = getGclid()
        if (gclid) pushGclid(gclid)

        const country = getGlobalISO2()
        if (!isSyncFeatureEnabled(FeatureFlag.CNJ, country)) {
            pushToGtm({
                [TrackEvent.REGISTRATION_TYPE]: RegistrationType.ADULTE
            })
        }
        pushToGtm({
            [TrackEvent.DEVICE]: getDeviceType()
        })
        return
    }

    pushToGtm({ [TrackEvent.STEP_NAME]: pathname })
}

const trackStepCount = (dispatch: Dispatch, stepCount: number) => {
    dispatch(actions.incrementStepCount())
    pushToGtm({ [TrackEvent.STEP_COUNT]: stepCount })
}

const useLocationTracking = () => {
    const dispatch = useDispatch()
    const stepCount = useSelector(selectors.getStepCount)
    const location = useLocation()
    usePageRefreshTracking()

    useEffect(() => {
        trackLocationChange(location)
        trackStepCount(dispatch, stepCount)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])
}

export default useLocationTracking
