import React from 'react'

import { actions as uiActions } from '@nickel/stimulus/ui'

import { FINAL_SCREEN_USER_IDLE_MODAL_DURATION, USER_IDLE_MODAL_DURATION } from '../../config/modals'
import { actions as navigationActions } from '../../redux/navigation'
import { TemplateProps } from '../types'

import Styled from './style'

const UserIdleTemplate = ({ dispatch, isVisible, t }: TemplateProps) => {
    const onCountdownOver = () => {
        dispatch(uiActions.hideModal())
        dispatch(navigationActions.goToFirstStep())
    }

    return (
        <div>
            <p>{t('user-idle:content')}</p>
            <Styled.ProgressCircle
                duration={USER_IDLE_MODAL_DURATION}
                isAnimating={isVisible}
                onComplete={onCountdownOver}
            />
        </div>
    )
}

export const FinalScreenUserIdleTemplate = ({ dispatch, isVisible, t }: TemplateProps) => {
    const onCountdownOver = () => {
        dispatch(uiActions.hideModal())
        dispatch(navigationActions.goToFirstStep())
    }

    return (
        <div>
            <p>{t('user-idle:final-screen.content')}</p>
            <Styled.ProgressCircle
                duration={FINAL_SCREEN_USER_IDLE_MODAL_DURATION}
                isAnimating={isVisible}
                onComplete={onCountdownOver}
            />
        </div>
    )
}

export default UserIdleTemplate
