import { RegistrationType } from '@nickel/kyc/fields/registrationType/types'
import { isTerminal } from '@nickel/utils/lib/web'

import { Step } from './steps'

export enum Role {
    ADULT = 'ADULT',
    CHILD = 'CHILD',
    GUARDIAN = 'GUARDIAN'
}

export enum DocumentRoute {
    ADULT_CLASSIC = 'ADULT_CLASSIC',
    ADULT_FULL_ONLINE = 'ADULT_FULL_ONLINE',
    CHILD_CLASSIC = 'CHILD_CLASSIC',
    GUARDIAN_CLASSIC = 'GUARDIAN_CLASSIC'
}

export const IDENTITY_DOCUMENT_ROUTE_MAPPER = {
    [DocumentRoute.ADULT_CLASSIC]: isTerminal
        ? `/${Step.IDENTITY}/${Step.DOCUMENT}`
        : `/${Step.IDENTITY}/${Step.LIVENESS}`,
    [DocumentRoute.CHILD_CLASSIC]: isTerminal
        ? `/${Step.IDENTITY}/${Step.DOCUMENT_CHILD}`
        : `/${Step.IDENTITY}/${Step.LIVENESS_CHILD}`,
    [DocumentRoute.GUARDIAN_CLASSIC]: isTerminal
        ? `/${Step.IDENTITY}/${Step.DOCUMENT_GUARDIAN}`
        : `/${Step.IDENTITY}/${Step.LIVENESS_GUARDIAN}`,
    [DocumentRoute.ADULT_FULL_ONLINE]: `/${Step.IDENTITY}/${Step.LIVENESS_VIDEO}`
}

export const OWNER_ROLE_MAPPER = {
    [RegistrationType.ADULTE]: Role.ADULT,
    [RegistrationType.COMPTE_NICKEL_JEUNE]: Role.CHILD
}
