import { actions as navigationActions } from '../../redux/navigation'

export const getAppLoadingErrorModalData = (homeLocation: string) => ({
    title: 'errors:app-loading.title',
    message: 'errors:app-loading.message',
    primaryAction: {
        action: navigationActions.goToExternal(homeLocation),
        text: 'common:quit-subscription'
    }
})
