import { createSelector } from '@reduxjs/toolkit'

import formsSelectors from '@nickel/forms/redux/selectors'
import { BIRTH_DATE, FIRST_NAME, LAST_NAME } from '@nickel/kyc/fields/names'
import { BirthFieldNames } from '@nickel/kyc/forms/birth/constants'

import { REOPENING_INFO } from '../../components/ReopeningInfo/constants'
import { ReopeningInfoState } from '../../components/ReopeningInfo/types'

/* ------------- Selectors ------------- */

const getReopeningBirthCountryIsValid = formsSelectors.getFieldIsValid(`${REOPENING_INFO}.${BirthFieldNames.COUNTRY}`)
const getReopeningBirthDateIsValid = formsSelectors.getFieldIsValid(`${REOPENING_INFO}.${BIRTH_DATE}`)
const getReopeningBirthRegionIsValid = formsSelectors.getFieldIsValid(`${REOPENING_INFO}.${BirthFieldNames.REGION}`)
const getReopeningFirstNameIsValid = formsSelectors.getFieldIsValid(`${REOPENING_INFO}.${FIRST_NAME}`)
const getReopeningLasttNameIsValid = formsSelectors.getFieldIsValid(`${REOPENING_INFO}.${LAST_NAME}`)

const getReopeningInfo = formsSelectors.getFieldValue<ReopeningInfoState>(REOPENING_INFO)
const getReopeningBirthCountry = createSelector([getReopeningInfo], (state) => state?.country)
const getReopeningBirthDate = createSelector([getReopeningInfo], (state) => state?.birthDate)
const getReopeningBirthRegion = createSelector([getReopeningInfo], (state) => state?.region)
const getReopeningFirstName = createSelector([getReopeningInfo], (state) => state?.firstName)
const getReopeningLastName = createSelector([getReopeningInfo], (state) => state?.lastName)

export const selectors = {
    getReopeningBirthCountry,
    getReopeningBirthCountryIsValid,
    getReopeningBirthDate,
    getReopeningBirthDateIsValid,
    getReopeningBirthRegion,
    getReopeningBirthRegionIsValid,
    getReopeningFirstName,
    getReopeningFirstNameIsValid,
    getReopeningLastName,
    getReopeningLasttNameIsValid
}
