import { PayloadAction } from '@reduxjs/toolkit'
import isFunction from 'lodash/isFunction'
import stubFalse from 'lodash/stubFalse'
import { SagaIterator } from 'redux-saga'
import { all, call, put, select } from 'redux-saga/effects'
import { createStructuredSelector } from 'reselect'

import { actions as stimulusActions, createStimulus, isStimulus, stimulusToUiData } from '@nickel/stimulus/stimulus'
import { actions as uiActions } from '@nickel/stimulus/ui'

import { actions as registrationActions } from '..'
import { Step } from '../../../constants/steps'
import { selectors as authSelectors } from '../../auth'
import validators from '../../navigation/validators'
import { http, HttpFunction } from '../../utils/sagas'

import requestMapper, { ApiRequest } from './requestMapper'
import successiveRequestMapper from './successiveRequestMapper'

function* callApi(request: ApiRequest, registrationFormId?: string): SagaIterator {
    const method: HttpFunction = yield select(request.api)
    if (!method) return

    let data = {}
    if (isFunction(request.data)) {
        data = yield select(request.data)
    } else if (request.data) {
        data = yield select(createStructuredSelector(request.data))
    }

    try {
        const handleError = request.options?.handleError
        yield call(http({ handleError }), method, registrationFormId, data)
    } catch (err) {
        if (isStimulus(err)) {
            yield put(stimulusActions.handleStimulus(err))
        } else {
            yield put(uiActions.showModal(stimulusToUiData(createStimulus())))
        }
    }
}

export function* send({ payload }: PayloadAction<Step>): SagaIterator {
    const request = requestMapper[payload]
    if (!request) return

    const isValid = yield select(validators[payload] || stubFalse)
    if (!isValid) return

    if (request.apis) {
        const registrationFormId: string | undefined = yield select(authSelectors.getRegistrationFormId)
        if (!registrationFormId) return

        yield all(request.apis.map((api) => call(callApi, api, registrationFormId)))
    }

    const successiveRequest = successiveRequestMapper[payload]
    if (successiveRequest) yield put(registrationActions.send(successiveRequest))
}
