import Axios, { AxiosRequestHeaders, AxiosResponse } from 'axios'
import { call, put, select, take } from 'redux-saga/effects'

import formsActions from '@nickel/forms/redux/actions'
import { FIELDS_META_NAME } from '@nickel/forms/redux/constants'
import { SupportedLocale } from '@nickel/i18n/lib/types'
import { RegistrationType } from '@nickel/kyc/fields/registrationType/types'
import { getGlobalISO2 } from '@nickel/l10n/config'
import { actions as stimulusActions, createStimulus } from '@nickel/stimulus/stimulus'
import { actions as uiActions } from '@nickel/stimulus/ui'
import { isTerminal } from '@nickel/utils/lib/web'

import store from '..'
import { StimulusType } from '../../config/errors'
import { FeatureFlag, isSyncFeatureEnabled } from '../../config/features'
import { getAppLoadingErrorModalData } from '../../constants/modals/appLoadingError'
import { RegistrationFormCreationView } from '../../services/api'
import { adultRegistrationFormResourceApi, childRegistrationFormResourceApi } from '../../services/utils'
import { getDeviceType } from '../../utils'
import { pushTrackEventToDatalayer } from '../../utils/tracking'
import { actions as captchaActions, selectors as captchaSelectors } from '../captcha'
import { selectors as countrySelectors } from '../form/country'
import { selectors as registrationTypeSelectors } from '../form/registrationType'
import { selectors as navigationSelectors } from '../navigation'
import { DEFAULT_METAS } from '../registration/sagas'
import { TrackEvent } from '../tracking'
import { AxiosFunction, getStimulusTypeFromApiResponse, isAxiosError } from '../utils/sagas'

import { actions as authActions } from '.'

/**
 * @deprecated
 */
export function* createRegistrationForm() {
    const language: SupportedLocale = yield select(countrySelectors.getLanguage)
    const registrationType: RegistrationType | undefined = yield select(registrationTypeSelectors.getRegistrationType)
    const culture: string = language.replace('_', '-')
    const terminalToken = localStorage.getItem('terminalToken')
    const countryCode = getGlobalISO2()
    let captchaVerificationToken: string = yield select(captchaSelectors.getVerificationToken)
    let wasChallengeOpen: boolean = yield select(captchaSelectors.wasChallengeOpen)

    if (isSyncFeatureEnabled(FeatureFlag.CAPTCHA, countryCode)) {
        // Wait for the captcha verification token to be updated
        while (!captchaVerificationToken) {
            yield take(captchaActions.setVerificationToken)
            captchaVerificationToken = yield select(captchaSelectors.getVerificationToken)
            wasChallengeOpen = yield select(captchaSelectors.wasChallengeOpen)
        }
    }

    try {
        const createAdultFn: AxiosFunction = yield call(
            adultRegistrationFormResourceApi.createAdultRegistrationForm,
            { captchaVerificationToken, wasChallengeOpen },
            countryCode,
            culture,
            getDeviceType(),
            isTerminal && terminalToken ? terminalToken : undefined
        )
        const {
            data: { accessToken, registrationFormId = '', registrationFormCode = '' }
        }: AxiosResponse<RegistrationFormCreationView> = yield call(createAdultFn)

        if (accessToken && registrationFormId) {
            yield put(authActions.createRegistrationFormSuccess({ accessToken, registrationFormId }))
            pushTrackEventToDatalayer(TrackEvent.REGISTRATION_CODE, registrationFormCode)
        }

        if (registrationType === RegistrationType.COMPTE_NICKEL_JEUNE) {
            const toChildFn: AxiosFunction = yield call(
                childRegistrationFormResourceApi.changeToChildRegistration,
                registrationFormId
            )
            const { newRegistrationCode } = yield call(toChildFn)
            pushTrackEventToDatalayer(TrackEvent.REGISTRATION_CODE, newRegistrationCode)
        }
    } catch (err) {
        if (isAxiosError(err) && StimulusType.SERVER_ERROR === getStimulusTypeFromApiResponse(err)) {
            const homeLocation: string = yield select(navigationSelectors.getHomeLocation)
            yield put(uiActions.showModal(getAppLoadingErrorModalData(homeLocation)))
        } else {
            yield put(stimulusActions.handleStimulus(createStimulus(StimulusType.NICKEL_SERVICE_UNAVAIBLE)))
        }
        yield put(stimulusActions.handleStimulus(createStimulus(StimulusType.NICKEL_SERVICE_UNAVAIBLE)))
    } finally {
        yield put(
            formsActions.changeFormMeta({
                meta: { [FIELDS_META_NAME]: DEFAULT_METAS }
            })
        )
        yield put(authActions.setIsInitializing(false))
    }
}

export const authInterceptor = Axios.interceptors.request.use((requestConfig) => {
    const { accessToken, registrationFormId } = store.getState().auth ?? { accessToken: '', registrationFormId: '' }
    const headers = { ...requestConfig.headers, Authorization: `Bearer ${accessToken}` } as AxiosRequestHeaders
    const includeAuthorizationHeader: boolean =
        (registrationFormId && requestConfig.url?.includes(registrationFormId)) || false
    return {
        ...requestConfig,
        ...(includeAuthorizationHeader && {
            headers
        })
    }
})
