import { pathOr } from 'ramda'

import { SupportedLocale } from '@nickel/i18n/lib/types'
import useL10n from '@nickel/l10n/useL10n'

const BELGIUM_CGT_DOCUMENTS = {
    [SupportedLocale.EN_US]: window.REACT_APP_CGT_PDF_URL_EN_BE,
    [SupportedLocale.NL_BE]: window.REACT_APP_CGT_PDF_URL_NL_BE
}
const BELGIUM_INSURANCE_DOCUMENTS = {
    [SupportedLocale.EN_US]: window.REACT_APP_INSURANCE_PDF_URL_EN_BE,
    [SupportedLocale.NL_BE]: window.REACT_APP_INSURANCE_PDF_URL_NL_BE
}
const BELGIUM_CHROME_DOCUMENTS = {
    [SupportedLocale.EN_US]: window.REACT_APP_PREMIUM_PDF_URL_EN_BE,
    [SupportedLocale.NL_BE]: window.REACT_APP_PREMIUM_PDF_URL_NL_BE
}

export const useDocuments = (locale: SupportedLocale) => {
    const { l } = useL10n()
    return [
        {
            name: 'cgt',
            url: l({
                BE: pathOr(window.REACT_APP_CGT_PDF_URL_FR_BE, [locale], BELGIUM_CGT_DOCUMENTS),
                ES: window.REACT_APP_CGT_PDF_URL_ES_ES,
                PT: window.REACT_APP_CGT_PDF_URL_PT_PT,
                DE: window.REACT_APP_CGT_PDF_URL_DE_DE,
                default: window.REACT_APP_CGT_PDF_URL_FR_FR
            })
        },
        {
            name: 'insurance',
            url: l({
                BE: pathOr(window.REACT_APP_INSURANCE_PDF_URL_FR_BE, [locale], BELGIUM_INSURANCE_DOCUMENTS),
                ES: window.REACT_APP_INSURANCE_PDF_URL_ES_ES,
                PT: window.REACT_APP_INSURANCE_PDF_URL_PT_PT,
                DE: window.REACT_APP_INSURANCE_PDF_URL_DE_DE,
                default: window.REACT_APP_INSURANCE_PDF_URL_FR_FR
            })
        },
        {
            name: 'chrome',
            url: l({
                BE: pathOr(window.REACT_APP_PREMIUM_PDF_URL_FR_BE, [locale], BELGIUM_CHROME_DOCUMENTS),
                ES: window.REACT_APP_PREMIUM_PDF_URL_ES_ES,
                PT: window.REACT_APP_PREMIUM_PDF_URL_PT_PT,
                DE: window.REACT_APP_PREMIUM_PDF_URL_DE_DE,
                default: window.REACT_APP_CHROME_PDF_URL_FR_FR
            })
        }
    ]
}

export const TERMS_AND_CONDITIONS = 'termsAndConditions'
export const TERMS_AND_CONDITIONS_CULTURE = 'termsAndConditions.culture'
export const USE_OF_DATA_PARTNERS = 'use-of-data-partners'
export const USE_OF_DATA_NICKEL = 'use-of-data-nickel'
export const ACKNOWLEDGED_DOWNLOADED_DOCUMENTS = 'acknowledged-downloaded-documents'
