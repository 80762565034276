import { FieldValue } from '@nickel/forms/types'
import { SexEnum } from '@nickel/kyc/fields/basicInfo/types'
import { IdentityDocumentType } from '@nickel/kyc/fields/identityDocumentType/types'
import {
    CIVILITY,
    FIRST_NAME,
    LAST_NAME,
    SECOND_LAST_NAME,
    SEX,
    TAX_IDENTIFICATION_NUMBER
} from '@nickel/kyc/fields/names'
import { BirthFieldNames, BirthFormName } from '@nickel/kyc/forms/birth/constants'
import { CivilFormName } from '@nickel/kyc/forms/civil/constants'
import { getGlobalISO2 } from '@nickel/l10n/config'
import { getOr } from '@nickel/utils/lib/common'

import { Role } from '../../constants/roles'
import { GetAllowedCountriesCountriesEnum } from '../../services'

import { FormProperties } from '.'

export const getOcrPropertiesForm = (
    issuingCountry: string,
    ocrProperties: { ocrKey: FieldValue },
    identityDocumentType: IdentityDocumentType
) => {
    const ISO2 = getGlobalISO2()

    const emptySecondLastNameOcrKey = (properties: { ocrKey: FieldValue }) => ''

    const lastNameOcrKey =
        issuingCountry === 'ES' && ISO2 === 'ES' ? getFirstLastNameOcrProperty : getFullLastNameOcrProperty

    const secondLastNameOcrKey =
        issuingCountry === 'ES' && ISO2 === 'ES' ? getSecondLastNameOcrProperty : emptySecondLastNameOcrKey

    const civilProperties = {
        [`${CIVILITY}`]: getSexOcrProperty(ocrProperties),
        [`${SEX}`]: getSexOcrProperty(ocrProperties),
        [`${FIRST_NAME}`]: getFirstNameOcrProperty(ocrProperties),
        [`${LAST_NAME}`]: lastNameOcrKey(ocrProperties),
        [`${SECOND_LAST_NAME}`]: secondLastNameOcrKey(ocrProperties),
        [`${TAX_IDENTIFICATION_NUMBER}`]: personalNumberOcrKey(ocrProperties)
    }
    const birthProperties = {
        [`${BirthFieldNames.DATE}`]: getBirthDateOcrProperty(ocrProperties),
        [`${BirthFieldNames.NATIONALITY}`]: getNationalityOcrProperty(ocrProperties, identityDocumentType)
    }
    return { civilProperties, birthProperties }
}

function getFirstNameOcrProperty(properties: { ocrKey: FieldValue }) {
    return getOr('firstNames', '', properties).join(' ')
}

function personalNumberOcrKey(properties: { ocrKey: FieldValue }) {
    return getOr('personalNumber', '', properties)
}

function getFirstLastNameOcrProperty(properties: { ocrKey: FieldValue }) {
    return extractLastNames(properties, 'firstLastName')
}

function getSecondLastNameOcrProperty(properties: { ocrKey: FieldValue }) {
    return extractLastNames(properties, 'secondLastName')
}

function extractLastNames(properties: { ocrKey: FieldValue }, lastNameIndex: 'firstLastName' | 'secondLastName') {
    const fullNames = getOr('lastNames', '', properties).join(' ')
    const namesTab = fullNames.split(' ')
    const separator = namesTab.find((element: string | never[]) => element.length > 3)
    const indexSeparator = namesTab.indexOf(separator)
    const result =
        lastNameIndex === 'firstLastName'
            ? namesTab.slice(0, indexSeparator + 1)
            : namesTab.slice(indexSeparator + 1, namesTab.length)
    return result.join(' ')
}

function getFullLastNameOcrProperty(properties: { ocrKey: FieldValue }) {
    return getOr('lastNames', '', properties).join(' ')
}
const getSexOcrProperty = (properties: { ocrKey: FieldValue }) => {
    const ocrSex = getOr('sex', '', properties)
    return Object.values(SexEnum).includes(ocrSex) ? ocrSex : ''
}
const getBirthDateOcrProperty = (properties: { ocrKey: FieldValue }) => {
    return getOr('birthDate', '', properties)
}
const getNationalityOcrProperty = (properties: { ocrKey: FieldValue }, identityDocumentType: IdentityDocumentType) => {
    const nationality = getOr('nationality', '', properties)
    if (identityDocumentType === IdentityDocumentType.RESIDENCE_PERMIT) return nationality
    const issuingCountry = getOr('issuingCountry', '', properties)
    if (
        identityDocumentType === IdentityDocumentType.NATIONAL_IDENTITY_CARD &&
        issuingCountry === GetAllowedCountriesCountriesEnum.Fr
    ) {
        return nationality || issuingCountry
    }

    return nationality
}

export const getOcrBasedForm = (role: Role, civilProperties: FormProperties, birthProperties: FormProperties) => {
    return {
        [`${role}`]: {
            [`${CivilFormName}`]: {
                ...civilProperties
            },
            [`${BirthFormName}`]: {
                ...birthProperties
            }
        }
    }
}
