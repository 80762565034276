import { useSelector } from 'react-redux'

import { AccountUse } from '@nickel/kyc/fields/accountUse/types'
import { push as pushToGtm } from '@nickel/tracking/gtm'
import { GtmEvents } from '@nickel/tracking/gtm/types'

import { Role } from '../constants/roles'
import { selectors as authSelectors } from '../redux/auth'
import { selectors as bankCardInfoSelectors } from '../redux/bankCardInfo'
import { selectors as accountTypeSelectors } from '../redux/form/accountType'
import { selectors as accountUseSelectors } from '../redux/form/accountUse'
import { selectors as civilSelectors } from '../redux/form/civil'
import { selectors as countrySelectors } from '../redux/form/country'
import { selectors as registrationTypeSelectors } from '../redux/form/registrationType'
import { selectors as mailSelectors } from '../redux/mail'
import { TrackEvent } from '../redux/tracking'
import { getDeviceType } from '../utils'

const useEventTracking = () => {
    const accountType = useSelector(accountTypeSelectors.getAccountType()) || ''
    const accountUse = useSelector(accountUseSelectors.getAccountUse())
    const guardianGender = useSelector(civilSelectors.getCivility(Role.GUARDIAN)) || ''
    const adultGender = useSelector(civilSelectors.getCivility(Role.ADULT)) || ''
    const childGender = useSelector(civilSelectors.getCivility(Role.CHILD)) || ''
    const registrationType = useSelector(registrationTypeSelectors.getRegistrationType) || ''
    const registrationCode = useSelector(authSelectors.getRegistrationFormCode) || ''
    const cardReceptionMode = useSelector(bankCardInfoSelectors.getCardReceptionMode) || ''
    const cardType = useSelector(bankCardInfoSelectors.getCardType) || ''
    const cardCustomization = useSelector(bankCardInfoSelectors.getCardCustomization) || ''
    const country = useSelector(countrySelectors.getCountry)
    const locale = useSelector(countrySelectors.getLanguage)
    const email = useSelector(mailSelectors.getEmail) || ''
    const trackEvent = (event: TrackEvent) => {
        switch (event) {
            case TrackEvent.ACCOUNT_TYPE: {
                pushToGtm({
                    [TrackEvent.ACCOUNT_TYPE]: accountType
                })
                break
            }
            case TrackEvent.ACCOUNT_USE: {
                const trackedAccountUse = []
                if (accountUse?.includes(AccountUse.ONLINE_SHOPPING)) trackedAccountUse.push(AccountUse.ONLINE_SHOPPING)
                if (accountUse?.includes(AccountUse.PAY_ABROAD)) trackedAccountUse.push(AccountUse.PAY_ABROAD)
                if (trackedAccountUse.length > 0) {
                    pushToGtm({
                        [TrackEvent.ACCOUNT_USE]: trackedAccountUse.join(' ')
                    })
                }
                break
            }
            case TrackEvent.GENDER:
            case TrackEvent.GENDER_GUARDIAN: {
                const isGuardian = event === TrackEvent.GENDER_GUARDIAN
                pushToGtm({
                    [TrackEvent.GENDER]: isGuardian ? guardianGender : adultGender
                })
                break
            }
            case TrackEvent.GENDER_CNJ: {
                pushToGtm({
                    [TrackEvent.GENDER_CNJ]: childGender
                })
                break
            }
            case TrackEvent.MAIL: {
                if (!email) return
                pushToGtm({ event: GtmEvents.EMAIL })
                break
            }
            case TrackEvent.REGISTRATION_TYPE: {
                pushToGtm({
                    [TrackEvent.REGISTRATION_TYPE]: registrationType
                })
                break
            }
            case TrackEvent.REGISTRATION_CODE: {
                pushToGtm({
                    [TrackEvent.REGISTRATION_CODE]: registrationCode
                })
                break
            }
            case TrackEvent.CARD_RECEPTION_MODE: {
                pushToGtm({
                    [TrackEvent.CARD_RECEPTION_MODE]: cardReceptionMode
                })
                break
            }
            case TrackEvent.CARD_TYPE: {
                pushToGtm({
                    [TrackEvent.CARD_TYPE]: cardType
                })
                break
            }
            case TrackEvent.CARD_CUSTOMIZATION: {
                pushToGtm({
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    [TrackEvent.CARD_CUSTOMIZATION]: cardCustomization
                })
                break
            }
            case TrackEvent.COUNTRY: {
                pushToGtm({
                    [TrackEvent.COUNTRY]: country
                })
                break
            }
            case TrackEvent.LOCALE: {
                pushToGtm({
                    [TrackEvent.LOCALE]: locale
                })
                break
            }
            case TrackEvent.DEVICE: {
                pushToGtm({
                    [TrackEvent.DEVICE]: getDeviceType()
                })
                break
            }
            default: {
                break
            }
        }
    }
    return { trackEvent }
}

export default useEventTracking
