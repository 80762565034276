import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { both, equals, has, includes, omit, pipe } from 'ramda'

import { ValidationStatus } from '@nickel/ui/types/validation'

import { RootState } from '..'
import { Role } from '../../constants/roles'
import { actions as registrationActions } from '../registration'

/* ------------- Type & State ------------- */

export enum DOCUMENT_TYPE {
    FAMILY_REGISTER = 'FAMILY_REGISTER',
    NATIONAL_IDENTITY_CARD = 'NATIONAL_IDENTITY_CARD',
    PASSPORT = 'PASSPORT',
    RESIDENCE_PERMIT = 'RESIDENCE_PERMIT',
    FACIAL_CAPTURE = 'FACIAL_CAPTURE'
}

export const DOUBLE_SIDED_DOCUMENTS = [
    DOCUMENT_TYPE.FAMILY_REGISTER,
    DOCUMENT_TYPE.NATIONAL_IDENTITY_CARD,
    DOCUMENT_TYPE.RESIDENCE_PERMIT
]

export type SetFileKeyPayload = {
    documentType: DOCUMENT_TYPE
    fileKey: string
    role: Role
    storeId: string
}

type SetOnfidoDocumentPayload = {
    documentId: string
    role: Role
    storeId: string
}

type UploadSuccessPayload = {
    accessToken?: string
    documentId?: string
    storeId: string
}

export type Document = UploadSuccessPayload & {
    backFileKey: string
    documentType: DOCUMENT_TYPE
    frontFileKey: string
    role: Role
    uploadStatus: ValidationStatus
}

type Documents = Record<string, Document>

export const INITIAL_STATE: Documents = {}

/* ------------- Slice & Reducers ------------- */

const SLICE_NAME = '@@documents'

const slice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        uploadRequest: (state, { payload: storeId }: PayloadAction<string>): Documents => ({
            ...state,
            [storeId]: {
                ...state[storeId],
                uploadStatus: ValidationStatus.PENDING
            }
        }),
        uploadFailure: (state, { payload: storeId }: PayloadAction<string>): Documents => ({
            ...state,
            [storeId]: {
                ...state[storeId],
                uploadStatus: ValidationStatus.INVALID
            }
        }),
        uploadSuccess: (state, action: PayloadAction<UploadSuccessPayload>): Documents => ({
            ...state,
            [action.payload.storeId]: {
                ...state[action.payload.storeId],
                accessToken: action.payload.accessToken,
                documentId: action.payload.documentId,
                uploadStatus: ValidationStatus.VALID
            }
        }),
        setBackFileKey: (state, action: PayloadAction<SetFileKeyPayload>): Documents => ({
            ...state,
            [action.payload.storeId]: {
                ...state[action.payload.storeId],
                backFileKey: action.payload.fileKey,
                documentType: action.payload.documentType,
                role: action.payload.role
            }
        }),
        setFrontFileKey: (state, action: PayloadAction<SetFileKeyPayload>): Documents => ({
            ...state,
            [action.payload.storeId]: {
                ...state[action.payload.storeId],
                documentType: action.payload.documentType,
                frontFileKey: action.payload.fileKey,
                role: action.payload.role
            }
        }),
        setOnfidoDocument: (state, action: PayloadAction<SetOnfidoDocumentPayload>): Documents => ({
            ...state,
            [action.payload.storeId]: {
                ...state[action.payload.storeId],
                documentId: action.payload.documentId,
                role: action.payload.role
            }
        }),
        resetDocument: (state, { payload: storeId }: PayloadAction<string>): Documents => omit([storeId], state)
    },
    extraReducers: (builder) => {
        builder.addCase(registrationActions.reset, (): Documents => INITIAL_STATE)
    }
})

export default slice.reducer

/* ------------- Actions ------------- */

export const actions = {
    ...slice.actions
}

/* ------------- Selectors ------------- */

const getDocuments = (state: RootState) => state.documents

const getDocument = (storeId: string) => createSelector([getDocuments], (documents) => documents[storeId])

const getDocumentId = (storeId: string) => createSelector([getDocument(storeId)], (document) => document?.documentId)

const getType = (storeId: string) => createSelector([getDocument(storeId)], (document) => document?.documentType)

const getRole = (storeId: string) => createSelector([getDocument(storeId)], (document) => document?.role)

const getIsDoubleSided = (storeId: string) =>
    createSelector([getType(storeId)], (type) => includes(type, DOUBLE_SIDED_DOCUMENTS))

const getIsComplete = (storeId: string) =>
    createSelector([getDocument(storeId), getIsDoubleSided(storeId)], (document, isDoubleSided) =>
        (isDoubleSided ? both(has('backFileKey'), has('frontFileKey')) : has('frontFileKey'))(document)
    )

const getIsUploaded = (storeId: string) =>
    createSelector(
        [getDocument(storeId)],
        pipe((d) => d?.uploadStatus, equals<ValidationStatus | undefined>(ValidationStatus.VALID))
    )

const getStoreIdFromDocumentId = (documentId: string) =>
    createSelector([getDocuments], (documents) => {
        return Object.keys(documents).find((key) => documents[key].documentId === documentId) ?? ''
    })

export const selectors = {
    getDocument,
    getDocumentId,
    getIsComplete,
    getIsDoubleSided,
    getIsUploaded,
    getRole,
    getStoreIdFromDocumentId
}

/* ------------- Utils ------------- */

export const getDocumentStoreId = (role: Role, documentType: DOCUMENT_TYPE) => `${role}_${documentType}`
