import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '..'
import { actions as registrationActions } from '../registration'

/* ------------- Type & State ------------- */

type CaptchaVerificationPayload = {
    verificationToken?: string
    wasChallengeOpen?: boolean
}

type CaptchaState = {
    verificationToken?: string
    wasChallengeOpen?: boolean
}

export const INITIAL_STATE: CaptchaState = {
    verificationToken: undefined,
    wasChallengeOpen: false
}

/* ------------- Slice & Reducers ------------- */

const SLICE_NAME = '@@captcha'

const slice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        setVerificationToken: (
            state,
            { payload: { verificationToken } }: PayloadAction<CaptchaVerificationPayload>
        ): CaptchaState => ({
            ...state,
            verificationToken
        }),
        setWasChallengeOpen: (
            state,
            { payload: { wasChallengeOpen } }: PayloadAction<CaptchaVerificationPayload>
        ): CaptchaState => ({
            ...state,
            wasChallengeOpen
        }),
        reset: (): CaptchaState => INITIAL_STATE
    },
    extraReducers: (builder) => {
        builder.addCase(registrationActions.reset, (): CaptchaState => INITIAL_STATE)
    }
})

export default slice.reducer

/* ------------- Actions ------------- */

export const actions = {
    ...slice.actions
}

/* ------------- Selectors ------------- */
const getVerificationToken = (state: RootState) => state.captcha.verificationToken
const wasChallengeOpen = (state: RootState) => state.captcha.wasChallengeOpen

export const selectors = {
    getVerificationToken,
    wasChallengeOpen
}
