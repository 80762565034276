import { createSelector } from '@reduxjs/toolkit'

import formsSelectors from '@nickel/forms/redux/selectors'
import { SexEnum } from '@nickel/kyc/fields/basicInfo/types'
import { BinaryChoice } from '@nickel/kyc/fields/binaryChoice/types'
import {
    CIVILITY,
    FIRST_NAME,
    LAST_NAME,
    MARITAL_NAME,
    SECOND_LAST_NAME,
    SEX,
    TAX_IDENTIFICATION_NUMBER
} from '@nickel/kyc/fields/names'
import { CivilFormName } from '@nickel/kyc/forms/civil/constants'
import { areTrue, isSet, isTrue } from '@nickel/utils/lib/common'

import { REOPENING_INFO } from '../../components/ReopeningInfo/constants'
import { Role } from '../../constants/roles'
import { ALREADY_CUSTOMER } from '../../fields/names'
import {
    GetAllowedCountriesCountriesEnum,
    UpdateCivilDataPayload,
    UpdateCivilDataPayloadSexEnum,
    UpdateCivilDataPayloadTitleEnum
} from '../../services'
import { isDefined } from '../../utils'

import { selectors as countrySelectors } from './country'
import { selectors as registrationTypeSelectors } from './registrationType'

/* ------------- Selectors ------------- */

const getCivility = (role: Role) =>
    formsSelectors.getFieldValue<UpdateCivilDataPayloadTitleEnum>(`${role}.${CivilFormName}.${CIVILITY}`)

const getFirstName = (role: Role) =>
    createSelector(
        [
            formsSelectors.getFieldValue<string>(ALREADY_CUSTOMER),
            formsSelectors.getFieldValue<string>(`${REOPENING_INFO}.${FIRST_NAME}`),
            formsSelectors.getFieldValue<string>(`${role}.${CivilFormName}.${FIRST_NAME}`)
        ],
        (alreadyCustomer, reopeningFirstName, firstName) =>
            alreadyCustomer === BinaryChoice.YES ? reopeningFirstName : firstName
    )

const getLastName = (role: Role) => formsSelectors.getFieldValue<string>(`${role}.${CivilFormName}.${LAST_NAME}`)

const getMaritalName = (role: Role) => formsSelectors.getFieldValue<string>(`${role}.${CivilFormName}.${MARITAL_NAME}`)

const getSecondLastName = (role: Role) =>
    formsSelectors.getFieldValue<string>(`${role}.${CivilFormName}.${SECOND_LAST_NAME}`)

const getPersonalNumber = (role: Role) =>
    formsSelectors.getFieldValue<string>(`${role}.${CivilFormName}.${TAX_IDENTIFICATION_NUMBER}`)

const getIsPersonalNumberSet = (role: Role) =>
    createSelector([getPersonalNumber(role)], (personalNumber) => {
        return isSet(personalNumber)
    })

const getSex = (role: Role) =>
    createSelector(
        [
            countrySelectors.getCountry,
            getCivility(role),
            formsSelectors.getFieldValue<UpdateCivilDataPayloadSexEnum>(`${role}.${CivilFormName}.${SEX}`)
        ],
        (country, civility, sex) =>
            country === GetAllowedCountriesCountriesEnum.De
                ? sex ?? UpdateCivilDataPayloadSexEnum.Unknown
                : ((civility as unknown) as UpdateCivilDataPayloadSexEnum)
    )
const getCivilityData = (role: Role) =>
    createSelector(
        [
            getCivility(role),
            getFirstName(role),
            getLastName(role),
            getSecondLastName(role),
            getMaritalName(role),
            getPersonalNumber(role),
            getSex(role)
        ],
        (title, firstName, lastName, secondLastName, marriedName, personalNumber, sex): UpdateCivilDataPayload => ({
            firstNames: [firstName].filter(isDefined),
            lastNames: [lastName, secondLastName].filter(isDefined),
            title: title as UpdateCivilDataPayloadTitleEnum,
            sex,
            marriedName,
            personalNumber
        })
    )

const getCustomerFirstName = createSelector(
    [registrationTypeSelectors.getIsCnj, getFirstName(Role.ADULT), getFirstName(Role.CHILD)],
    (isCnj, adultFirstName, childFirstName) => (isCnj ? childFirstName : adultFirstName)
)

const getIsCivilityValid = (role: Role) =>
    createSelector(
        [countrySelectors.getCountry, formsSelectors.getFieldValue(`${role}.${CivilFormName}.${CIVILITY}`)],
        (country, civility) => isTrue(Object.values(SexEnum).includes(civility as SexEnum))
    )

const getIsSexValid = (role: Role) =>
    createSelector(
        [countrySelectors.getCountry, formsSelectors.getFieldValue(`${role}.${CivilFormName}.${SEX}`)],
        (country, sex) =>
            country !== GetAllowedCountriesCountriesEnum.De || isTrue(Object.values(SexEnum).includes(sex as SexEnum))
    )
const getIsTaxIdentificationValid = (role: Role) =>
    createSelector(
        [
            countrySelectors.getCountry,
            formsSelectors.getFieldIsValid(`${role}.${CivilFormName}.${TAX_IDENTIFICATION_NUMBER}`)
        ],
        (country, isTinValid) => {
            const requiredTaxIdCountries: GetAllowedCountriesCountriesEnum[] = [
                GetAllowedCountriesCountriesEnum.Be,
                GetAllowedCountriesCountriesEnum.Pt,
                GetAllowedCountriesCountriesEnum.De
            ]
            return !requiredTaxIdCountries.includes(country) || isTrue(isTinValid)
        }
    )

const getIsMaritalNameValid = (role: Role) =>
    createSelector(
        [countrySelectors.getCountry, formsSelectors.getFieldIsValid(`${role}.${CivilFormName}.${MARITAL_NAME}`)],
        (country, isValid) => country !== GetAllowedCountriesCountriesEnum.Fr || isTrue(isValid)
    )

const isCivilValid = (role: Role) =>
    createSelector(
        [
            getIsCivilityValid(role),
            formsSelectors.getFieldIsValid(`${role}.${CivilFormName}.${FIRST_NAME}`),
            formsSelectors.getFieldIsValid(`${role}.${CivilFormName}.${LAST_NAME}`),
            getIsMaritalNameValid(role),
            getIsTaxIdentificationValid(role),
            getIsSexValid(role)
        ],
        (
            isCivilityValid,
            isFirstNameValid,
            isLastNameValid,
            isMaritalNameValid,
            isTaxIdentificationValid,
            isSexValid
        ) =>
            areTrue(
                isCivilityValid,
                isFirstNameValid,
                isLastNameValid,
                isTaxIdentificationValid,
                isMaritalNameValid,
                isSexValid
            )
    )

export const selectors = {
    getCivility,
    getCivilityData,
    getCustomerFirstName,
    isCivilValid,
    getIsPersonalNumberSet
}
