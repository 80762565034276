import { createAction, createSlice } from '@reduxjs/toolkit'

import { RootState } from '..'
import { actions as registrationActions } from '../registration'

/* ------------- Type & State ------------- */

export enum TrackEvent {
    ACCOUNT_TYPE = 'ACCOUNT_TYPE',
    ACCOUNT_USE = 'ACCOUNT_USE',
    CARD_CUSTOMIZATION = 'CARD_CUSTOMIZATION',
    CARD_RECEPTION_MODE = 'CARD_RECEPTION_MODE',
    CARD_TYPE = 'CARD_TYPE',
    COUNTRY = 'COUNTRY',
    DEVICE = 'DEVICE',
    DOCUMENT_TYPE = 'DOCUMENT_TYPE',
    DOCUMENT_TYPE_CNJ = 'DOCUMENT_TYPE_CNJ',
    DOCUMENT_TYPE_GUARDIAN = 'DOCUMENT_TYPE_GUARDIAN',
    EID_FAILURE = 'EID_FAILURE',
    EID_NOTIFICATION = 'EID_NOTIFICATION',
    EID_SUCCESS = 'EID_SUCCESS',
    GENDER = 'GENDER',
    GENDER_CNJ = 'GENDER_CNJ',
    GENDER_GUARDIAN = 'GENDER_GUARDIAN',
    LOCALE = 'LOCALE',
    MAIL = 'MAIL',
    MANDATORY_EMAIL = 'MANDATORY_EMAIL',
    REGISTRATION_TYPE = 'REGISTRATION_TYPE',
    REGISTRATION_CODE = 'NOB',
    STEP_COUNT = 'STEP_COUNT',
    STEP_NAME = 'STEP_NAME'
}

export interface TrackEventInfo {
    event: TrackEvent
    info: string | number
}

interface TrackingState {
    stepCount: number
}

export const INITIAL_STATE: TrackingState = {
    stepCount: 0
}

/* ------------- Slice & Reducers ------------- */

const SLICE_NAME = '@@track'

const slice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        incrementStepCount: (state): TrackingState => ({
            ...state,
            stepCount: state.stepCount + 1
        }),
        reset: (): TrackingState => INITIAL_STATE
    },
    extraReducers: (builder) => {
        builder.addCase(registrationActions.reset, (): TrackingState => INITIAL_STATE)
    }
})

export default slice.reducer

/* ------------- Actions ------------- */

export const actions = {
    ...slice.actions,
    countrySelected: createAction(`${SLICE_NAME}/COUNTRY_SELECTED`),
    trackEvent: createAction<TrackEvent>(`${SLICE_NAME}/TRACK_EVENT`),
    trackEventInfo: createAction<TrackEventInfo>(`${SLICE_NAME}/TRACK_EVENT_INFO`)
}

/* ------------- Selectors ------------- */

const getStepCount = (state: RootState) => state.tracking.stepCount

export const selectors = {
    getStepCount
}
