import { useEffect } from 'react'

import { isEmpty } from 'lodash'
import { useLocation } from 'react-router-dom'

import { init as initGtm } from '@nickel/tracking/gtm'

import { isRootRoute } from '../redux/navigation/utils'
import { TrackEvent } from '../redux/tracking'

import useEventTracking from './useEventTracking'

const usePageRefreshTracking = () => {
    const location = useLocation()
    const { pathname } = location
    const { trackEvent } = useEventTracking()

    useEffect(() => {
        // Only if we are not on the root route
        if (!isRootRoute(pathname)) {
            // Reload tracking and dataLayer if it's empty (https://compte-nickel.atlassian.net/browse/SOUS-6515)
            // And push needed data for efficient tracking (https://compte-nickel.atlassian.net/browse/SOUS-7969)
            if (isEmpty(window.dataLayer)) {
                initGtm(window.REACT_APP_GTM_ACCOUNT)
                trackEvent(TrackEvent.REGISTRATION_CODE)
                trackEvent(TrackEvent.REGISTRATION_TYPE)
                trackEvent(TrackEvent.DEVICE)
                trackEvent(TrackEvent.CARD_RECEPTION_MODE)
                trackEvent(TrackEvent.CARD_TYPE)
                trackEvent(TrackEvent.LOCALE)
                trackEvent(TrackEvent.COUNTRY)
            }
        }
    }, [pathname, trackEvent])
}

export default usePageRefreshTracking
