import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FaceResponse } from 'onfido-sdk-ui/dist/onfido.min.js'

import { RootState } from '..'
import { Role } from '../../constants/roles'
import { actions as registrationActions } from '../registration'

/* ------------- Type & State ------------- */

type CompletePayload = {
    role: Role
    face: typeof FaceResponse
}

type LivenessState = Partial<
    Record<
        Role,
        {
            face: typeof FaceResponse
        }
    >
>

export const INITIAL_STATE: LivenessState = {}

/* ------------- Slice & Reducers ------------- */

const SLICE_NAME = '@@liveness'

const slice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        complete: (state, { payload: { role, face } }: PayloadAction<CompletePayload>): LivenessState => ({
            ...state,
            [role]: { face }
        }),
        reset: (): LivenessState => INITIAL_STATE
    },
    extraReducers: (builder) => {
        builder.addCase(registrationActions.reset, (): LivenessState => INITIAL_STATE)
    }
})

export default slice.reducer

/* ------------- Actions ------------- */

export const actions = {
    ...slice.actions
}

/* ------------- Selectors ------------- */

const get = (state: RootState) => state.liveness

const getId = (role: Role) => createSelector([get], (liveness) => liveness[role]?.face.id)

const getVariant = (role: Role) => createSelector([get], (liveness) => liveness[role]?.face.variant.toUpperCase())

const getIsCompleted = (role: Role) => createSelector([get], (liveness) => liveness[role] !== undefined)

export const selectors = {
    getId,
    getVariant,
    getIsCompleted
}
