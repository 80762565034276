import { mergeDeepLeft } from 'ramda'

import root from '../root'

import accountType from './account-type.json'
import accountUse from './account-use.json'
import address from './address.json'
import allElements from './all-elements.json'
import bankCardCustomization from './bank-card-custmization.json'
import banCardType from './bank-card-type.json'
import barcode from './barcode.json'
import biometricConsent from './biometric-consent.json'
import birthDataConfirmation from './birth-data-confirmation.json'
import cardReceptionMode from './card-reception-mode.json'
import civil from './civil.json'
import common from './common.json'
import country from './country.json'
import customerSupport from './customer-support.json'
import employment from './employment.json'
import errors from './errors.json'
import familyRegister from './family-register.json'
import familyStatus from './family-status.json'
import final from './final.json'
import financial from './financial.json'
import fiscalAddress from './fiscal-address.json'
import guardian from './guardian.json'
import identityChoice from './identity-choice.json'
import identityDocument from './identity-document.json'
import livenessChild from './liveness-child.json'
import livenessGuardian from './liveness-guardian.json'
import livenessVideo from './liveness-video.json'
import liveness from './liveness.json'
import loader from './loader.json'
import mail from './mail.json'
import orderSummary from './order-summary.json'
import payment from './payment.json'
import phone from './phone.json'
import privacyPolicyEid from './privacy-policy-eid.json'
import privateLifeCertification from './private-life-certification.json'
import propertyStatus from './property-status.json'
import quitApplication from './quit-application.json'
import registrationType from './registration-type.json'
import reopeningInfo from './reopening-info.json'
import reopeningKyc from './reopening-kyc.json'
import reopeningMatchMulty from './reopening-match-multy.json'
import reopeningMatchOne from './reopening-match-one.json'
import reopeningMatchZero from './reopening-match-zero.json'
import reopening from './reopening.json'
import smsChallenge from './sms-challenge.json'
import steps from './steps.json'
import taxResidence from './tax-residence.json'
import termsAndConditionsEid from './terms-and-conditions-eid.json'
import termsAndConditions from './terms-and-conditions.json'
import usPerson from './us-person.json'
import userIdle from './user-idle.json'

export default {
    'account-type': accountType,
    'account-use': accountUse,
    address,
    'all-elements': allElements,
    barcode,
    'biometric-consent': biometricConsent,
    'birth-data-confirmation': birthDataConfirmation,
    civil,
    common,
    country,
    'customer-support': customerSupport,
    employment,
    errors,
    'family-register': familyRegister,
    'family-status': familyStatus,
    final,
    financial,
    'fiscal-address': fiscalAddress,
    guardian,
    'identity-choice': identityChoice,
    'identity-document': identityDocument,
    'liveness-adult': mergeDeepLeft(liveness, root['liveness-adult']),
    'liveness-child': mergeDeepLeft(mergeDeepLeft(livenessChild, liveness), root['liveness-child']),
    'liveness-guardian': mergeDeepLeft(mergeDeepLeft(livenessGuardian, liveness), root['liveness-guardian']),
    'liveness-video': livenessVideo,
    liveness,
    loader,
    mail,
    'order-summary': orderSummary,
    payment,
    phone,
    'privacy-policy-eid': privacyPolicyEid,
    'private-life-certification': privateLifeCertification,
    'property-status': propertyStatus,
    'quit-application': quitApplication,
    'registration-type': registrationType,
    reopening,
    'reopening-info': reopeningInfo,
    'reopening-kyc': reopeningKyc,
    'reopening-match-multy': reopeningMatchMulty,
    'reopening-match-one': reopeningMatchOne,
    'reopening-match-zero': reopeningMatchZero,
    'sms-challenge': smsChallenge,
    steps,
    'tax-residence': taxResidence,
    'terms-and-conditions': termsAndConditions,
    'terms-and-conditions-eid': termsAndConditionsEid,
    'us-person': usPerson,
    'user-idle': userIdle,
    'card-reception-mode': cardReceptionMode,
    'bank-card-type': banCardType,
    'bank-card-customization': bankCardCustomization
}
