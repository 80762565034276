import { createAction, createSelector, Selector } from '@reduxjs/toolkit'
import negate from 'lodash/negate'
import { always, head, map, pick, pipe } from 'ramda'

import { SupportedLocale } from '@nickel/i18n/lib/types'
import { RegistrationType } from '@nickel/kyc/fields/registrationType/types'
import { SupportedCountryISO2 } from '@nickel/l10n/types'
import { createDeepEqualSelector, get, isTrue } from '@nickel/utils/lib/common'
import { isTerminal, isTerminalWeb, PLATFORM } from '@nickel/utils/lib/web'

import { RootState } from '..'
import { CardTypeEnum } from '../../components/BankCardTypeDetail/types'
import getUnfilteredSteps from '../../config/steps'
import { ConfigStep, ReopeningMatchType } from '../../config/types'
import { Role } from '../../constants/roles'
import { Step } from '../../constants/steps'
import { SupportedLocalePath } from '../../navigation/types'
import { CardReceptionModeEnum } from '../../screens/CardReceptionMode/types'
import { getRootRoute, rootRoute } from '../../utils'
import { selectors as authSelectors } from '../auth'
import { selectors as bankCardInfoSelectors } from '../bankCardInfo'
import { selectors as countrySelectors } from '../form/country'
import { selectors as registrationTypeSelectors } from '../form/registrationType'
import { selectors as reopenSelectors } from '../form/reopening'
import { selectors as mailSelectors } from '../mail'
import { selectors as reopeningSelectors } from '../reopening'

import stepEnablers from './enablers'
import { isRootRoute } from './utils'
import stepValidators from './validators'

/* ------------- Type & State ------------- */

export interface NavigationStep {
    completion: number
    component?: string
    hiddenFromStepper: boolean
    isEnabled: boolean
    isValid: boolean
    name: Step
    parentName?: string
    role?: Role
    route: string
    substeps?: NavigationStep[]
    unprotected: boolean
    possibleNextSteps?: string[]
    mailAlreadySet?: boolean
}

export type RouteMapping = Pick<NavigationStep, 'component' | 'route'>

export interface InitialQueryParams {
    culture: SupportedLocale
    registrationType?: RegistrationType
    serial?: string
    token?: string
}

export type GoToIdentityDocumentsStepPayload = {
    role: Role
    storeId?: string
    cardReceptionMode?: CardReceptionModeEnum
}
export type GetCardVisualPayload = {
    country: SupportedCountryISO2
    cardType: CardTypeEnum
}

/* ------------- Slice & Reducers ------------- */

const SLICE_NAME = '@@navigation'

/* ------------- Actions ------------- */

/**
 * @deprecated
 */
export const actions = {
    goToExternal: createAction<string>(`${SLICE_NAME}/GO_TO_EXTERNAL`),
    goToFirstStep: createAction(`${SLICE_NAME}/GO_TO_FIRST_STEP`),
    goToNextStep: createAction(`${SLICE_NAME}/GO_TO_NEXT_STEP`),
    goToFirstStepWithResetDocument: createAction<string>(`${SLICE_NAME}/GO_TO_FIRST_STEP_WITH_RESET_DOCUMENT`),
    goToIdentityDocumentsStep: createAction<GoToIdentityDocumentsStepPayload>(
        `${SLICE_NAME}/GO_TO_IDENTITY_DOCUMENTS_STEP`
    ),
    goToStep: createAction<string>(`${SLICE_NAME}/GO_TO_STEP`)
}

/* ------------- Selectors ------------- */

const getStepEnabler = (stepName: Step) => stepEnablers[stepName] ?? always(true)
const getStepValidator = (stepName: Step) => stepValidators[stepName] ?? always(true)

const getCurrentRoute = ({ router }: RootState) => router.location.pathname

export const filterSteps = (
    steps: ConfigStep[],
    platform: PLATFORM,
    reopenState: boolean,
    mailAlreadySet: boolean,
    reopenMatch?: ReopeningMatchType[],
    registrationType?: string,
    cardReceptionMode?: CardReceptionModeEnum
): ConfigStep[] =>
    steps.reduce((acc, step) => {
        if (
            (step.platform !== undefined && step.platform !== platform) ||
            (step.registrationType && step.registrationType !== registrationType) ||
            (step.reopening !== undefined && step.reopening !== reopenState) ||
            (step.reopeningMatch !== undefined && !reopenMatch?.includes(step.reopeningMatch)) ||
            (step.cardReceptionMode !== undefined && step.cardReceptionMode !== cardReceptionMode) ||
            (step.mailAlreadySet !== undefined && step.mailAlreadySet !== mailAlreadySet)
        ) {
            return acc
        }

        return [
            ...acc,
            step.substeps
                ? {
                      ...step,
                      substeps: filterSteps(
                          step.substeps,
                          platform,
                          reopenState,
                          mailAlreadySet,
                          reopenMatch,
                          registrationType,
                          cardReceptionMode
                      )
                  }
                : step
        ]
    }, [] as ConfigStep[])

const getFilteredSteps = createSelector(
    [
        countrySelectors.getCountry,
        reopenSelectors.isReopening,
        reopeningSelectors.getMatchTypes,
        registrationTypeSelectors.getRegistrationType,
        bankCardInfoSelectors.getCardReceptionMode,
        bankCardInfoSelectors.isBasicCardType,
        bankCardInfoSelectors.getTagReceptionMode,
        mailSelectors.isFirstMailSet
    ],
    (
        country,
        reopenState,
        reopenMatch,
        registrationType,
        cardReceptionMode,
        isBasic,
        tagReceptionMode,
        mailAlreadySet
    ) =>
        filterSteps(
            getUnfilteredSteps(country, isBasic, tagReceptionMode),
            window.REACT_APP_PLATFORM as PLATFORM,
            reopenState,
            mailAlreadySet,
            reopenMatch,
            registrationType,
            cardReceptionMode
        )
)

const addParentStepName = (parentStep: NavigationStep) => (step: ConfigStep) => ({
    ...step,
    parentName: parentStep.name
})

const addCompletion = (parentStep: NavigationStep) => (step: NavigationStep) => ({
    ...step,
    completion: parentStep.completion || step.completion
})

const getCompletion = (substeps: ConfigStep[], state: RootState) =>
    substeps.reduce((total, substep) => total + Number(getStepValidator(substep.name)(state)), 0) / substeps.length

const getRoute = (name: Step, parentName?: string, substeps?: ConfigStep[]) => {
    if (name === Step.COUNTRY) {
        const locale = localStorage.getItem('REACT_APP_LANGUAGE')
        if (locale) {
            return locale === SupportedLocale.EN_US ? `/${SupportedLocalePath.EN_BE}` : `/${locale.toLowerCase()}`
        }
        return rootRoute
    }
    if (parentName) return `/${parentName}/${name}`
    if (substeps && substeps.length > 0) return `/${name}/${substeps[0].name}`
    return `/${name}`
}

const computeStep = (state: RootState) => ({
    component,
    hiddenFromStepper,
    name,
    parentName,
    role,
    substeps,
    unprotected,
    possibleNextSteps,
    mailAlreadySet
}: ConfigStep & { parentName?: string }): NavigationStep => {
    const route = getRoute(name, parentName, substeps)
    const isEnabled = getStepEnabler(name)(state)
    const isValid = substeps
        ? substeps.every((substep) => getStepValidator(substep.name)(state))
        : getStepValidator(name)(state)

    const completion = isEnabled && substeps ? getCompletion(substeps, state) : Number(isValid)

    return {
        ...{ hiddenFromStepper: hiddenFromStepper ?? false },
        ...(role && { role }),
        ...{ unprotected: unprotected ?? false },
        completion,
        component,
        isEnabled,
        isValid,
        name,
        ...(parentName && { parentName }),
        route,
        possibleNextSteps,
        mailAlreadySet
    }
}

const getSteps: Selector<RootState, NavigationStep[]> = createDeepEqualSelector(
    [getFilteredSteps, (s: RootState) => s],
    (steps: ConfigStep[], state: RootState) => {
        const _steps = steps.map(
            (_step): NavigationStep => {
                const step = computeStep(state)(_step)
                const substeps =
                    _step.substeps &&
                    _step.substeps.map(addParentStepName(step)).map(computeStep(state)).map(addCompletion(step))

                return {
                    ...step,
                    ...(substeps && { substeps })
                }
            }
        )

        return _steps.map((step, index) => ({
            ...step,
            isEnabled: index === 0 || (step.isEnabled && _steps[index - 1].isValid)
        }))
    }
)

const getFlatSteps: Selector<RootState, NavigationStep[]> = createDeepEqualSelector(
    [getSteps],
    (steps: NavigationStep[]) => {
        return steps.reduce((acc: NavigationStep[], step: NavigationStep) => {
            if (step.substeps) return acc.concat(step.substeps)
            return acc.concat(step)
        }, [])
    }
)

const getCurrentStep = createSelector([getFlatSteps, getCurrentRoute], (steps, currentRoute) =>
    isRootRoute(currentRoute) ? steps[0] : steps.find((step) => step.route === currentRoute) || steps[0]
)

const getCurrentStepName = createSelector([getCurrentStep], (step) => step.name)

const getIsFirstStep = createSelector([getCurrentStep], (currentStep) => {
    if (isTerminal || isTerminalWeb) return currentStep.name === Step.ALL_ELEMENTS
    return pipe(get('route'), isRootRoute)(currentStep)
})

const getIsLastStep = createSelector([getCurrentStep], (currentStep) => currentStep.name === Step.FINAL_STEP)

const getNextStep = createSelector([getCurrentStep, getFlatSteps], (currentStep, steps) => {
    if (!currentStep) return { route: rootRoute }
    const currentStepIndex = steps.findIndex(({ route }) => route === currentStep.route)
    const isLastStep = currentStepIndex === steps.length - 1
    if (isLastStep) return { route: rootRoute }
    return steps[currentStepIndex + 1]
})

const getPreviousStep = createSelector([getCurrentStep, getFlatSteps], (currentStep, steps) => {
    if (!currentStep) return { route: rootRoute }
    const currentStepIndex = steps.findIndex(({ route }) => route === currentStep.route)
    const isFirstStep = currentStepIndex <= 0
    if (isFirstStep) return { route: rootRoute }
    return steps[currentStepIndex - 1]
})

const getIsCurrentStepValid = createSelector([getCurrentStep, (state: RootState) => state], ({ name }, state) =>
    getStepValidator(name)(state)
)

const getIsCurrentStepEnabled = createSelector(
    [
        getCurrentStep,
        authSelectors.getIsAuthenticated,
        countrySelectors.getCountry,
        bankCardInfoSelectors.isBasicCardType,
        (state: RootState) => state,
        bankCardInfoSelectors.getTagReceptionMode
    ],
    (currentStep, isAuthenticated, country, isBasic, state, tagReceptionMode) => {
        if (!currentStep || (!currentStep.unprotected && !isAuthenticated)) return false
        const defaultStepName = head(getUnfilteredSteps(country, isBasic, tagReceptionMode))?.name
        return getStepEnabler(currentStep.name ?? defaultStepName)(state)
    }
)

const getHomeLocation = createSelector(
    [countrySelectors.getLanguage, countrySelectors.getCountry],
    (locale, country) => {
        if (isTerminalWeb) return getRootRoute('')
        if (isTerminal) return window.REACT_APP_HOME_BORNE_LOCATION

        switch (country) {
            case 'ES':
                return window.REACT_APP_HOME_WEB_LOCATION_ES
            case 'PT':
                return window.REACT_APP_HOME_WEB_LOCATION_PT
            case 'DE':
                return window.REACT_APP_HOME_WEB_LOCATION_DE
            case 'BE':
                switch (locale) {
                    case SupportedLocale.FR_BE:
                        return window.REACT_APP_HOME_WEB_LOCATION_FR_BE
                    case SupportedLocale.NL_BE:
                        return window.REACT_APP_HOME_WEB_LOCATION_NL_BE
                    case SupportedLocale.EN_US:
                        return window.REACT_APP_HOME_WEB_LOCATION_EN_BE
                    default:
                        return window.REACT_APP_HOME_WEB_LOCATION_FR
                }
            default: {
                return window.REACT_APP_HOME_WEB_LOCATION_FR
            }
        }
    }
)

const getIsNextButtonEnabled = getIsCurrentStepValid

const getIsProgressIndicatorVisible = createSelector([getCurrentStep], pipe(get('hiddenFromStepper'), negate(isTrue)))

const getRoutesMapping: Selector<RootState, RouteMapping[]> = createDeepEqualSelector(
    [getFlatSteps],
    map(pick(['component', 'route']))
)

const getQueryParams = (state: RootState) => {
    const queryParams = state?.router?.location?.query
    const _culture = queryParams?.culture?.replace('_', '-')
    const culture = Object.values(SupportedLocale).includes(_culture as SupportedLocale)
        ? (_culture as SupportedLocale)
        : SupportedLocale.FR_FR

    return { ...((queryParams as unknown) as InitialQueryParams), culture }
}

export const selectors = {
    getCurrentRoute,
    getCurrentStep,
    getCurrentStepName,
    getFlatSteps,
    getIsCurrentStepEnabled,
    getIsCurrentStepValid,
    getIsFirstStep,
    getIsLastStep,
    getIsNextButtonEnabled,
    getIsProgressIndicatorVisible,
    getNextStep,
    getPreviousStep,
    getQueryParams,
    getRoutesMapping,
    getSteps,
    getHomeLocation
}
