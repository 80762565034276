import { useCallback, useEffect, useMemo, useState } from 'react'

import { Dispatch } from '@reduxjs/toolkit'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { SupportedLocale } from '@nickel/i18n/lib/types'
import { RegistrationType } from '@nickel/kyc/fields/registrationType/types'
import { actions as uiActions } from '@nickel/stimulus/ui'
import { isTerminal, isTerminalWeb } from '@nickel/utils/lib/web'

import { stimulusToUiDataMap, StimulusType } from '../config/errors'
import { DocumentRoute, IDENTITY_DOCUMENT_ROUTE_MAPPER } from '../constants/roles'
import { Step } from '../constants/steps'
import { getLocaleFromPathName } from '../i18n'
import { getAllowedCountryISO2 } from '../l10n/utils'
import { SupportedLocalePath } from '../navigation/types'
import { actions as appActions } from '../redux/app'
import { actions as documentsActions } from '../redux/documents'
import { selectors as finalizationSelectors } from '../redux/finalization'
import { actions as identityDocumentsActions } from '../redux/identityDocuments'
import { actions as livenessActions } from '../redux/liveness'
import {
    GoToIdentityDocumentsStepPayload,
    InitialQueryParams,
    selectors as navigationSelectors
} from '../redux/navigation'
import { actions as registrationActions } from '../redux/registration'
import requestMapper from '../redux/registration/sagas/requestMapper'
import { TrackEvent } from '../redux/tracking'
import { CardReceptionModeEnum } from '../screens/CardReceptionMode/types'
import { rootRoute } from '../utils'

import useEventTracking from './useEventTracking'

export const goToExternal = (url: string) => window.location.assign(url)

const goToStep = (dispatch: Dispatch, route: string, currentStepName?: Step) => {
    if (currentStepName) dispatch(registrationActions.send(currentStepName))
    dispatch(push(route))
}

const goToNextStep = (currentStepName: Step, nextStep: string, dispatch: Dispatch) => {
    goToStep(dispatch, nextStep, currentStepName)
}

const goToPreviousStep = (previousStep: string, dispatch: Dispatch) => goToStep(dispatch, previousStep)

const goToFirstStep = (dispatch: Dispatch) => {
    if (isTerminal) goToExternal(window.REACT_APP_HOME_BORNE_LOCATION)
    else goToStep(dispatch, rootRoute)
}

const goToFirstStepWithResetDocument = (dispatch: Dispatch) => (storeId: string) => {
    dispatch(documentsActions.resetDocument(storeId))
    dispatch(identityDocumentsActions.resetIdentityDocument(storeId))
    goToFirstStep(dispatch)
}

const goToIdentityDocumentsStep = (dispatch: Dispatch) => ({
    role,
    storeId,
    cardReceptionMode = CardReceptionModeEnum.CLASSIC
}: GoToIdentityDocumentsStepPayload) => {
    if (storeId) {
        dispatch(documentsActions.resetDocument(storeId))
        dispatch(identityDocumentsActions.resetIdentityDocument(storeId))
    }
    if (!isTerminal) dispatch(livenessActions.reset())
    const livenessRoute = `${role}_${cardReceptionMode}` as DocumentRoute
    goToStep(dispatch, IDENTITY_DOCUMENT_ROUTE_MAPPER[livenessRoute])
}

enum Actions {
    GO_TO_STEP,
    GO_TO_NEXT_STEP,
    GO_TO_PREVIOUS_STEP,
    GO_TO_FIRST_STEP,
    GO_TO_FIRST_STEP_WITH_RESET_DOCUMENT,
    GO_TO_IDENTITY_DOCUMENTS_STEP
}

type NavigationState = {
    action: Actions
    payload?: {
        route?: string
        storeId?: string
        goToIdentityDocumentsStepPayload?: GoToIdentityDocumentsStepPayload
    }
}
export const useNavigation = () => {
    const dispatch = useDispatch()
    const currentStepName = useSelector(navigationSelectors.getCurrentStepName)
    const { route: nextStep } = useSelector(navigationSelectors.getNextStep)
    const { route: previousStep } = useSelector(navigationSelectors.getPreviousStep)
    const [navigation, setNavigation] = useState<NavigationState>()
    const { trackEvent } = useEventTracking()

    const trackingEvents: TrackEvent[] = useMemo(() => {
        return requestMapper[currentStepName]?.trackEvents ?? []
    }, [currentStepName])

    useEffect(() => {
        switch (navigation?.action) {
            case Actions.GO_TO_NEXT_STEP:
                trackingEvents.forEach((event) => trackEvent(event))
                goToNextStep(currentStepName, nextStep, dispatch)
                break
            case Actions.GO_TO_STEP:
                trackingEvents.forEach((event) => trackEvent(event))
                goToStep(dispatch, navigation.payload?.route as string, currentStepName)
                break
            case Actions.GO_TO_PREVIOUS_STEP:
                goToPreviousStep(previousStep, dispatch)
                break
            case Actions.GO_TO_FIRST_STEP:
                goToFirstStep(dispatch)
                break
            case Actions.GO_TO_FIRST_STEP_WITH_RESET_DOCUMENT:
                goToFirstStepWithResetDocument(dispatch)(navigation?.payload?.storeId as string)
                break
            case Actions.GO_TO_IDENTITY_DOCUMENTS_STEP:
                goToIdentityDocumentsStep(dispatch)(
                    navigation?.payload?.goToIdentityDocumentsStepPayload as GoToIdentityDocumentsStepPayload
                )
                break
            default:
        }
        setNavigation(undefined)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigation, currentStepName, dispatch, nextStep, previousStep, trackingEvents])

    return {
        goToStep: (route: string) => setNavigation({ action: Actions.GO_TO_STEP, payload: { route } }),
        goToNextStep: () => setNavigation({ action: Actions.GO_TO_NEXT_STEP }),
        goToPreviousStep: () => setNavigation({ action: Actions.GO_TO_PREVIOUS_STEP }),
        goToFirstStep: () => setNavigation({ action: Actions.GO_TO_FIRST_STEP }),
        goToFirstStepWithResetDocument: (storeId: string) =>
            setNavigation({
                action: Actions.GO_TO_FIRST_STEP_WITH_RESET_DOCUMENT,
                payload: { storeId }
            }),
        goToIdentityDocumentsStep: (goToIdentityDocumentsStepPayload: GoToIdentityDocumentsStepPayload) =>
            setNavigation({
                action: Actions.GO_TO_IDENTITY_DOCUMENTS_STEP,
                payload: { goToIdentityDocumentsStepPayload }
            })
    }
}

export const useLocationChangeHandler = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const isRegistrationFinalized = useSelector(finalizationSelectors.getIsSucceeded)
    const isCurrentStepEnabled = useSelector(navigationSelectors.getIsCurrentStepEnabled)
    const currentStepName = useSelector(navigationSelectors.getCurrentStepName)
    const { route: nextStep } = useSelector(navigationSelectors.getNextStep)
    const { culture: language, registrationType, token: terminalToken, serial }: InitialQueryParams = useSelector(
        navigationSelectors.getQueryParams
    )

    const handleLocationChanged = useCallback(() => {
        if (isRegistrationFinalized && currentStepName !== Step.FINAL_STEP) {
            dispatch(appActions.reset())
            return
        }

        if (!isCurrentStepEnabled) {
            dispatch(uiActions.showModal(stimulusToUiDataMap.get(StimulusType.UNAUTHORIZED)()))
            return
        }

        // Reset app
        if (Object.values(SupportedLocalePath).includes(location.pathname.substring(1) as SupportedLocalePath)) {
            dispatch(uiActions.showLoader({ message: '...' }))
            dispatch(appActions.reset())

            if (terminalToken) localStorage.setItem('terminalToken', terminalToken)

            if (!isTerminal && !isTerminalWeb) {
                dispatch(uiActions.hideLoader())
                return
            }

            if (!isTerminalWeb && (!registrationType || !Object.values(RegistrationType).includes(registrationType))) {
                goToExternal(window.REACT_APP_HOME_BORNE_LOCATION)
                return
            }

            const locale = getLocaleFromPathName()

            setTimeout(() => {
                dispatch(
                    registrationActions.init({
                        country: getAllowedCountryISO2(locale.country),
                        language: language !== SupportedLocale.FR_FR ? language : locale.language,
                        registrationType,
                        serial
                    })
                )
            }, 0)

            goToNextStep(currentStepName, nextStep, dispatch)
            dispatch(uiActions.hideLoader())
        }
    }, [
        currentStepName,
        dispatch,
        isCurrentStepEnabled,
        isRegistrationFinalized,
        language,
        location.pathname,
        nextStep,
        registrationType,
        serial,
        terminalToken
    ])

    return { handleLocationChanged }
}

export default useNavigation
