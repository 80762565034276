import { noop } from 'lodash'
import { useQuery } from 'react-query'
import { DispatchProp, shallowEqual, useDispatch, useSelector } from 'react-redux'

import formsActions from '@nickel/forms/redux/actions'
import { FieldValue } from '@nickel/forms/types'
import { IdentityDocumentType } from '@nickel/kyc/fields/identityDocumentType/types'
import { actions as uiActions } from '@nickel/stimulus/ui'
import { areSet, isSet } from '@nickel/utils/lib/common'

import {
    createBannedIdentityDocumentErrorModalData,
    createExpiredIdentityDocumentErrorModalData,
    createInvalidAgeIdentityDocumentErrorModalData,
    createProcessingIdentityDocumentErrorModalData
} from '../constants/modals/identityDocument'
import { Role } from '../constants/roles'
import { selectors as authSelectors } from '../redux/auth'
import { selectors as bankCardInfoSelectors } from '../redux/bankCardInfo'
import { DOCUMENT_TYPE, getDocumentStoreId, selectors as documentsSelectors } from '../redux/documents'
import { selectors as formDocumentsSelectors } from '../redux/form/documents'
import {
    actions,
    AGE_STATUS,
    EXPIRATION_STATUS,
    ExpirationStatusEnum,
    IdentityDocument,
    isAgeCheckProcessing,
    isBanCheckProcessing,
    isExpirationCheckProcessing,
    isOcrProcessing,
    selectors as identityDocumentsSelectors
} from '../redux/identityDocuments'
import { getOcrBasedForm, getOcrPropertiesForm } from '../redux/identityDocuments/constants'
import { CardReceptionModeEnum } from '../screens/CardReceptionMode/types'
import {
    RegistrationDocumentAgeStatusView,
    RegistrationDocumentAgeStatusViewAgeStatusEnum,
    RegistrationDocumentExpirationStatusView,
    RegistrationDocumentIsBannedView,
    RegistrationDocumentOcrPropertiesView
} from '../services'
import { registrationDocumentResourceApi } from '../services/utils'

// Checks
const performAgeCheck = (
    data: RegistrationDocumentAgeStatusView | undefined,
    dispatch: DispatchProp['dispatch'],
    role: Role,
    storeId: string,
    cardReceptionMode: CardReceptionModeEnum | undefined
) => {
    if (!data || data.ageStatus === AGE_STATUS.UNKNOWN) return
    dispatch(
        actions.completeAgeCheck({
            storeId,
            ageStatus: data.ageStatus as RegistrationDocumentAgeStatusViewAgeStatusEnum
        })
    )
    if (data.ageStatus !== AGE_STATUS.NOT_VALID) return
    dispatch(uiActions.showModal(createInvalidAgeIdentityDocumentErrorModalData(role, storeId, cardReceptionMode)))
}
const performBanCheck = (
    data: RegistrationDocumentIsBannedView | undefined,
    dispatch: DispatchProp['dispatch'],
    role: Role,
    storeId: string,
    cardReceptionMode: CardReceptionModeEnum | undefined
) => {
    if (!data) return
    dispatch(actions.completeBanCheck({ storeId, isBanned: data.banned }))
    if (!data.banned) return
    dispatch(
        uiActions.showModal(createBannedIdentityDocumentErrorModalData(role, data.reason, storeId, cardReceptionMode))
    )
}
const performExpirationCheck = (
    data: RegistrationDocumentExpirationStatusView | undefined,
    dispatch: DispatchProp['dispatch'],
    role: Role,
    storeId: string,
    cardReceptionMode: CardReceptionModeEnum | undefined
) => {
    if (!data || data.expirationStatus === EXPIRATION_STATUS.UNKNOWN) return
    dispatch(
        actions.completeExpirationCheck({
            storeId,
            expirationStatus: data.expirationStatus as ExpirationStatusEnum
        })
    )
    if (data?.expirationStatus !== EXPIRATION_STATUS.EXPIRED) return
    dispatch(uiActions.showModal(createExpiredIdentityDocumentErrorModalData(role, storeId, cardReceptionMode)))
}
const performOcrPropertiesCheck = (
    data: RegistrationDocumentOcrPropertiesView | undefined,
    dispatch: DispatchProp['dispatch'],
    role: Role,
    storeId: string,
    cardReceptionMode: CardReceptionModeEnum | undefined,
    identityDocumentType: IdentityDocumentType
) => {
    if (!data) return
    const { civilProperties, birthProperties } = getOcrPropertiesForm(
        data.issuingCountry as string,
        data as { ocrKey: FieldValue },
        identityDocumentType
    )
    const { documentNumber, expirationDate, issuingCountry, mrzLines } = data
    if (
        !documentNumber ||
        !expirationDate ||
        !issuingCountry ||
        (cardReceptionMode === CardReceptionModeEnum.CLASSIC && !isMrzLinesValid(mrzLines))
    )
        dispatch(uiActions.showModal(createProcessingIdentityDocumentErrorModalData(role, storeId, cardReceptionMode)))
    dispatch(actions.completeOcr({ storeId, ocrProperties: data }))
    dispatch(formsActions.changeFormValues({ values: getOcrBasedForm(role, civilProperties, birthProperties) }))
}

const isMrzLinesValid = (mrzLines?: string[]) => {
    if (!mrzLines) return false
    return !(mrzLines.length < 2 || !isSet(mrzLines[0]) || !isSet(mrzLines[1]))
}

// Queries
const getAgeStatus = (
    registrationFormId: string,
    registrationDocumentId: string,
    dispatch: DispatchProp['dispatch'],
    role: Role,
    storeId: string,
    cardReceptionMode: CardReceptionModeEnum | undefined
) => async () =>
    (await registrationDocumentResourceApi.getAgeStatus(registrationFormId, registrationDocumentId))().then((data) =>
        performAgeCheck(data.data, dispatch, role, storeId, cardReceptionMode)
    )

const getBannedStatus = (
    registrationFormId: string,
    registrationDocumentId: string,
    dispatch: DispatchProp['dispatch'],
    role: Role,
    storeId: string,
    cardReceptionMode: CardReceptionModeEnum | undefined
) => async () =>
    (await registrationDocumentResourceApi.getBannedStatus(registrationFormId, registrationDocumentId))().then((data) =>
        performBanCheck(data.data, dispatch, role, storeId, cardReceptionMode)
    )

const getExpirationStatus = (
    registrationFormId: string,
    registrationDocumentId: string,
    dispatch: DispatchProp['dispatch'],
    role: Role,
    storeId: string,
    cardReceptionMode: CardReceptionModeEnum | undefined
) => async () =>
    (
        await registrationDocumentResourceApi.getExpirationStatus(registrationFormId, registrationDocumentId)
    )().then((data) => performExpirationCheck(data.data, dispatch, role, storeId, cardReceptionMode))

const getOcrProperties = (
    registrationFormId: string,
    registrationDocumentId: string,
    dispatch: DispatchProp['dispatch'],
    role: Role,
    storeId: string,
    cardReceptionMode: CardReceptionModeEnum | undefined,
    identityDocumentType: IdentityDocumentType
) => async () =>
    (
        await registrationDocumentResourceApi.getOcrProperties(registrationFormId, registrationDocumentId)
    )().then((data) =>
        performOcrPropertiesCheck(data.data, dispatch, role, storeId, cardReceptionMode, identityDocumentType)
    )

// Hooks
export const useAgeStatusCheck = (
    registrationFormId: string,
    registrationDocumentId: string,
    dispatch: DispatchProp['dispatch'],
    role: Role,
    storeId: string,
    cardReceptionMode: CardReceptionModeEnum | undefined
) => {
    const { isLoading, refetch } = useQuery(
        ['getAgeStatus', registrationFormId, registrationDocumentId],
        getAgeStatus(registrationFormId, registrationDocumentId, dispatch, role, storeId, cardReceptionMode),
        {
            onError: noop,
            enabled: false,
            retry: false
        }
    )
    return { refetch, isLoading }
}

export const useBanStatusCheck = (
    registrationFormId: string,
    registrationDocumentId: string,
    dispatch: DispatchProp['dispatch'],
    role: Role,
    storeId: string,
    cardReceptionMode: CardReceptionModeEnum | undefined
) => {
    const { isLoading, refetch } = useQuery(
        ['getBanCheck', registrationDocumentId, registrationDocumentId],
        getBannedStatus(registrationFormId, registrationDocumentId, dispatch, role, storeId, cardReceptionMode),
        {
            onError: noop,
            enabled: false,
            retry: false
        }
    )
    return { refetch, isLoading }
}

export const useExpirationStatus = (
    registrationFormId: string,
    registrationDocumentId: string,
    dispatch: DispatchProp['dispatch'],
    role: Role,
    storeId: string,
    cardReceptionMode: CardReceptionModeEnum | undefined
) => {
    const { isLoading, refetch } = useQuery(
        ['getExpirationStatus', registrationFormId, registrationDocumentId],
        getExpirationStatus(registrationFormId, registrationDocumentId, dispatch, role, storeId, cardReceptionMode),
        {
            onError: noop,
            enabled: false,
            retry: false
        }
    )
    return { refetch, isLoading }
}

export const useOcrProperties = (
    registrationFormId: string,
    registrationDocumentId: string,
    dispatch: DispatchProp['dispatch'],
    role: Role,
    storeId: string,
    cardReceptionMode: CardReceptionModeEnum | undefined,
    identityDocumentType: IdentityDocumentType
) => {
    const { isLoading, refetch } = useQuery(
        ['getOcrProperties', registrationFormId, registrationDocumentId],
        getOcrProperties(
            registrationFormId,
            registrationDocumentId,
            dispatch,
            role,
            storeId,
            cardReceptionMode,
            identityDocumentType
        ),
        {
            onError: noop,
            enabled: false,
            retry: false
        }
    )
    return { refetch, isLoading }
}

const useCheckDocument = (
    registrationFormId: string,
    dispatch: DispatchProp['dispatch'],
    role: Role,
    cardReceptionMode: CardReceptionModeEnum | undefined
) => {
    const documentType = useSelector(formDocumentsSelectors.createGetIdentityDocumentType(role))
    const storeId = getDocumentStoreId(role, documentType as DOCUMENT_TYPE)
    const documentId = useSelector(documentsSelectors.getDocumentId(storeId), shallowEqual) as string
    const identityDocument: IdentityDocument =
        useSelector(identityDocumentsSelectors.createGetIdentityDocument(storeId), shallowEqual) ?? {}

    const isAgeStatusQueryEnabled =
        areSet(registrationFormId, documentId, documentType) && isAgeCheckProcessing(identityDocument)
    const iseBanCheckQueryEnabled =
        areSet(registrationFormId, documentId, documentType) && isBanCheckProcessing(identityDocument)
    const isExpirationCheckEnabled =
        areSet(registrationFormId, documentId, documentType) && isExpirationCheckProcessing(identityDocument)
    const isOcrPropertiesEnabled =
        areSet(registrationFormId, documentId, documentType) && isOcrProcessing(identityDocument)

    const { refetch: refetchAgeStatus, isLoading: isAgeStatusLoading } = useAgeStatusCheck(
        registrationFormId,
        documentId,
        dispatch,
        role,
        storeId,
        cardReceptionMode
    )
    const { refetch: refetchExpirationCheck, isLoading: isExpirationCheckLoading } = useExpirationStatus(
        registrationFormId,
        documentId,
        dispatch,
        role,
        storeId,
        cardReceptionMode
    )
    const { refetch: refetchBanCheck, isLoading: isBanCheckLoading } = useBanStatusCheck(
        registrationFormId,
        documentId,
        dispatch,
        role,
        storeId,
        cardReceptionMode
    )
    const { refetch: refetchOcrProperties, isLoading: isOcrPropertiesLoading } = useOcrProperties(
        registrationFormId,
        documentId,
        dispatch,
        role,
        storeId,
        cardReceptionMode,
        (documentType as unknown) as IdentityDocumentType
    )

    return {
        refetchIfNeeded: () => {
            if (isOcrPropertiesEnabled && !isOcrPropertiesLoading) refetchOcrProperties()
            if (isAgeStatusQueryEnabled && !isAgeStatusLoading) refetchAgeStatus()
            if (isExpirationCheckEnabled && !isExpirationCheckLoading) refetchExpirationCheck()
            if (iseBanCheckQueryEnabled && !isBanCheckLoading) refetchBanCheck()
        }
    }
}

export const useCheckAllIdentityDocuments = () => {
    const dispatch = useDispatch()
    const registrationFormId = useSelector(authSelectors.getRegistrationFormId, shallowEqual) ?? ''
    const cardReceptionMode = useSelector(bankCardInfoSelectors.getCardReceptionMode, shallowEqual)

    const { refetchIfNeeded: refetchAdultDocumentsIfNeeded } = useCheckDocument(
        registrationFormId,
        dispatch,
        Role.ADULT,
        cardReceptionMode
    )
    const { refetchIfNeeded: refetchChildDocumentsIfNeeded } = useCheckDocument(
        registrationFormId,
        dispatch,
        Role.CHILD,
        cardReceptionMode
    )
    const { refetchIfNeeded: refetchGuardianDocumentsIfNeeded } = useCheckDocument(
        registrationFormId,
        dispatch,
        Role.GUARDIAN,
        cardReceptionMode
    )

    return {
        refetchDocumentsIfNeeded: () => {
            refetchAdultDocumentsIfNeeded()
            refetchChildDocumentsIfNeeded()
            refetchGuardianDocumentsIfNeeded()
        }
    }
}
