import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import HCaptcha from '@hcaptcha/react-hcaptcha'
import { useDispatch, useSelector } from 'react-redux'

import { SupportedLocale } from '@nickel/i18n/lib/types'

import { FeatureFlag, isSyncFeatureEnabled } from '../../config/features'
import useCaptcha from '../../hooks/useCaptcha'
import { actions as captchaActions } from '../../redux/captcha'
import { selectors as countrySelectors } from '../../redux/form/country'

import { ModalOverlay } from './style'

type Props = {
    onChallengeCompleted?: () => void
    language: SupportedLocale
    onChallengeDismiss?: () => void
}

export type CaptchaContextType = {
    isCaptchaExecuted: boolean
    setIsCaptchaExecuted: (value: boolean) => void
    isCaptchaFeatureEnabled: boolean
}

interface CaptchaProviderProps {
    children: React.ReactNode
}

export const CaptchaContext = createContext<CaptchaContextType | null>(null)

export const CaptchaProvider = ({ children }: CaptchaProviderProps) => {
    const [isCaptchaExecuted, setIsCaptchaExecuted] = useState(false)
    const selectedCountry = useSelector(countrySelectors.getCountry)
    const isCaptchaFeatureEnabled = isSyncFeatureEnabled(FeatureFlag.CAPTCHA, selectedCountry)

    const value = useMemo(() => ({ isCaptchaExecuted, setIsCaptchaExecuted, isCaptchaFeatureEnabled }), [
        isCaptchaExecuted,
        isCaptchaFeatureEnabled
    ])
    return <CaptchaContext.Provider value={value}>{children}</CaptchaContext.Provider>
}

const Captcha = ({ onChallengeCompleted, language, onChallengeDismiss }: Props) => {
    const captchaRef = useRef<HCaptcha | null>(null)
    const dispatch = useDispatch()
    const [isChallengeOpen, setIsChallengeOpen] = useState(false)
    const [isCaptchaLoaded, setIsCaptchaLoaded] = useState(false)
    const { isCaptchaExecuted, setIsCaptchaExecuted, isCaptchaFeatureEnabled } = useCaptcha()

    useEffect(() => {
        if (isCaptchaExecuted) {
            executeChallenge()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCaptchaExecuted, isCaptchaLoaded, language])

    const _onChallengeCompleted = useCallback(
        (passcode: string) => {
            dispatch(captchaActions.setVerificationToken({ verificationToken: passcode }))
            setIsChallengeOpen(false)
            setIsCaptchaExecuted(false)
            onChallengeCompleted?.()
        },
        [dispatch, onChallengeCompleted, setIsCaptchaExecuted]
    )

    const onOpen = () => {
        setIsChallengeOpen(true)
        dispatch(captchaActions.setWasChallengeOpen({ wasChallengeOpen: true }))
    }

    const onClose = () => {
        setIsChallengeOpen(false)
        onChallengeDismiss?.()
    }

    const executeChallenge = useCallback(() => {
        if (isCaptchaFeatureEnabled) {
            captchaRef?.current?.execute()
        } else {
            _onChallengeCompleted(' ')
        }
    }, [_onChallengeCompleted, isCaptchaFeatureEnabled])

    return (
        <>
            {isChallengeOpen && <ModalOverlay />}
            {isCaptchaFeatureEnabled && (
                <HCaptcha
                    id="hcaptcha-widget"
                    ref={captchaRef}
                    sitekey={window.REACT_APP_HCAPTCHA_SITEKEY}
                    languageOverride={language}
                    size="invisible"
                    onVerify={(passcode: string, _: string) => _onChallengeCompleted(passcode)}
                    onOpen={onOpen}
                    onClose={onClose}
                    onError={() => executeChallenge()}
                    onLoad={() => setIsCaptchaLoaded(true)}
                    onChalExpired={() => executeChallenge()}
                />
            )}
        </>
    )
}

export default Captcha
