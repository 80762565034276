import { createAction, createSelector, createSlice } from '@reduxjs/toolkit'

import { RootState } from '..'
import { actions as registrationActions } from '../registration'

/* ------------- Type & State ------------- */

type AppState = {
    hasChallengeBeenAttempted: boolean
    isAddressAutocompleteEnabled: boolean
    isReferralCodeSaved: boolean
}

export const INITIAL_STATE: AppState = {
    hasChallengeBeenAttempted: false,
    isAddressAutocompleteEnabled: true,
    isReferralCodeSaved: false
}

/* ------------- Slice & Reducers ------------- */

const SLICE_NAME = '@@app'

const slice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        attemptChallenge: (state): AppState => ({
            ...state,
            hasChallengeBeenAttempted: true
        }),
        disableAddressAutocomplete: (state): AppState => ({
            ...state,
            isAddressAutocompleteEnabled: false
        }),
        enableAddressAutocomplete: (state): AppState => ({
            ...state,
            isAddressAutocompleteEnabled: true
        }),
        saveReferralCode: (state): AppState => ({
            ...state,
            isReferralCodeSaved: true
        }),
        unsaveReferralCode: (state): AppState => ({
            ...state,
            isReferralCodeSaved: false
        }),
        reset: (): AppState => INITIAL_STATE
    },
    extraReducers: (builder) => {
        builder.addCase(registrationActions.reset, (): AppState => INITIAL_STATE)
    }
})

export default slice.reducer

/* ------------- Actions ------------- */

export const actions = {
    ...slice.actions,
    saveReferralCodeAndNext: createAction(`${SLICE_NAME}/SAVE_REFERRAL_CODE_AND_NEXT`)
}

/* ------------- Selectors ------------- */

const getApp = (state: RootState) => state.app
const getHasChallengeBeenAttempted = createSelector([getApp], (app) => app.hasChallengeBeenAttempted)
const getIsAddressAutocompleteEnabled = createSelector([getApp], (app) => app.isAddressAutocompleteEnabled)
const getIsReferralCodeSaved = createSelector([getApp], (app) => app.isReferralCodeSaved)

export const selectors = {
    getHasChallengeBeenAttempted,
    getIsAddressAutocompleteEnabled,
    getIsReferralCodeSaved
}

/* ------------- Utils ------------- */
