import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import formsSelectors from '@nickel/forms/redux/selectors'
import { MAIL } from '@nickel/kyc/fields/names'
import { AddressFieldNames } from '@nickel/kyc/forms/address/constants'
import { BirthFieldNames } from '@nickel/kyc/forms/birth/constants'
import { MailFormName } from '@nickel/kyc/forms/mail/constants'
import { areSet, areTrue, isSet } from '@nickel/utils/lib/common'

import { RootState } from '..'
import {
    REOPENING_KYC_ACCOUNT_USE,
    REOPENING_KYC_CONTACT_DETAILS,
    REOPENING_KYC_PERSONAL_INFORMATION,
    REOPENING_KYC_SITUATION
} from '../../screens/ReopeningKyc/constants'
import { TITLE } from '../../screens/ReopeningKyc/fields/names'
import { BirthDataView, CivilDataView, CivilDataViewTitleEnum } from '../../services'
import { selectors as accountTypeSelectors } from '../form/accountType'
import { selectors as accountUseSelectors } from '../form/accountUse'
import { selectors as addressSelectors } from '../form/address'
import { selectors as familyStatusSelectors } from '../form/familyStatus'
import { selectors as financialSelectors } from '../form/financial'
import { selectors as professionSelectors } from '../form/profession'
import { selectors as propertyStatusSelectors } from '../form/propertyStatus'
import { selectors as taxResidenceSelectors } from '../form/taxResidence'
import { selectors as usPersonSelectors } from '../form/usPerson'
import { selectors as mailSelectors } from '../mail'
import { actions as registrationActions, Address } from '../registration'

/* ------------- Type & State ------------- */

export type ReopningKycContactDetails = {
    address?: {
        label?: string
        value?: Address
    }
    mail?: {
        label?: string
        value?: string
    }
}

interface CivilData extends CivilDataView {
    nationality?: string
    otherNationalities?: string[]
}

export type ReopningKycPersonnalInformation = CivilData &
    Pick<BirthDataView, 'birthDate' | 'birthPlace' | 'nationalityCode'>

type ReopeningKycData = {
    contactDetails?: ReopningKycContactDetails
    personnalInformation?: ReopningKycPersonnalInformation
}

type ReopeningKycState = ReopeningKycData & {
    currentSection: string
    isFetched: boolean
    isFetching: boolean
    isSending: boolean
}

const REOPENING_KYC_SECTIONS: string[] = [
    REOPENING_KYC_PERSONAL_INFORMATION,
    REOPENING_KYC_CONTACT_DETAILS,
    REOPENING_KYC_SITUATION,
    REOPENING_KYC_ACCOUNT_USE
]

export const INITIAL_STATE: ReopeningKycState = {
    currentSection: REOPENING_KYC_SECTIONS[0],
    isFetched: false,
    isFetching: false,
    isSending: false
}

/* ------------- Slice & Reducers ------------- */

const SLICE_NAME = '@@reopening-kyc'

const slice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        reset: (): ReopeningKycState => INITIAL_STATE,
        fetchKycFailure: (state): ReopeningKycState => ({
            ...state,
            isFetched: false,
            isFetching: false
        }),
        fetchKycRequest: (state): ReopeningKycState => ({
            ...state,
            isFetching: true
        }),
        fetchKycSuccess: (state): ReopeningKycState => ({
            ...state,
            isFetched: true,
            isFetching: false
        }),
        sendKycFailure: (state): ReopeningKycState => ({
            ...state,
            isSending: false
        }),
        sendKycRequest: (state): ReopeningKycState => ({
            ...state,
            isSending: true
        }),
        sendKycSuccess: (state): ReopeningKycState => ({
            ...state,
            isSending: false
        }),
        setContactDetails: (state, action: PayloadAction<ReopningKycContactDetails>): ReopeningKycState => ({
            ...state,
            contactDetails: {
                ...state.contactDetails,
                ...action.payload
            }
        }),
        setCurrentSection: (state, action: PayloadAction<string>): ReopeningKycState => ({
            ...state,
            currentSection: action.payload
        }),
        setPersonalInformation: (state, action: PayloadAction<ReopningKycPersonnalInformation>): ReopeningKycState => ({
            ...state,
            personnalInformation: {
                ...state.personnalInformation,
                ...action.payload
            }
        })
    },
    extraReducers: (builder) => {
        builder.addCase(registrationActions.reset, (): ReopeningKycState => INITIAL_STATE)
    }
})

export default slice.reducer

/* ------------- Actions ------------- */

export const actions = {
    ...slice.actions,
    deleteMaritalName: createAction(`${SLICE_NAME}/DELETE_MARITAL_NAME`),
    saveAddress: createAction(`${SLICE_NAME}/SAVE_ADDRESS`),
    savePersonalInformation: createAction(`${SLICE_NAME}/SAVE_PERSONAL_INFORMATION`)
}

/* ------------- Selectors ------------- */

const getReopeningKycState = (state: RootState) => state.reopeningKyc

const getCurrentSection = createSelector([getReopeningKycState], (state) => state.currentSection)

const isFetched = createSelector([getReopeningKycState], (state) => state.isFetched)

const isFetching = createSelector([getReopeningKycState], (state) => state.isFetching)

const isSending = createSelector([getReopeningKycState], (state) => state.isSending)

const getContactDetails = createSelector([getReopeningKycState], (state) => state.contactDetails ?? {})

const getPersonalInformation = createSelector([getReopeningKycState], (state) => state.personnalInformation ?? {})

const isAddressFormValid = addressSelectors.isAddressValid(REOPENING_KYC_CONTACT_DETAILS)

const isMailFormValid = formsSelectors.getFieldIsValid(`${REOPENING_KYC_CONTACT_DETAILS}.${MailFormName}.${MAIL}`)

const isPersonalInformationValid = createSelector(
    [
        formsSelectors.getFieldValue<string | undefined>(
            `${REOPENING_KYC_PERSONAL_INFORMATION}.${BirthFieldNames.NATIONALITY}`
        ),
        formsSelectors.getFieldValue<CivilDataViewTitleEnum | undefined>(
            `${REOPENING_KYC_PERSONAL_INFORMATION}.${TITLE}`
        )
    ],
    (nationality, title) => {
        return isSet(nationality) && isSet(title)
    }
)

const isContactDetailsValid = createSelector(
    [isPersonalInformationValid, getContactDetails, mailSelectors.isValid],
    (_isPersonalInformationValid, contactDetails, isEmailValid) => {
        if (!_isPersonalInformationValid) return false
        const { address: { value: address = {} } = {} } = contactDetails
        return (
            areSet(
                address[AddressFieldNames.LOCALITY],
                address[AddressFieldNames.POSTAL_CODE],
                address[AddressFieldNames.STREET_NAME]
            ) && isEmailValid
        )
    }
)

const isSituationValid = createSelector(
    [
        isPersonalInformationValid,
        isContactDetailsValid,
        professionSelectors.isProfessionValid,
        financialSelectors.isFinancialValid(REOPENING_KYC_SITUATION),
        propertyStatusSelectors.isPropertyStatusValid(REOPENING_KYC_SITUATION),
        usPersonSelectors.isUsPersonValid(REOPENING_KYC_SITUATION),
        taxResidenceSelectors.isTaxResidenceValid(REOPENING_KYC_SITUATION),
        familyStatusSelectors.isFamilyStatusValid(REOPENING_KYC_SITUATION)
    ],
    (
        _isPersonalInformationValid,
        _isContactDetailsValid,
        isProfessionValid,
        isFinancialValid,
        isPropertyStatusValid,
        isUsPersonValid,
        isTaxResidenceValid,
        isFamilyStatusValid
    ) => {
        if (!_isPersonalInformationValid || !_isContactDetailsValid) return false
        return areTrue(
            isProfessionValid,
            isFinancialValid,
            isPropertyStatusValid,
            isUsPersonValid,
            isTaxResidenceValid,
            isFamilyStatusValid
        )
    }
)

const isAccountUseValid = createSelector(
    [
        accountTypeSelectors.isAccountTypeValid(REOPENING_KYC_ACCOUNT_USE),
        accountUseSelectors.isAccountUseValid(REOPENING_KYC_ACCOUNT_USE)
    ],
    areTrue
)

const isAllSectionsValid = createSelector(
    [isAccountUseValid, isContactDetailsValid, isPersonalInformationValid, isSituationValid],
    areTrue
)

const isSectionActivated = (section: string) =>
    createSelector([getCurrentSection], (currentSection) => {
        const currentSectionIndex = REOPENING_KYC_SECTIONS.indexOf(currentSection)
        const sectionIndex = REOPENING_KYC_SECTIONS.indexOf(section)

        return currentSectionIndex > -1 && sectionIndex > -1 && sectionIndex <= currentSectionIndex
    })

export const selectors = {
    getContactDetails,
    getCurrentSection,
    getPersonalInformation,
    isAddressFormValid,
    isAllSectionsValid,
    isContactDetailsValid,
    isFetched,
    isFetching,
    isMailFormValid,
    isPersonalInformationValid,
    isSectionActivated,
    isSending,
    isSituationValid
}
