import { useEffect } from 'react'

import { isEmpty } from 'ramda'
import { useSelector } from 'react-redux'

import screenMapper from '../navigation/mapper'
import { NavigationStep, selectors as navigationSelectors } from '../redux/navigation'

const usePreloadNextStep = () => {
    const { component: nextStep } = useSelector(navigationSelectors.getNextStep) as NavigationStep
    const { possibleNextSteps } = useSelector(navigationSelectors.getCurrentStep) as NavigationStep

    useEffect(() => {
        const timout = setTimeout(() => {
            if (possibleNextSteps && !isEmpty(possibleNextSteps)) {
                possibleNextSteps?.forEach((next) => screenMapper[next as keyof typeof screenMapper]?.preload())
            } else {
                screenMapper[nextStep as keyof typeof screenMapper]?.preload()
            }
        }, 1000)
        return () => clearTimeout(timout)
    }, [nextStep, possibleNextSteps])
}

export default usePreloadNextStep
