import * as config from '@nickel/l10n/config'
import { SupportedCountryISO2 } from '@nickel/l10n/types'

let __setSetGlobalISO2Callback: (ISO2: SupportedCountryISO2) => void

export const setSetGlobalISO2Callback = (setISO2: (ISO2: SupportedCountryISO2) => void) => {
    __setSetGlobalISO2Callback = setISO2
}

export const setGlobalISO2 = (ISO2: SupportedCountryISO2) => {
    config.setGlobalISO2(ISO2)
    __setSetGlobalISO2Callback(ISO2)
}

export const { getGlobalISO2 } = config
