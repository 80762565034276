import { createAction, createSelector } from '@reduxjs/toolkit'

import formsSelectors from '@nickel/forms/redux/selectors'
import { ACCOUNT_USE } from '@nickel/kyc/fields/names'
import { isEmptyArray } from '@nickel/utils/lib/common'

/* ------------- Types and Actions ------------- */

export const actions = {
    resetTurnover: createAction<string | undefined>('accountUse/RESET_TURNOVER')
}

/* ------------- Selectors ------------- */

const getAccountUse = (pathName?: string) =>
    formsSelectors.getFieldValue<string[]>(`${pathName ? `${pathName}.` : ''}${ACCOUNT_USE}`)

const isAccountUseValid = (pathName?: string) =>
    createSelector([getAccountUse(pathName)], (arr) => arr !== undefined && !isEmptyArray(arr))

export const selectors = {
    getAccountUse,
    isAccountUseValid
}
