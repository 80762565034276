import { useContext, useCallback } from 'react'

import { CustomUiModalContext } from './CustomModalContextProvider'

import { CustomUiDataType } from '.'

const useModal = () => {
    const { setVisible, setUiData } = useContext(CustomUiModalContext)

    const showModal = useCallback(
        (data: CustomUiDataType) => {
            setVisible(true)
            setUiData(data)
        },
        [setUiData, setVisible]
    )

    const hideModal = useCallback(() => {
        setVisible(false)
    }, [setVisible])

    return { showModal, hideModal }
}

export default useModal
