import { createSelector } from '@reduxjs/toolkit'

import formsSelectors from '@nickel/forms/redux/selectors'
import { BinaryChoice } from '@nickel/kyc/fields/binaryChoice/types'

import { ALREADY_CUSTOMER } from '../../fields/names'

/* ------------- Selectors ------------- */

const isReopening = createSelector(
    [formsSelectors.getFieldValue<string>(ALREADY_CUSTOMER)],
    (reopening) => reopening === BinaryChoice.YES
)

export const selectors = {
    isReopening
}
