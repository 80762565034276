import { useCallback, useEffect, useRef, useState } from 'react'

const USER_EVENTS: string[] = ['mousemove', 'mousedown', 'resize', 'keydown', 'touchstart', 'wheel']
const TIMEOUT_MS = 60000

const useIdle = (idleHandler: (idle: boolean) => void, timeout = TIMEOUT_MS, isEnabled = false) => {
    const timer = useRef<number | undefined>()
    const [idle, setIdle] = useState<boolean>(false)
    const [isDocumentHidden, setIsDocumentHidden] = useState<boolean>(false)

    const onUserIdle = useCallback(() => {
        if (!idle) {
            setIdle(true)
            idleHandler(true)
        }
        clearTimeout(timer.current)
    }, [idle, idleHandler])

    const onUserEvent = useCallback(() => {
        if (idle) {
            setIdle(false)
            idleHandler(false)
        }
        clearTimeout(timer.current)
        timer.current = window.setTimeout(onUserIdle, timeout)
    }, [idle, idleHandler, onUserIdle, timeout])

    const onVisibilityChange = useCallback(() => {
        setIsDocumentHidden(document.hidden)
    }, [setIsDocumentHidden])

    useEffect(() => {
        if (!isEnabled || isDocumentHidden) return

        const clear = () => {
            USER_EVENTS.forEach((type) => window.removeEventListener(type, onUserEvent))
            clearTimeout(timer.current)
        }

        clear()

        document.removeEventListener('visibilitychange', onVisibilityChange)
        document.addEventListener('visibilitychange', onVisibilityChange)

        USER_EVENTS.forEach((type) => window.addEventListener(type, onUserEvent))
        timer.current = window.setTimeout(onUserIdle, timeout)

        return clear
    }, [isDocumentHidden, isEnabled, onUserEvent, onUserIdle, onVisibilityChange, timeout])
}

export default useIdle
