import {
    buildStimulusType,
    defaultStimulusToUiDataMap,
    DisplayType,
    setStimulusToUiDataMap
} from '@nickel/stimulus/stimulus'

import { Step } from '../constants/steps'
import { actions as appActions } from '../redux/app'
import { actions as navigationActions } from '../redux/navigation'
import { POPUP_CONTEXT, pushPopupTriggeredToGtm } from '../utils/tracking'

export const StimulusType = buildStimulusType({
    BAD_REQUEST: 'BAD_REQUEST',
    REFERRAL_CODE_SERVICE_UNAVAIBLE: 'REFERRAL_CODE_SERVICE_UNAVAIBLE',
    SCANNER_FAILURE: 'SCANNER_FAILURE',
    SERVER_ERROR: 'SERVER_ERROR',
    UNAUTHORIZED: 'UNAUTHORIZED',
    WRONG_SMS_CHALLENGE: 'WRONG_SMS_CHALLENGE'
})

export const stimulusToUiDataMap = new Map()

stimulusToUiDataMap.set(StimulusType.ALL, () => ({
    displayTypes: [DisplayType.MODAL]
}))

stimulusToUiDataMap.set(StimulusType.NICKEL_SERVICE_UNAVAIBLE, () => {
    pushPopupTriggeredToGtm(POPUP_CONTEXT.defaultError)
    return {
        ...defaultStimulusToUiDataMap.get('NICKEL_SERVICE_UNAVAIBLE')?.(),
        primaryAction: {
            action: appActions.reset(),
            text: 'common:quit'
        }
    }
})

stimulusToUiDataMap.set(StimulusType.BAD_REQUEST, () => {
    pushPopupTriggeredToGtm(POPUP_CONTEXT.defaultError)
    return {
        title: 'errors:oops.title',
        message: 'errors:bad-request.message',
        primaryAction: {
            text: 'common:go-back-home',
            action: appActions.reset()
        }
    }
})

stimulusToUiDataMap.set(StimulusType.REFERRAL_CODE_SERVICE_UNAVAIBLE, () => {
    pushPopupTriggeredToGtm(POPUP_CONTEXT.defaultError)
    return {
        title: 'errors:oops.title',
        message: 'errors:send-referral-code.message',
        secondaryAction: {
            text: 'errors:send-referral-code.continue-without-code'
        },
        primaryAction: {
            text: 'common:try-again',
            action: navigationActions.goToStep(Step.ALL_ELEMENTS)
        }
    }
})

stimulusToUiDataMap.set(StimulusType.UNAUTHORIZED, () => {
    pushPopupTriggeredToGtm(POPUP_CONTEXT.defaultError)
    return {
        ...defaultStimulusToUiDataMap.get('UNAUTHORIZED')?.(),
        primaryAction: {
            action: appActions.reset(),
            text: 'common:quit'
        }
    }
})

stimulusToUiDataMap.set(StimulusType.SCANNER_FAILURE, () => {
    pushPopupTriggeredToGtm(POPUP_CONTEXT.defaultError)
    return {
        title: 'errors:oops.title',
        message: 'errors:scanner-failure.message',
        secondaryAction: {
            text: 'common:go-back-home',
            action: appActions.reset()
        },
        primaryAction: {
            text: 'common:try-again'
        }
    }
})

stimulusToUiDataMap.set(StimulusType.SERVER_ERROR, () => {
    pushPopupTriggeredToGtm(POPUP_CONTEXT.defaultError)
    return {
        title: 'errors:default.title',
        message: 'errors:default.message',
        primaryAction: {
            text: 'common:go-back-home',
            action: appActions.reset()
        }
    }
})

stimulusToUiDataMap.set(StimulusType.WRONG_SMS_CHALLENGE, () => {
    pushPopupTriggeredToGtm(POPUP_CONTEXT.defaultError)
    return {
        title: 'errors:oops.title',
        message: 'errors:wrong-sms-challenge.message',
        secondaryAction: {
            text: 'common:go-back-home',
            action: appActions.reset()
        },
        primaryAction: {
            text: 'common:try-again'
        }
    }
})

setStimulusToUiDataMap(stimulusToUiDataMap)
