import { useCallback, useContext } from 'react'

import { useDispatch } from 'react-redux'

import { CaptchaContext, CaptchaContextType } from '../components/Captcha/Captcha'
import { actions as captchaActions } from '../redux/captcha'

const useCaptcha = () => {
    const { isCaptchaExecuted, setIsCaptchaExecuted, isCaptchaFeatureEnabled } = useContext(
        CaptchaContext
    ) as CaptchaContextType

    const dispatch = useDispatch()

    const executeCaptchaChallenge = useCallback(() => {
        setIsCaptchaExecuted(true)
    }, [setIsCaptchaExecuted])

    const resetCaptchaChallenge = useCallback(() => {
        setIsCaptchaExecuted(false)
        dispatch(captchaActions.reset())
    }, [dispatch, setIsCaptchaExecuted])

    return {
        executeCaptchaChallenge,
        isCaptchaExecuted,
        resetCaptchaChallenge,
        setIsCaptchaExecuted,
        isCaptchaFeatureEnabled
    }
}

export default useCaptcha
