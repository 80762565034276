import 'url-search-params-polyfill'
import React from 'react'

import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'

import Suspense from '@nickel/kyc/utils/Suspence'

import App from './App'
import PaylineProvider from './app/components/PaylineWidget/PaylineProvider'
import i18n from './app/i18n'

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <Suspense fallback={null}>
            <BrowserRouter>
                <PaylineProvider baseUrl={window.REACT_APP_PAYLINE_WIDGET_BASE_URL}>
                    <App />
                </PaylineProvider>
            </BrowserRouter>
        </Suspense>
    </I18nextProvider>,
    document.getElementById('root')
)
