import { createSelector, Selector } from '@reduxjs/toolkit'
import { isNil, isEmpty } from 'ramda'

import formsSelectors from '@nickel/forms/redux/selectors'
import { BinaryChoice } from '@nickel/kyc/fields/binaryChoice/types'
import { FISCAL_ADDRESS } from '@nickel/kyc/fields/names'
import { AddressFieldNames } from '@nickel/kyc/forms/address/constants'
import { FiscalAddressFieldNames, FiscalAddressFormName } from '@nickel/kyc/forms/fiscalAddress/constants'
import { areSet } from '@nickel/utils/lib/common'

import { RootState } from '..'
import { Role } from '../../constants/roles'
import { FISCAL_ADDRESS_CHOICE } from '../../fields/names'
import { UpdatePostalAddressPayload } from '../../services'
import { FiscalAddress } from '../registration'

/* ------------- Selectors ------------- */

const getIsAddressSameAsFiscalAddress = createSelector(
    [formsSelectors.getFieldValue<BinaryChoice>(FISCAL_ADDRESS_CHOICE)],
    (choice) => {
        if (isNil(choice) || isEmpty(choice)) return
        return choice === BinaryChoice.YES
    }
)
const getFiscalAddress = (pathName: Role | string) =>
    formsSelectors.getFieldValue<FiscalAddress>(`${pathName}.${FiscalAddressFormName}.${FISCAL_ADDRESS}`)
const getAdditionalInfo = (pathName: Role | string) =>
    formsSelectors.getFieldValue<string>(
        `${pathName}.${FiscalAddressFormName}.${FiscalAddressFieldNames.ADDITIONAL_INFO}`
    )
const getCountry = (pathName: Role | string) =>
    formsSelectors.getFieldValue<string>(`${pathName}.${FiscalAddressFormName}.${FiscalAddressFieldNames.COUNTRY}`)

const getIsCountryValid = (pathName: Role | string) =>
    formsSelectors.getFieldIsValid(`${pathName}.${FiscalAddressFormName}.${FiscalAddressFieldNames.COUNTRY}`)

const getFiscalAddressData: (
    pathName: Role | string
) => Selector<RootState, UpdatePostalAddressPayload | undefined | null> = (pathName: Role | string) =>
    createSelector(
        [
            getAdditionalInfo(pathName),
            getCountry(pathName),
            getIsAddressSameAsFiscalAddress,
            getFiscalAddress(pathName)
        ],
        (additionalInfo, country, sameAsAddress, fiscalAddress = {}) =>
            sameAsAddress
                ? null
                : {
                      additionalAddress: additionalInfo,
                      countryCode: country ?? '',
                      place: fiscalAddress[FiscalAddressFieldNames.LOCALITY],
                      region: fiscalAddress[FiscalAddressFieldNames.ADMINISTRATIVE_AREA],
                      streetName: fiscalAddress[FiscalAddressFieldNames.STREET_NAME] ?? '',
                      streetNumber: fiscalAddress[FiscalAddressFieldNames.STREET_NUMBER],
                      zipCode: fiscalAddress[FiscalAddressFieldNames.POSTAL_CODE] ?? ''
                  }
    )

const getIsPostalCodeValid = (roleKey: Role | string) =>
    formsSelectors.getFieldIsValid(
        `${roleKey}.${FiscalAddressFormName}.${FISCAL_ADDRESS}.${FiscalAddressFieldNames.POSTAL_CODE}`
    )

const getIsStreetNumberValid = (roleKey: Role | string) =>
    formsSelectors.getFieldIsValid(
        `${roleKey}.${FiscalAddressFormName}.${FISCAL_ADDRESS}.${AddressFieldNames.STREET_NUMBER}`
    )

const getIsLocalityValid = (roleKey: Role | string) =>
    formsSelectors.getFieldIsValid(
        `${roleKey}.${FiscalAddressFormName}.${FISCAL_ADDRESS}.${AddressFieldNames.LOCALITY}`
    )

const getIsStreetNameValid = (roleKey: Role | string) =>
    formsSelectors.getFieldIsValid(
        `${roleKey}.${FiscalAddressFormName}.${FISCAL_ADDRESS}.${AddressFieldNames.STREET_NAME}`
    )

export const isFiscalAddressValid = (pathName: Role | string) =>
    createSelector(
        [
            getCountry(pathName),
            getIsCountryValid(pathName),
            getIsPostalCodeValid(pathName),
            getFiscalAddress(pathName),
            getIsStreetNumberValid(pathName),
            getIsLocalityValid(pathName),
            getIsStreetNameValid(pathName)
        ],
        (
            country,
            isCountryValid,
            isPostalCodeValid,
            fiscalAddress = {},
            isStreetNumberValid,
            isLocalityValid,
            isStreetNameValid
        ) => {
            return (
                isStreetNameValid &&
                isLocalityValid &&
                isStreetNumberValid &&
                isCountryValid &&
                isPostalCodeValid &&
                areSet(
                    country,
                    fiscalAddress[FiscalAddressFieldNames.LOCALITY],
                    fiscalAddress[FiscalAddressFieldNames.POSTAL_CODE],
                    fiscalAddress[FiscalAddressFieldNames.STREET_NAME]
                )
            )
        }
    )

export const isFiscalStepValid = createSelector(
    [getIsAddressSameAsFiscalAddress, isFiscalAddressValid(Role.ADULT)],
    (isAddressSameAsFiscalAddress, isValid) => {
        if (isNil(isAddressSameAsFiscalAddress)) return false
        return isAddressSameAsFiscalAddress || isValid
    }
)

export const selectors = {
    getIsAddressSameAsFiscalAddress,
    getFiscalAddressData,
    isFiscalAddressValid,
    isFiscalStepValid
}
