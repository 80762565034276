import { actions as navigationActions } from '../../redux/navigation'
import { ModalTemplateType } from '../../templates/types'
import { POPUP_CONTEXT, pushPopupTriggeredToGtm } from '../../utils/tracking'

export const finalScreenUserIdleModal = () => {
    pushPopupTriggeredToGtm(POPUP_CONTEXT.timeout)
    return {
        title: 'user-idle:final-screen.title',
        template: {
            type: ModalTemplateType.FINAL_SCREEN_USER_IDLE
        },
        primaryAction: {
            text: 'user-idle:final-screen.buttonTitle'
        },
        secondaryAction: {
            text: 'user-idle:final-screen.linkTitle',
            action: navigationActions.goToFirstStep()
        }
    }
}
export default () => {
    pushPopupTriggeredToGtm(POPUP_CONTEXT.timeout)
    return {
        title: 'user-idle:title',
        template: {
            type: ModalTemplateType.USER_IDLE
        },
        primaryAction: {
            text: 'user-idle:buttonTitle'
        },
        secondaryAction: {
            text: 'user-idle:linkTitle',
            action: navigationActions.goToFirstStep()
        }
    }
}
