import { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'

import { SupportedLocale } from '@nickel/i18n/lib/types'
import { Options } from '@nickel/ui/types'

import { BELGIUM_LANGUAGES } from '../../../fields/constants'
import { LANGUAGES_LABELS, setGlobalLanguage } from '../../../i18n'
import { setGlobalISO2 } from '../../../l10n/config'
import { getSupportedCountryISO2 } from '../../../l10n/utils'
import { selectors as countrySelectors } from '../../../redux/form/country'
import { GetAllowedCountriesCountriesEnum } from '../../../services'

const useLangage = () => {
    const selectedCountry = useSelector(countrySelectors.getCountry)
    const [languages, setLanguages] = useState<Options<SupportedLocale>>([])
    const language = useSelector(countrySelectors.getLanguage)

    useEffect(() => {
        const isCrowdinInContextSetup = sessionStorage.getItem('CROWDIN_IN_CONTEXT')
        return isCrowdinInContextSetup === 'true'
            ? setGlobalLanguage(SupportedLocale.ACH_UG)
            : setGlobalLanguage(language)
    }, [language])

    useEffect(() => {
        if (selectedCountry === GetAllowedCountriesCountriesEnum.Be) {
            setLanguages(BELGIUM_LANGUAGES.map((locale) => ({ label: LANGUAGES_LABELS[locale], value: locale })))
        }
        setGlobalISO2(getSupportedCountryISO2(selectedCountry))
    }, [selectedCountry])

    return { languages, language }
}

export default useLangage
