import axios, { AxiosError, AxiosInstance, AxiosPromise, AxiosResponse } from 'axios'
import get from 'lodash/fp/get'
import { SagaIterator } from 'redux-saga'
import { call, delay } from 'redux-saga/effects'

import { createStimulus } from '@nickel/stimulus/stimulus'

import { StimulusType } from '../../config/errors'

const defaultHttpMaxTries = 3
const defaultHttpRetryDelay = 2000
const RECOVERY_STOP = 'stop retry'

export type AxiosFunction = (axios?: AxiosInstance, basePath?: string) => AxiosPromise
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type HttpFunction = (...args: any[]) => Promise<AxiosFunction>

export type Problem = { message: string }
export type Response = {
    detail: string
    instance: string
    status: number
    title: string
    type: string
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isAxiosError = (payload: any): payload is AxiosError<Problem> =>
    axios.isAxiosError(payload) && 'message' in payload

const defaultHandleSuccess = (response: AxiosResponse) => response.data

export const getStimulusTypeFromApiResponse = (error: AxiosError) => {
    if (!error.response) return StimulusType.NICKEL_SERVICE_UNAVAIBLE
    switch (error.response.status) {
        case 401:
        case 403:
            return StimulusType.UNAUTHORIZED
        case 408:
            return StimulusType.NICKEL_SERVICE_UNAVAIBLE
        case 400:
            return StimulusType.BAD_REQUEST
        case 500:
            return StimulusType.SERVER_ERROR
        default:
            return StimulusType.DEFAULT
    }
}

const defaultHandleError = (error: AxiosError, name = getStimulusTypeFromApiResponse(error)) => {
    return createStimulus(name, get('response.data')(error))
}

/**
 Rethrow `throw buildStopRecoveryError(error)` to stop the recovery mechanism :
 */
const defaultHandleErrorRecovery = (error: AxiosError) => {
    throw error
}

type Props = {
    maxTries?: number
    delayLength?: number
    handleSuccess?: (error: AxiosResponse) => unknown
    handleError?: (error: AxiosError<Problem>) => unknown
    handleErrorRecovery?: (error: AxiosError<Problem>) => AxiosResponse
}

export const http = <P>({
    maxTries = defaultHttpMaxTries,
    delayLength = defaultHttpRetryDelay,
    handleSuccess = defaultHandleSuccess,
    handleError = defaultHandleError,
    handleErrorRecovery = defaultHandleErrorRecovery
}: Props = {}) =>
    function* httpGenerator(fn: HttpFunction, ...args: P[]): SagaIterator {
        try {
            const request = yield call(fn, ...args)
            const result: AxiosResponse = yield call(
                retryWithRecovery,
                maxTries,
                delayLength,
                request,
                handleErrorRecovery
            )
            return handleSuccess(result)
        } catch (err) {
            if (isAxiosError(err)) {
                throw handleError(err)
            } else {
                throw err
            }
        }
    }

function* retryWithRecovery(
    maxTries: Exclude<Props['maxTries'], undefined>,
    delayLength: Exclude<Props['delayLength'], undefined>,
    fn: AxiosFunction,
    handleErrorRecovery: Exclude<Props['handleErrorRecovery'], undefined>
) {
    for (let i = 1; i <= maxTries; i += 1) {
        try {
            const result: AxiosResponse = yield call(fn)
            return result
        } catch (err) {
            if (isAxiosError(err)) {
                try {
                    return handleErrorRecovery(err)
                } catch (err2) {
                    if (isAxiosError(err2)) {
                        if (i < maxTries && err2.message !== RECOVERY_STOP) {
                            yield delay(delayLength)
                        } else {
                            throw err2
                        }
                    }
                }
            } else {
                throw err
            }
        }
    }

    throw new Error()
}

export const buildStopRecoveryError = (error: AxiosError) => {
    const errorStop: AxiosError = {
        ...error,
        message: RECOVERY_STOP,
        isAxiosError: true
    }

    throw errorStop
}
