import React from 'react'

import { useFormField } from '@nickel/forms/hooks'
import { FieldProps } from '@nickel/forms/types'
import { requiredString } from '@nickel/kyc/fields/validators'
import CountryDropdown from '@nickel/ui/components/legacy/form/downshift/CountryDropdown'
import Field from '@nickel/ui/components/legacy/form/Field'
import { Options } from '@nickel/ui/types'

import { GetAllowedCountriesCountriesEnum } from '../services'

import { COUNTRY } from './names'

interface Props extends FieldProps<GetAllowedCountriesCountriesEnum | null> {
    options: Options<GetAllowedCountriesCountriesEnum>
}

const CountryField = ({ options, ...props }: Props) => {
    const { contextValue, error, inputProps } = useFormField<GetAllowedCountriesCountriesEnum | null>(
        COUNTRY,
        [requiredString],
        props
    )

    return (
        <Field error={error} htmlFor={COUNTRY}>
            <CountryDropdown<GetAllowedCountriesCountriesEnum>
                {...inputProps}
                name={COUNTRY}
                options={options}
                value={contextValue}
            />
        </Field>
    )
}

export default CountryField
