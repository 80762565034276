import { createSelector } from '@reduxjs/toolkit'
import { equals } from 'ramda'

import formsSelectors from '@nickel/forms/redux/selectors'
import { IDENTITY_DOCUMENT_TYPE } from '@nickel/kyc/fields/names'

import { selectors as navigationSelectors } from '..'
import { RootState } from '../..'
import { Role } from '../../../constants/roles'

const get = createSelector([navigationSelectors.getCurrentStep], (s) => s.role ?? Role.ADULT)

const getIsChild = createSelector([get], equals(Role.CHILD))

const getIdentityDocumentType = createSelector([get, (state: RootState) => state], (currentRole, state) =>
    formsSelectors.getFieldValue<string>(`${currentRole}.${IDENTITY_DOCUMENT_TYPE}`)(state)
)

export const selectors = {
    get,
    getIdentityDocumentType,
    getIsChild
}
