import { DependencyList, useEffect } from 'react'

export const isCustomEvent = (event: Event): event is CustomEvent => {
    return (event as CustomEvent).detail !== undefined
}

const useEventListener = (
    eventName: string,
    callback: (event: Event) => void,
    deps: DependencyList,
    { target = window }: { target?: HTMLElement | Window | Document } = { target: undefined }
) => {
    useEffect(() => {
        target.addEventListener(eventName, callback)
        return () => target.removeEventListener(eventName, callback)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps)
}

export default useEventListener
