import { PayloadAction } from '@reduxjs/toolkit'
import { all, call, put, select, takeLatest, takeLeading } from 'redux-saga/effects'

import formsActions from '@nickel/forms/redux/actions'
import { BinaryChoice } from '@nickel/kyc/fields/binaryChoice/types'
import { ACCOUNT_USE, TAX_RESIDENCE_COUNTRIES } from '@nickel/kyc/fields/names'
import { getGlobalISO2 } from '@nickel/l10n/config'
import { COUNTRY_CODE } from '@nickel/l10n/constants'
import { getISO2FromZipCode } from '@nickel/l10n/utils'
import { actions as uiActions } from '@nickel/stimulus/ui'
import { isSet } from '@nickel/utils/lib/common'

import {
    HAS_OTHER_TAX_RESIDENCE_ADDRESS_NAME,
    TAX_RESIDENCES_INFOS_NAME,
    TAX_RESIDENT_IN_CURRENT_COUNTRY_CHOICE
} from '../../components/TaxResidenceInformations/constant'
import {
    hasOnlySelectCurrentCountryModalData,
    blankTaxResidenceIdentificationReminderModalData
} from '../../constants/modals/taxResidence'
import { CHALLENGE } from '../../screens/SMSChallenge/Challenge/constants'
import { POPUP_CONTEXT, pushPopupTriggeredToGtm } from '../../utils/tracking'
import { actions as appActions, selectors as appSelectors } from '../app'
import { actions as navigationActions } from '../navigation'

import { actions as accountUseActions, selectors as accountUseSelectors } from './accountUse'
import { actions as addressActions } from './address'
import { actions as challengeActions } from './challenge'
import { actions as taxResidenceActions, selectors as taxResidenceSelectors } from './taxResidence'

function* handlePostalCodeChanged({ payload: postalCode }: PayloadAction<string>) {
    const ISO2 = getISO2FromZipCode(postalCode) || getGlobalISO2()
    yield put(
        formsActions.changeFieldValue({
            fieldPath: 'phone.region',
            fieldValue: ISO2 === COUNTRY_CODE.TF ? COUNTRY_CODE.RE : ISO2
        })
    )
}

function* handleResetChallenge() {
    yield put(
        formsActions.changeFieldValue({
            fieldPath: CHALLENGE,
            fieldValue: ''
        })
    )
}

function* handleCheckChallenge({ payload: challenge }: PayloadAction<string>) {
    const hasChallengeBeenAttempted: boolean = yield select(appSelectors.getHasChallengeBeenAttempted)
    if (hasChallengeBeenAttempted || !isSet(challenge)) return
    yield put(appActions.attemptChallenge())
}

function* handleCheckTaxResidenceConsistency() {
    const hasNotSelectedEnoughCountries: boolean = yield select(
        taxResidenceSelectors.getHasNotSelectedEnoughCountries()
    )
    if (hasNotSelectedEnoughCountries) {
        yield put(uiActions.showModal(hasOnlySelectCurrentCountryModalData))
        return
    }
    const atLeastOneBlankTaxResidenceIdentification: boolean = yield select(
        taxResidenceSelectors.getAtLeastOneBlankTaxResidenceIdentification
    )
    if (atLeastOneBlankTaxResidenceIdentification) {
        pushPopupTriggeredToGtm(POPUP_CONTEXT.missingNIF)
        yield put(uiActions.showModal(blankTaxResidenceIdentificationReminderModalData))
        return
    }

    yield put(navigationActions.goToNextStep())
}

function* handleConfirmTaxResidenceInCurrentCountry() {
    yield put(
        formsActions.changeFieldValue({
            fieldPath: `${TAX_RESIDENCES_INFOS_NAME}.${TAX_RESIDENT_IN_CURRENT_COUNTRY_CHOICE}`,
            fieldValue: BinaryChoice.YES
        })
    )
    yield put(navigationActions.goToNextStep())
}

function* handleResetTaxResidenceInCurrentCountry() {
    yield put(
        formsActions.changeFieldValue({
            fieldPath: TAX_RESIDENCE_COUNTRIES,
            fieldValue: { taxResidences: [{ country: getGlobalISO2() }] }
        })
    )
    yield call(handleConfirmTaxResidenceInCurrentCountry)
}

function* handleResetOtherTaxResidenceAddress() {
    yield put(
        formsActions.changeFieldValue({
            fieldPath: `${TAX_RESIDENCE_COUNTRIES}.${HAS_OTHER_TAX_RESIDENCE_ADDRESS_NAME}`,
            fieldValue: undefined
        })
    )
}

function* handleResetTurnover({ payload: pathName }: PayloadAction<string | undefined>) {
    const uses: string[] | undefined = yield select(accountUseSelectors.getAccountUse(pathName))
    if (!uses) return

    yield put(
        formsActions.changeFieldValue({
            fieldPath: `${pathName ? `${pathName}.` : ''}${ACCOUNT_USE}`,
            fieldValue: uses
        })
    )
}

export default function* root() {
    yield all([
        takeLatest(addressActions.postalCodeChanged, handlePostalCodeChanged),
        takeLatest(accountUseActions.resetTurnover, handleResetTurnover),
        takeLatest(challengeActions.resetChallenge, handleResetChallenge),
        takeLeading(challengeActions.checkChallenge, handleCheckChallenge),
        takeLatest(taxResidenceActions.checkTaxResidenceConsistency, handleCheckTaxResidenceConsistency),
        takeLatest(taxResidenceActions.confirmTaxResidenceInCurrentCountry, handleConfirmTaxResidenceInCurrentCountry),
        takeLatest(taxResidenceActions.resetTaxResidenceInCurrentCountry, handleResetTaxResidenceInCurrentCountry),
        takeLatest(taxResidenceActions.resetOtherTaxResidenceAddress, handleResetOtherTaxResidenceAddress)
    ])
}
