import { all, delay, put, select, takeEvery } from 'redux-saga/effects'

import { isTerminal } from '@nickel/utils/lib/web'

import { actions as navigationActions, selectors as navigationSelectors } from '../navigation'
import { isRootRoute } from '../navigation/utils'
import { actions as registrationActions } from '../registration'

import { actions as appActions } from '.'

export function* onReset() {
    const currentRoute: string = yield select(navigationSelectors.getCurrentRoute)
    yield put(registrationActions.reset())
    if (isRootRoute(currentRoute)) return

    yield put(navigationActions.goToFirstStep())
    if (isTerminal) return

    yield delay(1)
    window.location.reload()
}

function* onSaveReferralCodeAndNext() {
    yield put(appActions.saveReferralCode())
    yield put(navigationActions.goToNextStep())
}

export default function* root() {
    yield all([
        takeEvery(appActions.reset, onReset),
        takeEvery(appActions.saveReferralCodeAndNext, onSaveReferralCodeAndNext)
    ])
}
