import styled from 'styled-components'

import { Spacing } from '@nickel/foundations/tokens'

const DropdownWrapper = styled.div`
    margin: 0 auto ${Spacing.MEDIUM};
    max-width: 25rem;
    text-align: left;
`

const LanguageWrapper = styled.div`
    margin-top: ${Spacing.XXLARGE};
    text-align: center;
`

const CrowdinButton = styled.div`
    display: block;
    text-align: start;
    width: 400px;
    word-break: keep-all;
`

export default {
    DropdownWrapper,
    LanguageWrapper,
    CrowdinButton
}
