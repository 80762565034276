import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native

import ReduxPersist from '../config/reduxPersist'

const updateReducers = () => {
    const { reducerVersion } = ReduxPersist
    // Check to ensure latest reducer version
    storage
        .getItem('reducerVersion')
        .then((localVersion) => {
            if (localVersion !== reducerVersion) {
                // Purge store
                storage.setItem('reducerVersion', reducerVersion)
            }
        })
        .catch(() => {
            storage.setItem('reducerVersion', reducerVersion)
        })
}

export default { updateReducers }
