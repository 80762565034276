import styled from 'styled-components'

import { Color } from '@nickel/foundations/tokens'

const ModalOverlay = styled.div`
    background-color: ${Color.MODAL_BACKGROUND};
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: all 0.3s;
    z-index: 1000;
`

export { ModalOverlay }
