/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import formsSelectors from '@nickel/forms/redux/selectors'
import { EmploymentFieldNames, EmploymentFormName } from '@nickel/kyc/forms/employment/constants'
import { AccountProfessionalUsage } from '@nickel/kyc/forms/employment/types'
import { FormData, INITIAL_VALUE } from '@nickel/onboarding/forms/profession'

import { RootState } from '..'
import {
    UpdateProfessionDataPayload,
    UpdateProfessionDataPayloadSocioProfessionalCategoryEnum,
    UpdateProfessionDataPayloadSocioProfessionalGroupEnum
} from '../../services'
import { actions as registrationActions } from '../registration'

/* ---------- State ---------- */

type professionState = {
    values: FormData
    state: { isValid: boolean }
}

export const INITIAL_STATE: professionState = {
    values: INITIAL_VALUE,
    state: { isValid: false }
}

/* ------------- Slice & Reducers ------------- */

const SLICE_NAME = '@@profession'

export const counterSlice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        setValidity: (state, action: PayloadAction<boolean>) => {
            state.state.isValid = action.payload
        },
        setValues: (state, action: PayloadAction<FormData>) => {
            state.values = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(registrationActions.reset, () => INITIAL_STATE)
    }
})

export default counterSlice.reducer

/* ------------- Actions ------------- */

export const { setValidity, setValues } = counterSlice.actions

/* ------------- Selectors ------------- */

const getAccountProfessionalUsage = (pathName?: string) =>
    formsSelectors.getFieldValue<AccountProfessionalUsage>(
        `${pathName ? `${pathName}.` : ''}${EmploymentFormName}.${EmploymentFieldNames.ACCOUNT_PROFESSIONAL_USAGE}`
    )

const getProfessionPayload = (state: RootState): UpdateProfessionDataPayload => {
    const data = state.profession.values
    return {
        activitySector: data.professionSector === '' ? undefined : data.professionSector,
        employerName: data.employerName,
        formerJob: data.jobName,
        jobName: data.jobName,
        socioProfessionalCategory:
            data.professionCategory === ''
                ? UpdateProfessionDataPayloadSocioProfessionalCategoryEnum.Unknown
                : data.professionCategory,
        socioProfessionalGroup:
            data.professionGroup === ''
                ? UpdateProfessionDataPayloadSocioProfessionalGroupEnum.Unknown
                : data.professionGroup
    }
}

export const selectors = {
    getAccountProfessionalUsage,
    getProfessionData: (state: RootState) => state.profession.values,
    getProfessionPayload,
    isProfessionValid: (state: RootState) => state.profession.state.isValid
}
