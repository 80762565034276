import { createSelector } from '@reduxjs/toolkit'

import formsSelectors from '@nickel/forms/redux/selectors'
import { FamilyStatus } from '@nickel/kyc/fields/familyStatus/types'
import { FAMILY_STATUS } from '@nickel/kyc/fields/names'
import { isSet } from '@nickel/utils/lib/common'

/* ------------- Selectors ------------- */

const getFamilyStatus = (pathName?: string) =>
    formsSelectors.getFieldValue<FamilyStatus>(`${pathName ? `${pathName}.` : ''}${FAMILY_STATUS}`)

const isFamilyStatusValid = (pathName?: string) => createSelector([getFamilyStatus(pathName)], isSet)

export const selectors = {
    getFamilyStatus,
    isFamilyStatusValid
}
