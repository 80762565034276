import { createAction, createSelector } from '@reduxjs/toolkit'

import formsSelectors from '@nickel/forms/redux/selectors'
import { ADDRESS } from '@nickel/kyc/fields/names'
import { AddressFieldNames, AddressFormName } from '@nickel/kyc/forms/address/constants'
import { areSet, isSet } from '@nickel/utils/lib/common'

import { Role } from '../../constants/roles'
import { GetAllowedCountriesCountriesEnum } from '../../services'
import { selectors as appSelectors } from '../app'
import { Address } from '../registration'

import { selectors as countrySelectors } from './country'

/* ------------- Types and Actions ------------- */

export const actions = {
    postalCodeChanged: createAction<string>('address/POSTAL_CODE_CHANGED')
}

/* ------------- Selectors ------------- */

const getIsAddressFieldValid = (pathName: Role | string) =>
    formsSelectors.getFieldIsValid(`${pathName}.${AddressFormName}.${ADDRESS}`)
const getAddressFieldMeta = (pathName: Role | string) =>
    formsSelectors.getFieldMeta(`${pathName}.${AddressFormName}.${ADDRESS}`)
const getAddress = (pathName: Role | string) =>
    formsSelectors.getFieldValue<Address>(`${pathName}.${AddressFormName}.${ADDRESS}`)
const getAdditionalInfo = (pathName: Role | string) =>
    formsSelectors.getFieldValue<string>(`${pathName}.${AddressFormName}.${AddressFieldNames.ADDITIONAL_INFO}`)

const getAddressData = (pathName: Role | string) =>
    createSelector([getAdditionalInfo(pathName), getAddress(pathName)], (additionalInfo, address = {}) => ({
        additionalAddress: additionalInfo,
        countryCode: window.REACT_APP_COUNTRY_CODE,
        place: address[AddressFieldNames.LOCALITY],
        region: address[AddressFieldNames.ADMINISTRATIVE_AREA],
        streetName: address[AddressFieldNames.STREET_NAME] ?? '',
        streetNumber: address[AddressFieldNames.STREET_NUMBER],
        zipCode: address[AddressFieldNames.POSTAL_CODE] ?? ''
    }))

const getIsPostalCodeValid = (roleKey: Role | string) =>
    formsSelectors.getFieldIsValid(`${roleKey}.${AddressFormName}.${ADDRESS}.${AddressFieldNames.POSTAL_CODE}`)

const getIsStreetNumberValid = (roleKey: Role | string) =>
    formsSelectors.getFieldIsValid(`${roleKey}.${AddressFormName}.${ADDRESS}.${AddressFieldNames.STREET_NUMBER}`)

const getIsLocalityValid = (roleKey: Role | string) =>
    formsSelectors.getFieldIsValid(`${roleKey}.${AddressFormName}.${ADDRESS}.${AddressFieldNames.LOCALITY}`)

const getIsStreetNameValid = (roleKey: Role | string) =>
    formsSelectors.getFieldIsValid(`${roleKey}.${AddressFormName}.${ADDRESS}.${AddressFieldNames.STREET_NAME}`)

export const isAddressValid = (pathName: Role | string) =>
    createSelector(
        [
            getIsPostalCodeValid(pathName),
            countrySelectors.getCountry,
            appSelectors.getIsAddressAutocompleteEnabled,
            getIsAddressFieldValid(pathName),
            getAddressFieldMeta(pathName),
            getAddress(pathName),
            getIsStreetNumberValid(pathName),
            getIsLocalityValid(pathName),
            getIsStreetNameValid(pathName)
        ],
        (
            isPostalCodeValid,
            country,
            isAddressAutocompleteEnabled,
            isAddressFieldValid,
            addressFieldMeta,
            address = {},
            isStreetNumberValid,
            isLocalityValid,
            isStreetNameValid
        ) => {
            if (isAddressAutocompleteEnabled) return isAddressFieldValid && (addressFieldMeta?.isTouched ?? false)

            const hasAdministrativeArea =
                country === GetAllowedCountriesCountriesEnum.Es
                    ? isSet(address[AddressFieldNames.ADMINISTRATIVE_AREA])
                    : true
            return (
                isStreetNameValid &&
                isLocalityValid &&
                isStreetNumberValid &&
                isPostalCodeValid &&
                hasAdministrativeArea &&
                areSet(
                    address[AddressFieldNames.LOCALITY],
                    address[AddressFieldNames.POSTAL_CODE],
                    address[AddressFieldNames.STREET_NAME]
                )
            )
        }
    )

export const selectors = {
    getAddressData,
    getIsAddressFieldValid,
    isAddressValid
}
