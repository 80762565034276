import { createSelector } from '@reduxjs/toolkit'

import formsSelectors from '@nickel/forms/redux/selectors'
import { SupportedLocale } from '@nickel/i18n/lib/types'

import { COUNTRY, LANGUAGE } from '../../fields/names'
import { GetAllowedCountriesCountriesEnum } from '../../services'

import { getLanguageFromCountry, getNavigatorCountry } from './utils'

/* ------------- Selectors ------------- */

const getCountry = createSelector(
    [formsSelectors.getFieldValue<GetAllowedCountriesCountriesEnum>(COUNTRY)],
    (country) => country ?? getNavigatorCountry()
)

const getLanguage = createSelector(
    [getCountry, formsSelectors.getFieldValue<SupportedLocale>(LANGUAGE)],
    getLanguageFromCountry
)

export const selectors = {
    getCountry,
    getLanguage
}
