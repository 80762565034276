import { RegistrationType } from '@nickel/kyc/fields/registrationType/types'

import { actions as navigationActions } from '../../redux/navigation'
import { CardReceptionModeEnum } from '../../screens/CardReceptionMode/types'
import { RegistrationDocumentIsBannedViewReasonEnum } from '../../services/api'
import { ModalData, ModalTemplateType } from '../../templates/types'
import { getRootRoute } from '../../utils'
import { POPUP_CONTEXT, pushPopupTriggeredToGtm } from '../../utils/tracking'
import { Role } from '../roles'

export const createBannedIdentityDocumentErrorModalData = (
    role: Role,
    reason: RegistrationDocumentIsBannedViewReasonEnum,
    storeId?: string,
    cardReceptionMode: CardReceptionModeEnum = CardReceptionModeEnum.CLASSIC
): ModalData => {
    pushPopupTriggeredToGtm(POPUP_CONTEXT.documentRefused)
    return {
        title: `identity-document:modals.banned-error.${reason}.title`,
        message: `identity-document:modals.banned-error.${reason}.message`,

        primaryAction: {
            text: 'identity-document:choose-another-identity-document',
            action: navigationActions.goToIdentityDocumentsStep({ role, storeId, cardReceptionMode })
        },

        secondaryAction: {
            text: 'common:cancel-my-account-opening',
            action: navigationActions.goToFirstStep()
        }
    }
}

export const createExpiredIdentityDocumentErrorModalData = (
    role: Role,
    storeId?: string,
    cardReceptionMode: CardReceptionModeEnum = CardReceptionModeEnum.CLASSIC
): ModalData => {
    pushPopupTriggeredToGtm(POPUP_CONTEXT.expiredId)
    return {
        title: 'identity-document:modals.expired-error.title',
        message: 'identity-document:modals.expired-error.message',

        primaryAction: {
            action: navigationActions.goToIdentityDocumentsStep({ role, storeId, cardReceptionMode }),
            text: 'identity-document:choose-another-identity-document'
        },

        secondaryAction: {
            text: 'common:cancel-my-account-opening',
            action: navigationActions.goToFirstStep()
        }
    }
}

export const createProcessingIdentityDocumentErrorModalData = (
    role: Role,
    storeId: string,
    cardReceptionMode: CardReceptionModeEnum = CardReceptionModeEnum.CLASSIC
): ModalData => {
    pushPopupTriggeredToGtm(role === Role.CHILD ? POPUP_CONTEXT.youngId : POPUP_CONTEXT.adultId)
    return {
        title: `identity-document:modals.processing-error.title${role === Role.CHILD ? '_child' : ''}`,
        message: `identity-document:modals.processing-error.message${role === Role.CHILD ? '_child' : ''}`,

        primaryAction: {
            text: 'identity-document:choose-another-identity-document',
            action: navigationActions.goToIdentityDocumentsStep({ role, storeId, cardReceptionMode })
        },

        secondaryAction: {
            text: 'common:cancel-my-account-opening',
            action: navigationActions.goToFirstStepWithResetDocument(storeId)
        }
    }
}

export const forgottenDocumentWarningModalData: ModalData = {
    title: 'identity-document:modals.forgotten-document-warning.title',
    primaryAction: {
        action: navigationActions.goToNextStep(),
        text: 'identity-document:modals.forgotten-document-warning.button'
    },
    template: {
        type: ModalTemplateType.FORGOTTEN_DOCUMENT_WARNING
    }
}

export const adultInvalidAgeIdentityDocumentErrorModalData = (
    storeId?: string,
    cardReceptionMode: CardReceptionModeEnum = CardReceptionModeEnum.CLASSIC
): ModalData => {
    pushPopupTriggeredToGtm(POPUP_CONTEXT.youngId)
    return {
        title: 'identity-document:modals.invalid-age-error.adult.title',
        message: 'identity-document:modals.invalid-age-error.adult.message',
        primaryAction: {
            action: navigationActions.goToIdentityDocumentsStep({ role: Role.ADULT, storeId, cardReceptionMode }),
            text: 'identity-document:choose-another-identity-document'
        },
        secondaryAction: {
            action: navigationActions.goToExternal(
                getRootRoute(`registrationType=${RegistrationType.COMPTE_NICKEL_JEUNE}`)
            ),
            text: 'identity-document:modals.invalid-age-error.adult.secondary-action'
        }
    }
}

export const childInvalidAgeIdentityDocumentErrorModalData = (storeId?: string): ModalData => {
    pushPopupTriggeredToGtm(POPUP_CONTEXT.adultId)
    return {
        title: 'identity-document:modals.invalid-age-error.child.title',
        message: 'identity-document:modals.invalid-age-error.child.message',
        primaryAction: {
            action: navigationActions.goToIdentityDocumentsStep({ role: Role.CHILD, storeId }),
            text: 'identity-document:choose-another-identity-document'
        },
        secondaryAction: {
            action: navigationActions.goToExternal(
                getRootRoute(`registrationType=${RegistrationType.COMPTE_NICKEL_JEUNE}`)
            ),
            text: 'identity-document:modals.invalid-age-error.child.secondary-action'
        }
    }
}

export const guardianInvalidAgeIdentityDocumentErrorModalData = (storeId?: string): ModalData => {
    pushPopupTriggeredToGtm(POPUP_CONTEXT.youngId)
    return {
        title: 'identity-document:modals.invalid-age-error.guardian.title',
        message: 'identity-document:modals.invalid-age-error.guardian.message',
        primaryAction: {
            action: navigationActions.goToIdentityDocumentsStep({ role: Role.GUARDIAN, storeId }),
            text: 'identity-document:choose-another-identity-document'
        },
        secondaryAction: {
            action: navigationActions.goToFirstStep(),
            text: 'common:cancel-my-account-opening'
        }
    }
}

export const createInvalidAgeIdentityDocumentErrorModalData = (
    role: Role,
    storeId?: string,
    cardReceptionMode: CardReceptionModeEnum = CardReceptionModeEnum.CLASSIC
): ModalData => {
    // We disable eslint default-case check because we want TypeScript to fail if a new enum value is added
    // eslint-disable-next-line default-case
    switch (role) {
        case Role.ADULT:
            return adultInvalidAgeIdentityDocumentErrorModalData(storeId, cardReceptionMode)
        case Role.CHILD:
            return childInvalidAgeIdentityDocumentErrorModalData(storeId)
        case Role.GUARDIAN:
            return guardianInvalidAgeIdentityDocumentErrorModalData(storeId)
    }
}
