import React, { useEffect } from 'react'

import { useSelector } from 'react-redux'

import { SupportedLocale } from '@nickel/i18n/lib/types'
import Button from '@nickel/ui/components/legacy/Button'

import i18n, { setGlobalLanguage } from '../../i18n'
import { selectors as countrySelectors } from '../../redux/form/country'

import Styled from './style'

const CrowdinContextButton = () => {
    const language = useSelector(countrySelectors.getLanguage)
    // Set up de Crowdin In-Context
    const script1SetupInContext = document.createElement('script')
    const script2SetupInContext = document.createElement('script')

    script2SetupInContext.id = 'SCRIPT_CROWDIN'
    script1SetupInContext.innerText =
        'var _jipt = [];' +
        "            _jipt.push(['project', '36436b72124c4dece223843636d3ffb6']);" +
        "            _jipt.push(['domain', 'nickel']);" +
        "            alert('Launch of Crowdin inContext');"
    script2SetupInContext.src = '//cdn.crowdin.com/jipt/jipt.js'

    const onInContextSetUpClick = () => {
        // Modification de la locale pour revenir sur celle du parcours de souscription et rechargement pour supprimer les scripts
        if (i18n.language === SupportedLocale.ACH_UG) {
            sessionStorage.setItem('CROWDIN_IN_CONTEXT', 'false')
            setGlobalLanguage(language)
            cleanLocalStorage()
            window.location.reload()
        } else {
            // Modification de la locale pour passer sur celle de Crowdin In-Context
            sessionStorage.setItem('CROWDIN_IN_CONTEXT', 'true')
            setGlobalLanguage(SupportedLocale.ACH_UG)
            document.body.appendChild(script1SetupInContext)
            document.body.appendChild(script2SetupInContext)
        }
    }

    useEffect(() => {
        if (
            sessionStorage.getItem('CROWDIN_IN_CONTEXT') === 'true' &&
            !document.body.contains(document.getElementById('SCRIPT_CROWDIN'))
        ) {
            cleanLocalStorage()
            document.body.appendChild(script1SetupInContext)
            document.body.appendChild(script2SetupInContext)
        }
    }, [script1SetupInContext, script2SetupInContext])

    const cleanLocalStorage = () => {
        Object.keys(sessionStorage)
            .filter((k) => {
                return /^jipt/.test(k)
            })
            .forEach((k) => {
                sessionStorage.removeItem(k)
            })
    }

    const getCrowdinButtonLabel = () => {
        return sessionStorage.getItem('CROWDIN_IN_CONTEXT') === 'true'
            ? 'Disable Crowdin In Context'
            : 'Enable Crowdin In Context'
    }

    return (
        <>
            <Styled.CrowdinButton>
                <Button
                    type="submit"
                    link
                    onClick={onInContextSetUpClick}
                    testId="Header#Crowdin#Button"
                    text={getCrowdinButtonLabel()}
                />
            </Styled.CrowdinButton>
        </>
    )
}

export default CrowdinContextButton
