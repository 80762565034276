import { actions as navigationActions } from '../../redux/navigation'

export const getQuitApplicationModalData = (homeLocation: string) => ({
    title: 'quit-application:modal.title',
    primaryAction: {
        action: navigationActions.goToExternal(homeLocation),
        text: 'quit-application:modal.confirm-button'
    },
    secondaryAction: {
        text: 'common:cancel'
    }
})
