import { createSelector } from '@reduxjs/toolkit'
import PhoneNumber from 'awesome-phonenumber'

import formsSelectors from '@nickel/forms/redux/selectors'
import { PHONE_NUMBER } from '@nickel/kyc/fields/names'
import { isValid } from '@nickel/kyc/fields/phoneNumber/utils'
import { isSet } from '@nickel/utils/lib/common'

import { isDefined } from '../../utils'

const getPhoneNumber = formsSelectors.getFieldValue<string>(PHONE_NUMBER)

const getPhoneNumberIsValid = createSelector(
    [getPhoneNumber],
    (phoneNumber) => isDefined(phoneNumber) && isSet(phoneNumber) && isValid(phoneNumber)
)

const formatPhoneNumber = (value?: string) => (value ? new PhoneNumber(value).getNumber('international') : '')

const getFormattedPhoneNumber = createSelector([getPhoneNumber], formatPhoneNumber)

export const selectors = {
    getFormattedPhoneNumber,
    getPhoneNumber,
    getPhoneNumberIsValid
}
