import { anyPass, equals, endsWith } from 'ramda'

import { SupportedCountryISO2 } from '@nickel/l10n/types'

export const isRootRoute = anyPass([
    equals(''),
    equals('/'),
    endsWith(`-${SupportedCountryISO2.BE.toLowerCase()}`),
    endsWith(`-${SupportedCountryISO2.DE.toLowerCase()}`),
    endsWith(`-${SupportedCountryISO2.ES.toLowerCase()}`),
    endsWith(`-${SupportedCountryISO2.FR.toLowerCase()}`),
    endsWith(`-${SupportedCountryISO2.PT.toLowerCase()}`)
])
