import { ReactElement } from 'react'

import { TFunction } from 'i18next'
import { DispatchProp } from 'react-redux'
import { Action } from 'typesafe-actions'

import { SupportedLocale } from '@nickel/i18n/lib/types'
import { L10nObject } from '@nickel/l10n/createL'

export enum ModalTemplateType {
    FAMILY_REGISTER,
    FINAL_SCREEN_USER_IDLE,
    FORGOTTEN_DOCUMENT_WARNING,
    ID_NUMBER_POSITION,
    IDENTITY,
    RGPD,
    SCAN,
    USER_IDLE
}

export enum DisplayType {
    MODAL = 'MODAL',
    TOAST = 'TOAST'
}

export interface UiDataType {
    title?: string
    message?: string
    primaryAction?: {
        text: string
        action?: Action
    }
    secondaryAction?: {
        text: string
        action?: Action
    }
    displayTypes?: DisplayType[]
}

export type ModalData = UiDataType & {
    message?: string | [string, Record<string, unknown>]
    messageValues?: Record<string, unknown>
    template?: {
        type: ModalTemplateType
        payload?: number
    }
    showCloseButton?: boolean
    translationComponent?: Record<string, ReactElement>
}

export type TemplateProps = {
    dispatch: DispatchProp['dispatch']
    isVisible: boolean
    t: TFunction
    localeLanguage?: SupportedLocale
    l: <T>(l10nObject: L10nObject<T>) => T
}
