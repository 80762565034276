import { createSelector } from '@reduxjs/toolkit'

import formsSelectors from '@nickel/forms/redux/selectors'

import { REOPENING_MATCH_MULTY } from '../../screens/ReopeningMatchMulty/constants'
import { ReopeningMatchMultyState } from '../../screens/ReopeningMatchMulty/types'

/* ------------- Selectors ------------- */

const getReopening = formsSelectors.getFieldValue<ReopeningMatchMultyState>(`${REOPENING_MATCH_MULTY}`)
const getReopeningSelected = createSelector([getReopening], (root) => root?.values?.[root?.selected])

export const selectors = {
    getReopeningSelected
}
