import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../index'
import { actions as registrationActions } from '../registration'

type PdfDocument = {
    isValid: boolean
}
type EidPdfDocuments = {
    termsAndConditions: PdfDocument
    privacy: PdfDocument
}
export const INITIAL_STATE: EidPdfDocuments = { termsAndConditions: { isValid: false }, privacy: { isValid: false } }

/* ------------- Slice & Reducers ------------- */

const SLICE_NAME = '@@eid-pdf-documents'

const slice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        updateTermsAndConditions: (state, action: PayloadAction<boolean>): EidPdfDocuments => ({
            ...state,
            termsAndConditions: { isValid: action.payload }
        }),
        updatePrivacy: (state, action: PayloadAction<boolean>): EidPdfDocuments => ({
            ...state,
            privacy: { isValid: action.payload }
        })
    },
    extraReducers: (builder) => {
        builder.addCase(registrationActions.reset, (): EidPdfDocuments => INITIAL_STATE)
    }
})

export default slice.reducer

/* ------------- Actions ------------- */

export const actions = {
    ...slice.actions
}

/* ------------- Selectors ------------- */

const isTermsAndConditionsValid = (state: RootState) => state.eidPdfDocuments.termsAndConditions.isValid
const isPrivacyValid = (state: RootState) => state.eidPdfDocuments.privacy.isValid

export const selectors = {
    isTermsAndConditionsValid,
    isPrivacyValid
}
