import { L10nObject } from '@nickel/l10n/createL'
import { SupportedCountryISO2 } from '@nickel/l10n/types'

export enum SupportedLocalePath {
    DE_DE = 'de-de',
    EN_BE = 'en-be',
    ES_ES = 'es-es',
    FR_FR = 'fr-fr',
    FR_BE = 'fr-be',
    NL_BE = 'nl-be',
    PT_PT = 'pt-pt'
}

export const DefaultSupportedLocalePath: L10nObject<SupportedLocalePath> = {
    [SupportedCountryISO2.BE]: SupportedLocalePath.FR_BE,
    [SupportedCountryISO2.DE]: SupportedLocalePath.DE_DE,
    [SupportedCountryISO2.ES]: SupportedLocalePath.ES_ES,
    [SupportedCountryISO2.FR]: SupportedLocalePath.FR_FR,
    [SupportedCountryISO2.PT]: SupportedLocalePath.PT_PT
}
