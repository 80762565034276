import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { isValid } from '@nickel/kyc/fields/barcode/utils'

import { RootState } from '../../redux'
import { actions as registrationActions } from '../../redux/registration'

/* ------------- Type & State ------------- */

export type BarcodeState = {
    barcode?: string
}

export const INITIAL_STATE: BarcodeState = {}

/* ------------- Slice & Reducers ------------- */

const SLICE_NAME = '@@barcode'

const slice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        reset: (): BarcodeState => INITIAL_STATE,
        setBarcode: (state, { payload = '' }: PayloadAction<string>): BarcodeState => ({
            ...state,
            barcode: payload
        })
    },
    extraReducers: (builder) => {
        builder.addCase(registrationActions.reset, (): BarcodeState => INITIAL_STATE)
    }
})

export default slice.reducer

/* ------------- Actions ------------- */

export const actions = {
    ...slice.actions
}

/* ------------- Selectors ------------- */

const getBarcodeState = (state: RootState) => state.barcode

const getBarcode = createSelector([getBarcodeState], (state) => state.barcode ?? '')

const getIsBarcodeValid = createSelector([getBarcode], (barcode) => isValid(barcode))

export const selectors = { getBarcode, getIsBarcodeValid }
