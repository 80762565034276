import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { complement, has, omit } from 'ramda'

import { RootState } from '..'
import { actions as documentsActions } from '../documents'
import { actions as registrationActions } from '../registration'

/* ------------- Type & State ------------- */

export const AGE_STATUS = {
    NOT_VALID: 'NOT_VALID',
    VALID: 'VALID',
    UNKNOWN: 'UNKNOWN'
} as const
export type AgeStatusEnum = typeof AGE_STATUS[keyof typeof AGE_STATUS]

export const EXPIRATION_STATUS = {
    EXPIRED: 'EXPIRED',
    NOT_EXPIRED: 'NOT_EXPIRED',
    IRRELEVANT: 'IRRELEVANT',
    UNKNOWN: 'UNKNOWN'
} as const
export type ExpirationStatusEnum = typeof EXPIRATION_STATUS[keyof typeof EXPIRATION_STATUS]

export type IdentityDocument = {
    ageStatus?: AgeStatusEnum
    expirationStatus?: ExpirationStatusEnum
    isBanned?: boolean
    ocrProperties?: unknown
}

type CompleteAgeCheckPayload = {
    storeId: string
    ageStatus: AgeStatusEnum
}

type CompleteBanCheckPayload = {
    storeId: string
    isBanned: boolean
}

type CompleteExpirationCheckPayload = {
    storeId: string
    expirationStatus: ExpirationStatusEnum
}

type CompleteOcrPayload = {
    storeId: string
    ocrProperties: unknown
}

export type FormProperties = {
    [fieldName: string]: string
}

type IdentityDocuments = Record<string, IdentityDocument>

export const INITIAL_STATE: IdentityDocuments = {}

/* ------------- Slice & Reducers ------------- */

const SLICE_NAME = '@@identity-documents'

const slice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        completeAgeCheck: (state, action: PayloadAction<CompleteAgeCheckPayload>): IdentityDocuments => ({
            ...state,
            [action.payload.storeId]: {
                ...state[action.payload.storeId],
                ageStatus: action.payload.ageStatus
            }
        }),
        completeBanCheck: (state, action: PayloadAction<CompleteBanCheckPayload>): IdentityDocuments => ({
            ...state,
            [action.payload.storeId]: {
                ...state[action.payload.storeId],
                isBanned: action.payload.isBanned
            }
        }),
        completeExpirationCheck: (state, action: PayloadAction<CompleteExpirationCheckPayload>): IdentityDocuments => ({
            ...state,
            [action.payload.storeId]: {
                ...state[action.payload.storeId],
                expirationStatus: action.payload.expirationStatus
            }
        }),
        completeOcr: (state, action: PayloadAction<CompleteOcrPayload>): IdentityDocuments => ({
            ...state,
            [action.payload.storeId]: {
                ...state[action.payload.storeId],
                ocrProperties: action.payload.ocrProperties
            }
        }),
        resetIdentityDocument: (state, { payload: storeId }: PayloadAction<string>): IdentityDocuments =>
            omit([storeId], state)
    },
    extraReducers: (builder) => {
        builder
            .addCase(registrationActions.reset, (): IdentityDocuments => INITIAL_STATE)
            .addCase(documentsActions.setOnfidoDocument, (): IdentityDocuments => INITIAL_STATE)
            .addCase(
                documentsActions.uploadSuccess,
                (state, action): IdentityDocuments => ({
                    ...state,
                    [action.payload.storeId]: {}
                })
            )
    }
})

export default slice.reducer

/* ------------- Actions ------------- */

export const actions = {
    ...slice.actions
}

/* ------------- Selectors ------------- */

const getIdentityDocuments = (state: RootState) => state.identityDocuments

const createGetIdentityDocument = (storeId: string) =>
    createSelector([getIdentityDocuments], (documents) => documents[storeId])

const createGetOcrProperties = (storeId: string) =>
    createSelector([createGetIdentityDocument(storeId)], (i) => i.ocrProperties)

export const selectors = {
    createGetIdentityDocument,
    createGetOcrProperties
}

/* ------------- Utils ------------- */

export const isAgeCheckProcessing = complement(has('ageStatus'))
export const isBanCheckProcessing = complement(has('isBanned'))
export const isExpirationCheckProcessing = complement(has('expirationStatus'))
export const isOcrProcessing = complement(has('ocrProperties'))
