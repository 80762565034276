import { useLayoutEffect, useState } from 'react'

import { find, propEq } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'

import formsActions from '@nickel/forms/redux/actions'

import { COUNTRY, LANGUAGE } from '../../../fields/names'
import useAllowedCountries from '../../../hooks/useAllowedCountries'
import { getLocaleFromPathName } from '../../../i18n'
import { selectors as countrySelectors } from '../../../redux/form/country'

const useMounted = () => {
    const dispatch = useDispatch()
    const [mounted, setMounted] = useState(false)
    const { allowedCountries } = useAllowedCountries()
    const selectedCountry = useSelector(countrySelectors.getCountry)

    useLayoutEffect(() => {
        if (!allowedCountries.length || mounted) return
        const locale = getLocaleFromPathName()
        const countryOption = find(propEq('value', locale.country))(allowedCountries)
        if (countryOption && countryOption.value !== selectedCountry)
            dispatch(formsActions.changeFieldValue({ fieldPath: COUNTRY, fieldValue: countryOption.value }))

        dispatch(
            formsActions.changeFieldValue({
                fieldPath: LANGUAGE,
                fieldValue: locale.language
            })
        )

        setMounted(true)
    }, [allowedCountries, dispatch, mounted, selectedCountry])

    return mounted
}

export default useMounted
