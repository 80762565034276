import { createSelector } from '@reduxjs/toolkit'
import { includes } from 'ramda'

import formsSelectors from '@nickel/forms/redux/selectors'
import { UsPersonFieldNames, UsPersonFormName, UsPersonStatus } from '@nickel/kyc/forms/usPerson/constants'
import { isSet } from '@nickel/utils/lib/common'

import { UpdateUsPersonDataPayloadUsPersonStatusEnum } from '../../services/api'

/* ------------- Selectors ------------- */

const getUsPersonStatus = (pathName?: string) =>
    formsSelectors.getFieldValue<UsPersonStatus>(
        `${pathName ? `${pathName}.` : ''}${UsPersonFormName}.${UsPersonFieldNames.STATUS}`
    )

const getUsPersonData = (pathName?: string) =>
    createSelector([getUsPersonStatus(pathName)], (status) => {
        switch (status) {
            case UsPersonStatus.FATCA_NO:
                return { usPersonStatus: UpdateUsPersonDataPayloadUsPersonStatusEnum.FatcaNo }
            case UsPersonStatus.FATCA_YES:
                return { usPersonStatus: UpdateUsPersonDataPayloadUsPersonStatusEnum.FatcaYes }
            case UsPersonStatus.NO:
                return { usPersonStatus: UpdateUsPersonDataPayloadUsPersonStatusEnum.No }
            default:
                return undefined
        }
    })

const isUsPersonValid = (pathName?: string) =>
    createSelector(
        [getUsPersonStatus(pathName)],
        (status) => isSet(status) && includes(status, [UsPersonStatus.FATCA_NO, UsPersonStatus.NO])
    )

export const selectors = {
    getUsPersonData,
    getUsPersonStatus,
    isUsPersonValid
}
