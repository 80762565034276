import {
    AdultRegistrationFormResourceApiFp,
    AdultRegistrationFormResourceV1ApiFp,
    CardResourceApiFp,
    ChildRegistrationFormResourceApiFp,
    ChildRegistrationFormResourceV1ApiFp,
    PaymentResourceApiFp,
    RegistrationDocumentResourceApiFp,
    RegistrationFormResourceApiFp,
    RegistrationFormResourceV1ApiFp,
    ReopeningCustomerResourceApiFp,
    SmsChallengeResourceApiFp,
    UtilsResourceApiFp,
    ValidatorsResourceApiFp
} from './api'
import { Configuration } from './configuration'

const configuration = new Configuration({
    basePath: window.REACT_APP_REGISTRATION_FORM_API
})

export const adultRegistrationFormResourceApi = AdultRegistrationFormResourceApiFp(configuration)
export const adultRegistrationFormResourceV1Api = AdultRegistrationFormResourceV1ApiFp(configuration)
export const childRegistrationFormResourceApi = ChildRegistrationFormResourceApiFp(configuration)
export const childRegistrationFormResourceV1Api = ChildRegistrationFormResourceV1ApiFp(configuration)
export const registrationDocumentResourceApi = RegistrationDocumentResourceApiFp(configuration)
export const registrationFormResourceApi = RegistrationFormResourceApiFp(configuration)
export const reopeningCustomerResourceApi = ReopeningCustomerResourceApiFp(configuration)
export const smsChallengeResourceApi = SmsChallengeResourceApiFp(configuration)
export const utilsResourceApi = UtilsResourceApiFp(configuration)
export const validatorsResourceApi = ValidatorsResourceApiFp(configuration)
export const cardResourceApi = CardResourceApiFp(configuration)
export const registrationFormResourceV1Api = RegistrationFormResourceV1ApiFp(configuration)
export const paymentResourceApi = PaymentResourceApiFp(configuration)
