import React from 'react'

import { pathOr } from 'ramda'

import { SupportedLocale } from '@nickel/i18n/lib/types'
import identityCardBeSrc from '@nickel/kyc/assets/documents/id/belgium/national-identity-card-recto.png'
import passportBeSrc from '@nickel/kyc/assets/documents/id/belgium/passport-cropped.png'
import residencePermitBeSrc from '@nickel/kyc/assets/documents/id/belgium/residence-permit-recto.png'
import identityCardFrSrc from '@nickel/kyc/assets/documents/id/france/national-identity-card-recto.png'
import residencePermitFrSrc from '@nickel/kyc/assets/documents/id/france/residence-permit-recto.png'
import identityCardDeSrc from '@nickel/kyc/assets/documents/id/germany/national-identity-card-recto.png'
import passportDeSrc from '@nickel/kyc/assets/documents/id/germany/passport.png'
import residencePermitDeSrc from '@nickel/kyc/assets/documents/id/germany/residence-permit-recto.png'
import identityCardPtSrc from '@nickel/kyc/assets/documents/id/portugal/national-identity-card-recto.png'
import passportPtSrc from '@nickel/kyc/assets/documents/id/portugal/passport-cropped.png'
import residencePermitPtSrc from '@nickel/kyc/assets/documents/id/portugal/residence-permit-recto.png'
import identityCardEsSrc from '@nickel/kyc/assets/documents/id/spain/national-identity-card-recto.png'
import passportEsSrc from '@nickel/kyc/assets/documents/id/spain/passport-cropped.png'
import residencePermitEsSrc from '@nickel/kyc/assets/documents/id/spain/residence-permit-recto.png'

import familyRegisterEsSrc from '../assets/L-recto-es.png'
import familyRegisterFrSrc from '../assets/L-recto-fr.png'
import passportFrSrc from '../assets/passport-cropped.png'
import scanStep1 from '../assets/scanner/scan-1.svg'
import scanStep2 from '../assets/scanner/scan-2.svg'
import scanStep3 from '../assets/scanner/scan-3.svg'

import Styled from './styles'
import { ModalTemplateType, TemplateProps } from './types'
import UserIdleTemplate, { FinalScreenUserIdleTemplate } from './UserIdle'

export const modalTemplateMap = new Map()

modalTemplateMap.set(ModalTemplateType.ID_NUMBER_POSITION, ({ t, l }: TemplateProps) => {
    const identityCardSrc = l({
        BE: identityCardBeSrc,
        ES: identityCardEsSrc,
        PT: identityCardPtSrc,
        default: identityCardFrSrc
    })

    const passportSrc = l({
        BE: passportBeSrc,
        ES: passportEsSrc,
        PT: passportPtSrc,
        default: passportFrSrc
    })

    const residencePermitSrc = l({
        BE: residencePermitBeSrc,
        ES: residencePermitEsSrc,
        PT: residencePermitPtSrc,
        default: residencePermitFrSrc
    })
    return (
        <Styled.ImageWrapper>
            <Styled.Image>
                <div>
                    <img src={identityCardSrc} alt={t('common:identity-card')} />
                </div>
                <b>{t('common:identity-card')}</b>
            </Styled.Image>
            <Styled.Image>
                <div>
                    <img src={passportSrc} alt={t('common:passport')} />
                </div>
                <b>{t('common:passport')}</b>
            </Styled.Image>
            <Styled.Image>
                <div>
                    <img src={residencePermitSrc} alt={t('common:residence-permit')} />
                </div>
                <b>{t('common:residence-permit')}</b>
            </Styled.Image>
        </Styled.ImageWrapper>
    )
})

modalTemplateMap.set(ModalTemplateType.IDENTITY, ({ t, l }: TemplateProps) => {
    const identityCardSrc = l({
        BE: identityCardBeSrc,
        ES: identityCardEsSrc,
        PT: identityCardPtSrc,
        DE: identityCardDeSrc,
        default: identityCardFrSrc
    })

    const passportSrc = l({
        BE: passportBeSrc,
        ES: passportEsSrc,
        PT: passportPtSrc,
        DE: passportDeSrc,
        default: passportFrSrc
    })

    const residencePermitSrc = l({
        BE: residencePermitBeSrc,
        ES: residencePermitEsSrc,
        PT: residencePermitPtSrc,
        DE: residencePermitDeSrc,
        default: residencePermitFrSrc
    })
    return (
        <Styled.IdentityContent>
            <div>
                <img src={identityCardSrc} alt={t('common:identity-card')} />
                <b>{t('common:identity-card')}</b>
                <p>{t('all-elements:identity-modal.ue-and-aele')}</p>
                <p className="aele-countries">{t('all-elements:identity-modal.aele-countries')}</p>
            </div>
            <div>
                <img src={residencePermitSrc} alt={t('common:residence-permit')} />
                <b>{t('common:residence-permit')}</b>
            </div>
            <div>
                <img src={passportSrc} alt={t('common:passport')} />
                <b>{t('common:passport')}</b>
                <p>{t('all-elements:identity-modal.passport-info')}</p>
            </div>
        </Styled.IdentityContent>
    )
})

modalTemplateMap.set(ModalTemplateType.FAMILY_REGISTER, ({ t, l }: TemplateProps) => {
    const familyRegisterSrc = l({
        ES: familyRegisterEsSrc,
        default: familyRegisterFrSrc
    })
    return (
        <Styled.FamilyRegisterContent>
            <img src={familyRegisterSrc} alt={t('all-elements:family-register-modal.content')} />
            <p>{t('all-elements:family-register-modal.content')}</p>
        </Styled.FamilyRegisterContent>
    )
})

modalTemplateMap.set(ModalTemplateType.FORGOTTEN_DOCUMENT_WARNING, ({ t }: TemplateProps) => {
    return t('identity-document:modals.forgotten-document-warning.content')
})

modalTemplateMap.set(ModalTemplateType.RGPD, ({ isVisible, t, l, localeLanguage }: TemplateProps) => {
    const BELGIUM_PERSONAL_DATA_PDF_FILE_DOCUMENTS = {
        [SupportedLocale.EN_US]: window.REACT_APP_PERSONAL_DATA_PDF_FILE_URL_EN_BE,
        [SupportedLocale.NL_BE]: window.REACT_APP_PERSONAL_DATA_PDF_FILE_URL_NL_BE
    }
    return (
        <Styled.RgdpContent>
            <Styled.RgdpFrame
                isVisible={isVisible}
                src={`${l({
                    BE: pathOr(
                        window.REACT_APP_PERSONAL_DATA_PDF_FILE_URL_FR_BE,
                        [localeLanguage as string],
                        BELGIUM_PERSONAL_DATA_PDF_FILE_DOCUMENTS
                    ),
                    ES: window.REACT_APP_PERSONAL_DATA_PDF_FILE_URL_ES_ES,
                    PT: window.REACT_APP_PERSONAL_DATA_PDF_FILE_URL_PT_PT,
                    DE: window.REACT_APP_PERSONAL_DATA_PDF_FILE_URL_DE_DE,
                    default: window.REACT_APP_PERSONAL_DATA_PDF_FILE_URL_FR_FR
                })}#toolbar=0`}
                title={t('form:mail.rgpd_title')}
            />
        </Styled.RgdpContent>
    )
})

modalTemplateMap.set(ModalTemplateType.SCAN, ({ t }: TemplateProps) => {
    return (
        <Styled.ScannerContent>
            <div>
                <img src={scanStep1} alt={t('identity-document:scan.step1')} />
                <span>
                    <b>{t('identity-document:scan.step1')}</b>
                </span>
            </div>
            <div>
                <img src={scanStep2} alt={t('identity-document:scan.step2')} />
                <span>
                    <b>{t('identity-document:scan.step2')}</b>
                </span>
            </div>
            <div>
                <img src={scanStep3} alt={t('identity-document:scan.step3')} />
                <span>
                    <b>{t('identity-document:scan.step3')}</b>
                </span>
            </div>
        </Styled.ScannerContent>
    )
})

modalTemplateMap.set(ModalTemplateType.FINAL_SCREEN_USER_IDLE, FinalScreenUserIdleTemplate)
modalTemplateMap.set(ModalTemplateType.USER_IDLE, UserIdleTemplate)
