import { Selector } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { createStimulus } from '@nickel/stimulus/stimulus'

import { selectors as apiSelectors } from '..'
import { StimulusType } from '../../../config/errors'
import { Role } from '../../../constants/roles'
import { Step } from '../../../constants/steps'
import { selectors as barcodeSelectors } from '../../../screens/Barcode/slice'
import { selectors as bankCardInfoSelector } from '../../bankCardInfo'
import { selectors as biometricConsentSelectors } from '../../biometricConsent'
import { selectors as accountTypeSelectors } from '../../form/accountType'
import { selectors as accountUseSelectors } from '../../form/accountUse'
import { selectors as addressSelectors } from '../../form/address'
import { selectors as birthSelectors } from '../../form/birth'
import { selectors as civilSelectors } from '../../form/civil'
import { selectors as documentsSelectors } from '../../form/documents'
import { selectors as familyStatusSelectors } from '../../form/familyStatus'
import { selectors as financialSelectors } from '../../form/financial'
import { selectors as fiscalAddressSelectors } from '../../form/fiscalAddress'
import { selectors as employmentSelectors } from '../../form/profession'
import { selectors as propertyStatusSelectors } from '../../form/propertyStatus'
import { selectors as referralCodeSelectors } from '../../form/referralCode'
import { selectors as taxResidenceSelectors } from '../../form/taxResidence'
import { selectors as termsAndConditionsSelectors } from '../../form/termsAndConditions'
import { selectors as usPersonSelectors } from '../../form/usPerson'
import { selectors as livenessSelectors } from '../../liveness'
import { selectors as mailSelectors } from '../../mail'
import { TrackEvent } from '../../tracking'

export interface ApiRequest {
    api: Selector
    data?: Selector | Record<string, Selector>
    options?: {
        handleError?: (error: AxiosError) => unknown
    }
}

export interface TaskRequest {
    trackEvents?: TrackEvent[]
    apis?: ApiRequest[]
}

const BIRTH_DATE_FORMAT = 'YYY-MM-dd'

const requestMapper: { [key in Step]?: TaskRequest } = {
    [Step.ACCOUNT_TYPE]: {
        trackEvents: [TrackEvent.ACCOUNT_TYPE],
        apis: [
            {
                api: apiSelectors.getAccountTypeApi,
                data: { accountType: accountTypeSelectors.getAccountType() }
            }
        ]
    },
    [Step.ACCOUNT_USE]: {
        trackEvents: [TrackEvent.ACCOUNT_USE],
        apis: [
            {
                api: apiSelectors.getAccountUseApi,
                data: { accountUses: accountUseSelectors.getAccountUse() }
            }
        ]
    },
    [Step.ADDRESS]: {
        apis: [
            {
                api: apiSelectors.getAdultAddressApi,
                data: addressSelectors.getAddressData(Role.ADULT)
            }
        ]
    },
    [Step.FISCAL_ADDRESS]: {
        apis: [
            {
                api: apiSelectors.getAdultFiscalAddressApi,
                data: fiscalAddressSelectors.getFiscalAddressData(Role.ADULT)
            }
        ]
    },
    [Step.ADDRESS_CHILD]: {
        apis: [
            {
                api: apiSelectors.getChildAddressApi,
                data: addressSelectors.getAddressData(Role.CHILD)
            }
        ]
    },
    [Step.ADDRESS_GUARDIAN]: {
        apis: [
            {
                api: apiSelectors.getGuardianAddressApi,
                data: addressSelectors.getAddressData(Role.GUARDIAN)
            }
        ]
    },
    [Step.ALL_ELEMENTS]: {
        apis: [
            {
                api: apiSelectors.getUpdateReferralCodeApi,
                data: {
                    referralCode: referralCodeSelectors.getReferralCode
                },
                options: {
                    handleError: () => {
                        throw createStimulus(StimulusType.REFERRAL_CODE_SERVICE_UNAVAIBLE)
                    }
                }
            }
        ]
    },
    [Step.BARCODE]: {
        apis: [
            {
                api: apiSelectors.getBarcodeApi,
                data: {
                    barcode: barcodeSelectors.getBarcode
                }
            }
        ]
    },
    [Step.BIRTH_DATA_CONFIRMATION_CHILD]: {
        apis: [
            {
                api: apiSelectors.getChildBirthApi,
                data: birthSelectors.getBirthData(Role.CHILD, BIRTH_DATE_FORMAT)
            }
        ]
    },
    [Step.BIRTH_DATA_CONFIRMATION_GUARDIAN]: {
        apis: [
            {
                api: apiSelectors.getGuardianBirthApi,
                data: birthSelectors.getBirthData(Role.GUARDIAN, BIRTH_DATE_FORMAT)
            }
        ]
    },
    [Step.BIRTH_DATA_CONFIRMATION]: {
        apis: [
            {
                api: apiSelectors.getAdultBirthApi,
                data: birthSelectors.getBirthData(Role.ADULT, BIRTH_DATE_FORMAT)
            }
        ]
    },
    [Step.CIVILITY_CONFIRMATION_CHILD]: {
        trackEvents: [TrackEvent.GENDER_CNJ],
        apis: [
            {
                api: apiSelectors.getChildCivilityApi,
                data: civilSelectors.getCivilityData(Role.CHILD)
            }
        ]
    },
    [Step.CIVILITY_CONFIRMATION_GUARDIAN]: {
        trackEvents: [TrackEvent.GENDER_GUARDIAN],
        apis: [
            {
                api: apiSelectors.getGuardianCivilityApi,
                data: civilSelectors.getCivilityData(Role.GUARDIAN)
            }
        ]
    },
    [Step.CIVILITY_CONFIRMATION]: {
        trackEvents: [TrackEvent.GENDER],
        apis: [
            {
                api: apiSelectors.getAdultCivilityApi,
                data: civilSelectors.getCivilityData(Role.ADULT)
            }
        ]
    },
    [Step.DOCUMENT_CHILD]: {
        apis: [
            {
                api: apiSelectors.getChildDocumentApi,
                data: documentsSelectors.getChildIdentityDocumentData
            }
        ]
    },
    [Step.DOCUMENT]: {
        apis: [
            {
                api: apiSelectors.getAdultDocumentApi,
                data: documentsSelectors.getAdultIdentityDocumentData
            }
        ]
    },
    [Step.DOCUMENT_GUARDIAN]: {
        apis: [
            {
                api: apiSelectors.getGuardianDocumentApi,
                data: documentsSelectors.getGuardianIdentityDocumentData
            }
        ]
    },
    [Step.EMPLOYMENT]: {
        apis: [
            {
                api: apiSelectors.getUpdateProfessionApi,
                data: employmentSelectors.getProfessionPayload
            }
        ]
    },
    [Step.MANDATORY_EMAIL]: {
        trackEvents: [TrackEvent.MANDATORY_EMAIL],
        apis: [
            {
                api: apiSelectors.getUpdateEmailAddressApi,
                data: {
                    emailAddress: mailSelectors.getEmail
                }
            }
        ]
    },
    [Step.FAMILY_REGISTER]: {
        apis: [
            {
                api: apiSelectors.getGuardianDocumentApi,
                data: documentsSelectors.getFamilyRegisterData
            }
        ]
    },
    [Step.FAMILY_STATUS]: {
        apis: [
            {
                api: apiSelectors.getFamilyStatusApi,
                data: { familyStatus: familyStatusSelectors.getFamilyStatus() }
            }
        ]
    },
    [Step.FINANCIAL]: {
        apis: [
            {
                api: apiSelectors.getFinancialApi,
                data: financialSelectors.getFinancialData()
            }
        ]
    },
    [Step.LIVENESS]: {
        apis: [
            {
                api: apiSelectors.getUpdateConsentApi,
                data: biometricConsentSelectors.hasBiometricConsentGranted(Role.ADULT)
            },
            {
                api: apiSelectors.getAddLivenessDocumentApi,
                data: {
                    livePhotoId: livenessSelectors.getId(Role.ADULT),
                    facialCaptureVariant: livenessSelectors.getVariant(Role.ADULT)
                }
            }
        ]
    },
    [Step.LIVENESS_GUARDIAN]: {
        apis: [
            {
                api: apiSelectors.getUpdateConsentApi,
                data: biometricConsentSelectors.hasBiometricConsentGranted(Role.GUARDIAN)
            },
            {
                api: apiSelectors.getAddLivenessDocumentApi,
                data: {
                    livePhotoId: livenessSelectors.getId(Role.GUARDIAN),
                    facialCaptureVariant: livenessSelectors.getVariant(Role.GUARDIAN)
                }
            }
        ]
    },
    [Step.LIVENESS_VIDEO]: {
        apis: [
            {
                api: apiSelectors.getUpdateConsentApi,
                data: biometricConsentSelectors.hasBiometricConsentGranted(Role.ADULT)
            }
        ]
    },
    [Step.MAIL]: {
        trackEvents: [TrackEvent.MAIL],
        apis: [
            {
                api: apiSelectors.getUpdateEmailAddressApi,
                data: {
                    emailAddress: mailSelectors.getEmail
                }
            }
        ]
    },
    [Step.PROPERTY_STATUS]: {
        apis: [
            {
                api: apiSelectors.getPropertyStatusApi,
                data: { propertyStatus: propertyStatusSelectors.getPropertyStatus() }
            }
        ]
    },
    [Step.US_PERSON]: {
        apis: [
            {
                api: apiSelectors.getUsPersonApi,
                data: usPersonSelectors.getUsPersonData()
            }
        ]
    },
    [Step.TAX_RESIDENCE]: {
        apis: [
            {
                api: apiSelectors.getTaxResidenceApi,
                data: taxResidenceSelectors.getTaxResidencesInfos()
            }
        ]
    },
    [Step.TERMS_AND_CONDITIONS]: {
        apis: [
            {
                api: apiSelectors.getTermsAndConditionsApi,
                data: {
                    customerDataUseNickelAuthorized: termsAndConditionsSelectors.getUseOfDataNickel,
                    customerDataUsePartnerAuthorized: termsAndConditionsSelectors.getUseOfDataPartners,
                    customerAcknowledgedAndDownloadedDocuments:
                        termsAndConditionsSelectors.getAcknowledgedDownloadedDocuments
                }
            }
        ]
    },
    [Step.HOSTED]: {
        apis: [
            {
                api: apiSelectors.getHostApi,
                data: propertyStatusSelectors.getHostData()
            }
        ]
    },
    [Step.BANK_CARD_TYPE]: {
        trackEvents: [TrackEvent.CARD_RECEPTION_MODE, TrackEvent.CARD_TYPE],
        apis: [
            {
                api: apiSelectors.getCardReceptionModeApi,
                data: { cardReceptionMode: bankCardInfoSelector.getCardReceptionMode }
            },
            {
                api: apiSelectors.getBankCardTypeApi,
                data: { cardType: bankCardInfoSelector.getCardType }
            }
        ]
    },
    [Step.CARD_RECEPTION_MODE]: {
        trackEvents: [TrackEvent.CARD_RECEPTION_MODE],
        apis: []
    },
    [Step.BANK_CARD_CUSTOMIZATION]: {
        trackEvents: [TrackEvent.CARD_CUSTOMIZATION],
        apis: [
            {
                api: apiSelectors.getCardPersonalizationApi,
                data: bankCardInfoSelector.getCardCustomizationPayload
            }
        ]
    },
    [Step.PRIVATE_LIFE_CERTIFICATION]: {
        apis: [
            {
                api: apiSelectors.getUpdatePrivacyCertificationDateApi
            }
        ]
    },
    [Step.TERMS_AND_CONDITIONS_EID]: {
        apis: [
            {
                api: apiSelectors.validateEidTermsAndConditions
            }
        ]
    },
    [Step.PRIVACY_POLICY_EID]: {
        apis: [
            {
                api: apiSelectors.validateEidPrivacyPolicy
            }
        ]
    }
}

export default requestMapper
