import { useMemo } from 'react'

import { useQuery } from 'react-query'

import { getCountryByISO2, optionifyWithTranslatedLabel } from '@nickel/l10n/utils'
import { Options } from '@nickel/ui/types'

import { GetAllowedCountriesCountriesEnum } from '../services'
import { utilsResourceApi } from '../services/utils'
import { isDefined } from '../utils'

const useAllowedCountries = () => {
    const query = async () => (await utilsResourceApi.getAllowedCountries())()

    const { data, isError, isLoading } = useQuery('getAllowedCountries', query, {
        select: (response) => new Set(response.data.countries)
    })

    const allowedCountries = useMemo<Options<GetAllowedCountriesCountriesEnum>>(() => {
        if (!data) return []
        const coutriesCodes = Array.from(data)
            .map(getCountryByISO2)
            .filter(isDefined)
            .map(optionifyWithTranslatedLabel)
            .map(({ value, label }) => ({ value: value as GetAllowedCountriesCountriesEnum, label }))

        return coutriesCodes
    }, [data])

    return {
        allowedCountries,
        isError,
        isLoading
    }
}

export default useAllowedCountries
