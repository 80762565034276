import { Selector } from '@reduxjs/toolkit'

import { RootState } from '..'
import { Step } from '../../constants/steps'

import validators from './validators'

const barcode = validators[Step.REGISTRATION_TYPE]
const smsChallenge = validators.phone

const enablers: { [key in Step]?: Selector<RootState, boolean> } = {
    [Step.BARCODE]: barcode,
    [Step.SMS_CHALLENGE]: smsChallenge
}

export default enablers
