import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '..'
import { ReopeningMatchType } from '../../config/types'
import { ReopenableCustomerView } from '../../services'
import { actions as registrationActions } from '../registration'

/* ------------- Type & State ------------- */

export type InitializeReopeningPayload = {
    token: string
}

export type FailureReason =
    | 'error.customerNotFound'
    | 'error.customerNotReopenable'
    | 'error.customerNotAutomaticallyReopenable'

export type ReopeningState = {
    matchFailure?: FailureReason
    matchNoResult?: boolean
    matchOneResult?: ReopenableCustomerView
    matchMultyResults?: ReopenableCustomerMulty
}

export type ReopenableCustomerType =
    | {
          type: 'zero'
          info: FailureReason
      }
    | {
          type: 'one'
          info: ReopenableCustomerView
      }
    | {
          type: 'multy'
          info: ReopenableCustomerMulty
      }

export type ReopenableCustomerMulty = {
    documents: ReopenableCustomerMultyDocument[]
}

export type ReopenableCustomerMultyDocument = {
    documentType: 'PASSPORT' | 'NATIONAL_IDENTITY_CARD' | 'RESIDENCE_PERMIT' | 'INVOICE'
    documentNumber: string
}

export const INITIAL_STATE: ReopeningState = {}

/* ------------- Slice & Reducers ------------- */

const SLICE_NAME = '@@reopening'

const slice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        matchFailure: (state): ReopeningState => ({
            ...state,
            matchFailure: 'error.customerNotFound'
        }),
        searchCardSuccess: (state, { payload }: PayloadAction<ReopenableCustomerType>): ReopeningState => {
            return {
                ...state,
                matchFailure: payload.type === 'zero' ? payload.info : undefined,
                matchOneResult: payload.type === 'one' ? payload.info : undefined
            }
        },
        searchInfoSuccess: (state, { payload }: PayloadAction<ReopenableCustomerType>): ReopeningState => {
            return {
                ...state,
                matchFailure:
                    payload.type === 'zero' && payload.info !== 'error.customerNotFound' ? payload.info : undefined,
                matchNoResult: payload.type === 'zero' && payload.info === 'error.customerNotFound',
                matchOneResult: payload.type === 'one' ? payload.info : undefined,
                matchMultyResults: payload.type === 'multy' ? payload.info : undefined
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(registrationActions.reset, (): ReopeningState => INITIAL_STATE)
    }
})

export default slice.reducer

/* ------------- Actions ------------- */

export const actions = {
    ...slice.actions,
    initialize: createAction<InitializeReopeningPayload>(`${SLICE_NAME}/INITIALIZE`)
}

/* ------------- Selectors ------------- */

const getReopeningState = (state: RootState) => state.reopening
const getMatchFailure = createSelector([getReopeningState], (state) => state.matchFailure)
const getMatchMultyResults = createSelector([getReopeningState], (state) => state.matchMultyResults)
const getMatchNoResult = createSelector([getReopeningState], (state) => state.matchNoResult)
const getMatchOneResult = createSelector([getReopeningState], (state) => state.matchOneResult)

const getMatchTypes = createSelector(
    [getMatchFailure, getMatchNoResult, getMatchOneResult, getMatchMultyResults],
    (failure, noResult, result, results) => {
        const arr: ReopeningMatchType[] = []
        if (failure) arr.push('failure')
        if (noResult) arr.push('zero')
        if (result) arr.push('one')
        if (results) arr.push('multy')
        return arr
    }
)

export const selectors = {
    getMatchFailure,
    getMatchMultyResults,
    getMatchOneResult,
    getMatchTypes
}
