import React, { createContext, useState, useMemo, useContext } from 'react'

import { head } from 'ramda'
import { useSelector } from 'react-redux'

import { Check } from '@nickel/core-ui/components/Icons'
import Stepper from '@nickel/core-ui/components/Stepper'
import i18n from '@nickel/i18n'

import { Step } from '../../constants/steps'
import useNavigation from '../../hooks/useNavigation'
import useOrientation from '../../hooks/useOrientation'
import { NavigationStep, selectors as navigationSelectors } from '../../redux/navigation'

import Styles from './styles'
import { getParentRoute } from './utils'

type ProgressIndicatorContextType = {
    withProgressIndicator: boolean
    setWithProgressIndicator: (vaule: boolean) => void
}

const ProgressIndicatorContext = createContext<ProgressIndicatorContextType | null>(null)

interface ProgressIndicatorProviderProps {
    children: React.ReactNode
}

export const ProgressIndicatorProvider = ({ children }: ProgressIndicatorProviderProps) => {
    const [withProgressIndicator, setWithProgressIndicator] = useState(true)
    const value = useMemo(() => ({ withProgressIndicator, setWithProgressIndicator }), [withProgressIndicator])
    return <ProgressIndicatorContext.Provider value={value}>{children}</ProgressIndicatorContext.Provider>
}

export const useProgressIndicator = () => {
    const progressIndicatorContext = useContext(ProgressIndicatorContext) as ProgressIndicatorContextType
    const isVisible = useSelector(navigationSelectors.getIsProgressIndicatorVisible)
    return {
        withProgressIndicator: progressIndicatorContext?.withProgressIndicator && isVisible,
        setWithProgressIndicator: progressIndicatorContext?.setWithProgressIndicator
    }
}

const getRouteOfFirstChild = (steps: NavigationStep[], name: string) =>
    head(
        steps.filter((step) => step.name === name).map((step) => (step.substeps ? step.substeps[0].route : step.route))
    )

const ProgressIndicator = () => {
    const steps = useSelector(navigationSelectors.getSteps)
    const currentRoute = useSelector(navigationSelectors.getCurrentRoute)
    const orientation = useOrientation()
    const { withProgressIndicator } = useProgressIndicator()
    const { goToStep } = useNavigation()

    const currentSteps = steps.filter((step) => !step.hiddenFromStepper)
    const formatedSteps = currentSteps.map(({ completion, isEnabled, name }, index) => ({
        completion: isEnabled ? completion * 100 : 0,
        disabled: !isEnabled,
        id: index,
        label: i18n.t(`steps:${name}`)
    }))
    const activeStep = currentSteps.findIndex(({ route }) => getParentRoute(route) === getParentRoute(currentRoute))
    const stepsNames = currentSteps.map(({ name }) => name)

    if (!withProgressIndicator) return null

    return (
        <Styles.Container visible={withProgressIndicator} orientation={orientation}>
            <Stepper
                activeStep={activeStep}
                onClick={({ id }) => {
                    goToStep(getRouteOfFirstChild(steps, stepsNames[id]) ?? Step.COUNTRY)
                }}
                orientation="vertical"
                steps={formatedSteps}
                testId="ProgressIndicator"
                icon={<Check />}
            />
        </Styles.Container>
    )
}

export default ProgressIndicator
