import { createSelector } from '@reduxjs/toolkit'

import formsSelectors from '@nickel/forms/redux/selectors'
import { BirthFieldNames, BirthFormName } from '@nickel/kyc/forms/birth/constants'
import { areSet, areTrue } from '@nickel/utils/lib/common'

import { Role } from '../../constants/roles'

import { formatDateToString } from './utils'

const EMPTY_VAL = ''

const getBirthCountry = (roleKey: Role) =>
    formsSelectors.getFieldValue<string>(`${roleKey}.${BirthFormName}.${BirthFieldNames.COUNTRY}`)

const getBirthDate = (roleKey: Role) =>
    formsSelectors.getFieldValue<string>(`${roleKey}.${BirthFormName}.${BirthFieldNames.DATE}`)

const getBirthNationality = (roleKey: Role) =>
    formsSelectors.getFieldValue<string>(`${roleKey}.${BirthFormName}.${BirthFieldNames.NATIONALITY}`)

const getBirthPlace = (roleKey: Role) =>
    formsSelectors.getFieldValue<string>(`${roleKey}.${BirthFormName}.${BirthFieldNames.PLACE}`)

const getBirthRegion = (roleKey: Role) =>
    formsSelectors.getFieldValue<string>(`${roleKey}.${BirthFormName}.${BirthFieldNames.REGION}`)
const getOtherNationalities = (roleKey: Role) =>
    formsSelectors.getFieldValue<string[]>(`${roleKey}.${BirthFormName}.${BirthFieldNames.OTHER_NATIONALITIES}`)

const getBirthData = (roleKey: Role, birthDateFormat?: string) =>
    createSelector(
        [
            getBirthCountry(roleKey),
            getBirthDate(roleKey),
            getBirthNationality(roleKey),
            getBirthPlace(roleKey),
            getBirthRegion(roleKey),
            getOtherNationalities(roleKey)
        ],
        (birthCountryCode, birthDate, nationalityCode, birthPlace, birthRegion, otherNationalities) => {
            const filteredNationalities = !otherNationalities
                ? [nationalityCode]
                : [nationalityCode, ...otherNationalities.filter((n) => n !== EMPTY_VAL)]

            return {
                birthCountryCode,
                birthDate: birthDateFormat ? formatDateToString(birthDate, birthDateFormat) : birthDate,
                birthPlace,
                birthRegion,
                nationalitiesCode: filteredNationalities
            }
        }
    )

const isBirthValid = (roleKey: Role) =>
    createSelector(
        [
            getBirthCountry(roleKey),
            getBirthDate(roleKey),
            getBirthNationality(roleKey),
            getBirthPlace(roleKey),
            formsSelectors.getFieldIsValid(`${roleKey}.${BirthFormName}.${BirthFieldNames.COUNTRY}`),
            formsSelectors.getFieldIsValid(`${roleKey}.${BirthFormName}.${BirthFieldNames.DATE}`),
            formsSelectors.getFieldIsValid(`${roleKey}.${BirthFormName}.${BirthFieldNames.NATIONALITY}`),
            formsSelectors.getFieldIsValid(`${roleKey}.${BirthFormName}.${BirthFieldNames.PLACE}`)
        ],
        (country, date, nationality, place, isCountryValid, isDateValid, isNationalityValid, isPlaceValid) =>
            areSet(country, date, nationality, place) &&
            areTrue(isCountryValid, isDateValid, isNationalityValid, isPlaceValid)
    )

export const selectors = {
    getBirthData,
    isBirthValid
}
