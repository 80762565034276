import { createSelector } from '@reduxjs/toolkit'

import formsSelectors from '@nickel/forms/redux/selectors'
import { FinancialFieldNames, FinancialFormName } from '@nickel/kyc/forms/financial/constants'
import { areSet } from '@nickel/utils/lib/common'

import {
    UpdateFinancialDataPayloadAssetValueRangeEnum,
    UpdateFinancialDataPayloadEarningsRangeEnum
} from '../../services'

/* ------------- Selectors ------------- */

const getEarningsRange = (pathName?: string) =>
    formsSelectors.getFieldValue<UpdateFinancialDataPayloadEarningsRangeEnum>(
        `${pathName ? `${pathName}.` : ''}${FinancialFormName}.${FinancialFieldNames.EARNINGS_RANGE}`
    )

const getAssetValueRange = (pathName?: string) =>
    formsSelectors.getFieldValue<UpdateFinancialDataPayloadAssetValueRangeEnum>(
        `${pathName ? `${pathName}.` : ''}${FinancialFormName}.${FinancialFieldNames.ASSET_VALUE_RANGE}`
    )

const getFinancialData = (pathName?: string) =>
    createSelector([getEarningsRange(pathName), getAssetValueRange(pathName)], (earningsRange, assetValueRange) => ({
        earningsRange,
        assetValueRange
    }))

const isFinancialValid = (pathName?: string) =>
    createSelector([getEarningsRange(pathName), getAssetValueRange(pathName)], (earningsRange, assetValueRange) =>
        areSet(earningsRange, assetValueRange)
    )

export const selectors = {
    getAssetValueRange,
    getEarningsRange,
    getFinancialData,
    isFinancialValid
}
