import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { equals } from 'ramda'

import { RootState } from '..'
import { actions as documentActions } from '../documents'
import { actions as registrationActions } from '../registration'

/* ------------- Type & State ------------- */

type SetCreationCodeType = { code: string | undefined }

type FinalizationState = {
    notProcessedDocumentErrorCount: number
    customerCreationCode: string | undefined
    status: 'FAILED' | 'IDLE' | 'PENDING' | 'SUCCEEDED'
}

export const INITIAL_STATE: FinalizationState = {
    notProcessedDocumentErrorCount: 0,
    customerCreationCode: '',
    status: 'IDLE'
}

/* ------------- Slice & Reducers ------------- */

const SLICE_NAME = '@@finalization'

const slice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        finalize: (state): FinalizationState => ({
            ...state,
            status: state.status === 'SUCCEEDED' ? 'SUCCEEDED' : 'PENDING'
        }),
        finalizeSuccess: (state): FinalizationState => ({
            ...state,
            status: 'SUCCEEDED'
        }),
        finalizeFailure: (state): FinalizationState => ({
            ...state,
            status: 'FAILED'
        }),
        setCustomerCreationCode: (state, action: PayloadAction<SetCreationCodeType>): FinalizationState => ({
            ...state,
            customerCreationCode: action.payload.code
        })
    },
    extraReducers: (builder) => {
        builder
            .addCase(registrationActions.reset, (): FinalizationState => INITIAL_STATE)
            .addCase(documentActions.uploadSuccess, (): FinalizationState => INITIAL_STATE)
    }
})

export default slice.reducer

/* ------------- Actions ------------- */

export const actions = {
    ...slice.actions
}

/* ------------- Selectors ------------- */

const getFinalization = (state: RootState) => state.finalization
const getStatus = createSelector([getFinalization], (state) => state.status)
const getCustomerCreationCode = createSelector([getFinalization], (state) => state.customerCreationCode)
const getIsSucceeded = createSelector([getStatus], equals('SUCCEEDED'))

export const selectors = {
    getIsSucceeded,
    getStatus,
    getCustomerCreationCode
}

/* ------------- Utils ------------- */
