import { format, parse } from 'date-fns'
import { last } from 'ramda'

import { getCurrentDateLocale } from '@nickel/i18n/lib/date'
import { SupportedLocale } from '@nickel/i18n/lib/types'
import { getGlobalISO2 } from '@nickel/l10n/config'

import { BELGIUM_LANGUAGES } from '../../fields/constants'
import { getAllowedCountryISO2 } from '../../l10n/utils'
import { GetAllowedCountriesCountriesEnum } from '../../services'

export const formatDateToString = (date: Date | string | undefined, formatStr = 'dd/MM/yyyy'): string => {
    if (!date) return ''
    try {
        const parsedDate =
            date instanceof Date ? date : parse(date, 'YYYY-MM-DD', new Date(), { locale: getCurrentDateLocale() })
        return format(parsedDate, formatStr, { locale: getCurrentDateLocale() })
    } catch (err) {
        return date.toString()
    }
}

export const getNavigatorCountry = (isBelgium = false): GetAllowedCountriesCountriesEnum => {
    const { languages, language } = window.navigator
    const lng = languages && languages.length ? languages[0] : language
    let country: string = lng ? last(lng.split('-')) ?? '' : ''
    if (isBelgium && country === 'en') country = 'us'
    return getAllowedCountryISO2(country ? country.toUpperCase() : getGlobalISO2())
}

const getBelgiumLocale = () => {
    switch (getNavigatorCountry(true)) {
        case GetAllowedCountriesCountriesEnum.Fr:
            return SupportedLocale.FR_BE
        case GetAllowedCountriesCountriesEnum.Nl:
            return SupportedLocale.NL_BE
        default:
            return SupportedLocale.EN_US
    }
}

export const getLanguageFromCountry = (country: GetAllowedCountriesCountriesEnum, language?: SupportedLocale) => {
    switch (country) {
        case GetAllowedCountriesCountriesEnum.Be:
            return BELGIUM_LANGUAGES.includes(language as SupportedLocale)
                ? (language as SupportedLocale)
                : getBelgiumLocale()
        case GetAllowedCountriesCountriesEnum.Es:
            return SupportedLocale.ES_ES
        case GetAllowedCountriesCountriesEnum.Pt:
            return SupportedLocale.PT_PT
        case GetAllowedCountriesCountriesEnum.De:
            return SupportedLocale.DE_DE
        default:
            return SupportedLocale.FR_FR
    }
}
