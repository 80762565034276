import { PayloadAction } from '@reduxjs/toolkit'
import { push } from 'connected-react-router'
import { call, put, select } from 'redux-saga/effects'

import { isTerminal } from '@nickel/utils/lib/web'

import { DocumentRoute, IDENTITY_DOCUMENT_ROUTE_MAPPER } from '../../constants/roles'
import { Step } from '../../constants/steps'
import { goToExternal as goTo } from '../../hooks/useNavigation'
import { CardReceptionModeEnum } from '../../screens/CardReceptionMode/types'
import { rootRoute } from '../../utils'
import { actions as documentsActions } from '../documents'
import { actions as identityDocumentsActions } from '../identityDocuments'
import { actions as livenessActions } from '../liveness'
import { actions as registrationActions } from '../registration'

import { selectors as navigationSelectors, GoToIdentityDocumentsStepPayload } from '.'

/**
 * @deprecated
 */
export function* goToStep({ payload: route }: PayloadAction<string>) {
    yield put(push(route))
}

/**
 * @deprecated
 */
export function* goToExternal({ payload: url }: PayloadAction<string>) {
    yield call(goTo, url)
}

/**
 * @deprecated
 */
export function* goToNextStep() {
    const currentStepName: Step = yield select(navigationSelectors.getCurrentStepName)
    yield put(registrationActions.send(currentStepName))

    const { route } = yield select(navigationSelectors.getNextStep)
    yield call(goToStep, { payload: route, type: '' })
}

/**
 * @deprecated
 */
export function* goToFirstStepWithResetDocument({ payload: storeId }: PayloadAction<string>) {
    yield put(documentsActions.resetDocument(storeId))
    yield put(identityDocumentsActions.resetIdentityDocument(storeId))
    yield call(goToFirstStep)
}

/**
 * @deprecated
 */
export function* goToFirstStep() {
    if (isTerminal) yield call(goTo, window.REACT_APP_HOME_BORNE_LOCATION)
    yield call(goToStep, { type: '', payload: rootRoute })
}

/**
 * @deprecated
 */
export function* goToIdentityDocumentsStep({
    payload: { role, storeId, cardReceptionMode = CardReceptionModeEnum.CLASSIC }
}: PayloadAction<GoToIdentityDocumentsStepPayload>) {
    if (storeId) {
        yield put(documentsActions.resetDocument(storeId))
        yield put(identityDocumentsActions.resetIdentityDocument(storeId))
    }
    if (!isTerminal) yield put(livenessActions.reset())
    const livenessRoute = `${role}_${cardReceptionMode}` as DocumentRoute
    yield call(goToStep, { type: '', payload: IDENTITY_DOCUMENT_ROUTE_MAPPER[livenessRoute] })
}
