import React, { StrictMode, useState } from 'react'

import { PayloadAction } from '@reduxjs/toolkit'
import { ConnectedRouter } from 'connected-react-router'
import { Trans } from 'react-i18next'
import { pdfjs } from 'react-pdf'
import { QueryClientProvider } from 'react-query'
import { DispatchProp, Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
// @ts-expect-error TODO
import { PersistGate } from 'redux-persist/lib/integration/react'
import { ThemeProvider } from 'styled-components'

import Modal from '@nickel/core-ui/components/Modal'
import ConnectedForm from '@nickel/forms/redux/ConnectedForm'
import GlobalStyle from '@nickel/foundations/components/config/GlobalStyle'
import L10nProvider from '@nickel/l10n/L10nProvider'
import { SessionTokenProvider } from '@nickel/location/sessionTokenContext'
import { actions as uiActions, LoaderState } from '@nickel/stimulus/ui'
import UIContainer from '@nickel/stimulus/ui/Container'
import RadiosContextProvider from '@nickel/ui/components/legacy/form/Radios/ContextProvider'
import { RadiosVariant } from '@nickel/ui/components/legacy/form/Radios/types'
import Loader from '@nickel/ui/components/legacy/Loader'
import nickel from '@nickel/ui/themes/nickel'

import { CaptchaProvider } from './app/components/Captcha/Captcha'
import CustomModal from './app/components/CustomModal'
import CustomModalContextProvider from './app/components/CustomModal/CustomModalContextProvider'
import Header from './app/components/Header'
import ProgressIndicator, { ProgressIndicatorProvider } from './app/components/ProgressIndicator'
import VirtualKeyboard from './app/components/VirtualKeyboard'
import queryClient from './app/config/query/queryClient'
import i18ng from './app/i18n'
import { getGlobalISO2, setSetGlobalISO2Callback } from './app/l10n/config'
import Router from './app/navigation/Router'
import store, { RootState } from './app/redux'
import history from './app/redux/history'
import { modalTemplateMap } from './app/templates'
import { ModalData } from './app/templates/types'
import '@csstools/normalize.css'
import './App.css'

pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js'

const persistor = persistStore(store)

const renderLoader = (loader: LoaderState, i18n: typeof i18ng) => (
    <Loader data-testid="Loader" message={i18n.t(loader.message ?? '')} />
)

const renderModal = (
    modal: { isVisible: boolean; uiData: ModalData },
    dispatch: DispatchProp['dispatch'],
    i18n: typeof i18ng
) => {
    const {
        isVisible,
        uiData: {
            title,
            message,
            messageValues,
            showCloseButton,
            primaryAction,
            secondaryAction,
            template,
            translationComponent
        }
    } = modal
    const onModalAction = (action?: Pick<PayloadAction, 'type'>, shouldClose = true) => () => {
        if (action?.type) dispatch(action)
        if (shouldClose) dispatch(uiActions.hideModal())
    }
    const getMessage = () => {
        if (!message) return ''
        if (Array.isArray(message)) return i18n.t(message[0], message[1]) // TODO add this type in Stimulus ?
        return (
            <Trans i18nKey={message} components={translationComponent} values={messageValues}>
                <b>Text_0</b>
            </Trans>
        )
    }
    const modalContent = () => {
        if (!template) {
            return getMessage()
        }
        const { type, payload } = template
        if (type && !modalTemplateMap.has(type)) return
        if (payload) return modalTemplateMap.get(type)({ t: i18n.t.bind(i18n), payload })
        if (message) return modalTemplateMap.get(type)({ t: i18n.t.bind(i18n), message })
        return modalTemplateMap.get(type)({ dispatch, isVisible, t: i18n.t.bind(i18n) })
    }
    return (
        <Modal
            open={isVisible}
            width="668px"
            showCloseButton={showCloseButton}
            onClose={onModalAction()}
            testId="Modal"
            title={i18n.t(title ?? '')}
            primaryButton={{
                onClick: onModalAction(primaryAction?.action),
                testId: 'ModalPrimaryActionButton',
                text: i18n.t(primaryAction?.text ?? '')
            }}
            secondaryButton={
                secondaryAction && {
                    onClick: onModalAction(secondaryAction.action),
                    testId: 'ModalSecondaryActionButton',
                    text: i18n.t(secondaryAction.text),
                    variant: 'ghost'
                }
            }
        >
            {modalContent()}
        </Modal>
    )
}

const App = () => {
    const [ISO2, setISO2] = useState(getGlobalISO2())

    setSetGlobalISO2Callback(setISO2)

    return (
        <StrictMode>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <QueryClientProvider client={queryClient}>
                        <L10nProvider value={ISO2}>
                            <ThemeProvider theme={nickel}>
                                <GlobalStyle />
                                <ConnectedForm>
                                    <ConnectedRouter history={history}>
                                        <UIContainer
                                            getState={(state: RootState) => state.ui}
                                            renderModal={renderModal}
                                            renderLoader={renderLoader}
                                        >
                                            <CustomModalContextProvider>
                                                <Header />
                                                <div className="App">
                                                    <ProgressIndicatorProvider>
                                                        <CaptchaProvider>
                                                            <ProgressIndicator />
                                                            <SessionTokenProvider>
                                                                <RadiosContextProvider variant={RadiosVariant.BUTTON}>
                                                                    <Router />
                                                                </RadiosContextProvider>
                                                            </SessionTokenProvider>
                                                        </CaptchaProvider>
                                                    </ProgressIndicatorProvider>
                                                    <VirtualKeyboard />
                                                </div>
                                                <CustomModal width="668px" height="auto" />
                                            </CustomModalContextProvider>
                                        </UIContainer>
                                    </ConnectedRouter>
                                </ConnectedForm>
                            </ThemeProvider>
                        </L10nProvider>
                    </QueryClientProvider>
                </PersistGate>
            </Provider>
        </StrictMode>
    )
}

export default App

if (window.Cypress) {
    window.store = store
}
