import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '..'
import { Role } from '../../constants/roles'
import { ConsentEnum } from '../../screens/BiometricConsent/types'
import { actions as registrationActions } from '../registration'

/* ------------- Type & State ------------- */

type CompletePayload = {
    role: Role
    consent?: ConsentEnum
    isFallbackDisplay?: boolean
}

type BiometricConsentState = Partial<
    Record<
        Role,
        {
            consent: ConsentEnum
            isFallbackDisplay: boolean
        }
    >
>

export const INITIAL_STATE: BiometricConsentState = {}

/* ------------- Slice & Reducers ------------- */

const SLICE_NAME = '@@biometric-consent'

const slice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        setConsent: (state, { payload: { role, consent } }: PayloadAction<CompletePayload>): BiometricConsentState => ({
            ...state,
            [role]: { consent }
        }),
        setFallbackScreenDisplay: (
            state,
            { payload: { role, isFallbackDisplay } }: PayloadAction<CompletePayload>
        ): BiometricConsentState => ({
            ...state,
            [role]: { isFallbackDisplay }
        }),
        reset: (): BiometricConsentState => INITIAL_STATE
    },
    extraReducers: (builder) => {
        builder.addCase(registrationActions.reset, (): BiometricConsentState => INITIAL_STATE)
    }
})

export default slice.reducer

/* ------------- Actions ------------- */

export const actions = {
    ...slice.actions
}

/* ------------- Selectors ------------- */

const getConsentData = (role: Role) => (state: RootState) => state.biometricConsent[role]?.consent

const hasBiometricConsentGranted = (role: Role) => (state: RootState) => ({
    consent: state.biometricConsent[role]?.consent === ConsentEnum.GRANTED
})

const isFallbackScreenDisplay = (role: Role) => (state: RootState) =>
    state.biometricConsent[role]?.isFallbackDisplay === true

export const selectors = {
    getConsentData,
    hasBiometricConsentGranted,
    isFallbackScreenDisplay
}
