import React, { useState } from 'react'

import WithKeyboard from '@nickel/onboarding/components/TotemKeyboard'
import { isTerminal } from '@nickel/utils/lib/web'

import useEventListener from '../../hooks/useEventListener'
import { useProgressIndicator } from '../ProgressIndicator'

const STEPPER_WIDTH = 272

const VirtualKeyboard = () => {
    const { withProgressIndicator } = useProgressIndicator()
    const [keyboardShift, setKeyboardShift] = useState((STEPPER_WIDTH / document.body.clientWidth) * 50)
    useEventListener('resize', () => setKeyboardShift((STEPPER_WIDTH / document.body.clientWidth) * 50), [
        keyboardShift
    ])
    return <WithKeyboard noKeyboard={!isTerminal} style={withProgressIndicator && { left: `${50 + keyboardShift}%` }} />
}

export default VirtualKeyboard
