import { all, fork, takeLatest, takeLeading } from 'redux-saga/effects'

import { stimulusInterceptors } from '@nickel/stimulus/stimulus'

import appSagas from './app/sagas'
import formSagas from './form/sagas'
import { actions as navigationActions } from './navigation'
import {
    goToExternal,
    goToFirstStep,
    goToFirstStepWithResetDocument,
    goToIdentityDocumentsStep,
    goToNextStep,
    goToStep
} from './navigation/sagas'
import registrationSagas from './registration/sagas'
import reopeningSagas from './reopening/sagas'
import { actions as scannerActions } from './scanner'
import { scan, scanSetup } from './scanner/sagas'

export default function* root() {
    yield all([
        ...stimulusInterceptors,

        // App
        fork(appSagas),

        // Navigation
        takeLatest(navigationActions.goToExternal, goToExternal),
        takeLatest(navigationActions.goToFirstStep, goToFirstStep),
        takeLatest(navigationActions.goToFirstStepWithResetDocument, goToFirstStepWithResetDocument),
        takeLatest(navigationActions.goToIdentityDocumentsStep, goToIdentityDocumentsStep),
        takeLatest(navigationActions.goToNextStep, goToNextStep),
        takeLatest(navigationActions.goToStep, goToStep),

        // Form values
        fork(formSagas),

        // Registration
        fork(registrationSagas),

        // Reopening
        fork(reopeningSagas),

        // Scanner
        takeLeading(scannerActions.scan, scan),
        takeLeading(scannerActions.scanSetup, scanSetup)
    ])
}
