import { createSelector } from '@reduxjs/toolkit'

import formsSelectors from '@nickel/forms/redux/selectors'
import { ACCOUNT_TYPE } from '@nickel/kyc/fields/names'
import { isSet } from '@nickel/utils/lib/common'

/* ------------- Selectors ------------- */

const getAccountType = (pathName?: string) =>
    formsSelectors.getFieldValue<string>(`${pathName ? `${pathName}.` : ''}${ACCOUNT_TYPE}`)

const isAccountTypeValid = (pathName?: string) => createSelector([getAccountType(pathName)], isSet)

export const selectors = {
    getAccountType,
    isAccountTypeValid
}
