import styled from 'styled-components'

import Heading from '@nickel/core-ui/components/Heading'
import { Breakpoint, Color, FontSize, FontWeight, Spacing } from '@nickel/foundations/tokens'

const H1 = styled.h1<{ fontWeight: FontWeight; uppercase?: boolean; withNoWrap?: boolean }>`
    font-size: ${FontSize.XXXLARGE};
    font-weight: ${({ fontWeight }) => fontWeight};
    margin-bottom: ${Spacing.XLARGE};
    text-align: center;
    ${({ uppercase }) => uppercase && 'text-transform: uppercase;'}

    span {
        color: ${Color.PRIMARY_NICKEL};
        ${({ withNoWrap }) => withNoWrap && 'white-space: nowrap;'}
    }

    @media (min-width: ${Breakpoint.TABLET}) {
        font-size: ${FontSize.XWIDE};
    }

    @media (min-width: ${Breakpoint.TABLET}) {
        margin-bottom: 2.5rem;
    }
`
const HeadingH1 = styled(Heading.H1)`
    text-align: center;
`

export default {
    H1,
    HeadingH1
}
