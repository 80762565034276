import React, { PropsWithChildren, useEffect, useState } from 'react'

import Loader from '@nickel/ui/components/legacy/Loader'

const getScriptUrl = (baseUrl: string) => `${baseUrl}/cdn/scripts/widget-min.js`
const getStylesheetUrl = (baseUrl: string) => `${baseUrl}/cdn/styles/widget-min.css`

export type Props = PropsWithChildren<{
    baseUrl: string
}>

const PaylineProvider = ({ baseUrl, children }: Props) => {
    const [isLoaded, setIsLoaded] = useState(false)
    const scriptUrl = getScriptUrl(baseUrl)
    // add script
    useEffect(() => {
        let script: HTMLScriptElement | null = document.querySelector(`script[src="${scriptUrl}"]`)
        if (!script) {
            script = document.createElement('script')
            script.src = scriptUrl
            document.head.appendChild(script)
        }

        const onLoad = () => setIsLoaded(true)

        script.addEventListener('load', onLoad, false)
        return () => {
            if (script) script.removeEventListener('load', onLoad, false)
        }
    }, [scriptUrl])

    // add stylesheet
    const stylesheetUrl = getStylesheetUrl(baseUrl)
    useEffect(() => {
        let stylesheet: HTMLLinkElement | null = document.querySelector(`link[href="${stylesheetUrl}"]`)
        if (!stylesheet) {
            stylesheet = document.createElement('link')
            stylesheet.href = stylesheetUrl
            stylesheet.rel = 'stylesheet'
            document.head.appendChild(stylesheet)
        }
    }, [stylesheetUrl])

    // render children
    return isLoaded ? <>{children}</> : <Loader />
}

export default PaylineProvider
