import { equals, pipe, toLower } from 'ramda'

import { SupportedCountryISO2 } from '@nickel/l10n/types'

import { GetAllowedCountriesCountriesEnum } from '../services'

export const getAllowedCountryISO2 = (value: SupportedCountryISO2 | string): GetAllowedCountriesCountriesEnum => {
    const iso2 = Object.values(GetAllowedCountriesCountriesEnum).find(pipe(toLower, equals(toLower(value))))
    return iso2 ?? GetAllowedCountriesCountriesEnum.Fr
}

export const getSupportedCountryISO2 = (value: GetAllowedCountriesCountriesEnum): SupportedCountryISO2 => {
    const iso2 = Object.values(SupportedCountryISO2).find(pipe((c) => toLower(c), equals(toLower(value))))
    return iso2 ?? SupportedCountryISO2.FR
}
