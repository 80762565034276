import { useState } from 'react'

import useEventListener from './useEventListener'

export type Orientation = 'PORTRAIT' | 'LANDSCAPE'

const useOrientation = () => {
    const orientation = window.matchMedia('(orientation: landscape)')
    const [isLandscape, setIsLandscape] = useState(orientation?.matches)
    useEventListener('orientationchange', () => setIsLandscape((value) => !value), [])

    return isLandscape ? 'LANDSCAPE' : 'PORTRAIT'
}

export default useOrientation
