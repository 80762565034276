import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { isSet } from '@nickel/utils/lib/common'

import { RootState } from '..'
import { actions as registrationActions } from '../registration'

/* ------------- Type & State ------------- */

type MailPayload = {
    isFirstMailSet: boolean
}

type MailState = {
    email: string
    isValid: boolean
    isFirstMailSet: boolean
}

export const INITIAL_STATE: MailState = {
    email: '',
    isValid: false,
    isFirstMailSet: false
}

/* ------------- Slice & Reducers ------------- */

const SLICE_NAME = '@@mail'

const slice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        setIsFirstMailSet: (state, { payload: { isFirstMailSet } }: PayloadAction<MailPayload>): MailState => ({
            ...state,
            isFirstMailSet
        }),
        setEmail: (state, { payload }: PayloadAction<string>): MailState => ({
            ...state,
            email: payload
        }),
        setIsEmailValid: (state, { payload }: PayloadAction<boolean>): MailState => ({
            ...state,
            isValid: payload
        })
    },
    extraReducers: (builder) => {
        builder.addCase(registrationActions.reset, (): MailState => INITIAL_STATE)
    }
})

export default slice.reducer

/* ------------- Actions ------------- */

export const actions = {
    ...slice.actions
}

/* ------------- Selectors ------------- */

const getEmail = (state: RootState) => state.mail.email
const isValid = (state: RootState) => state.mail.isValid
const isSetAndValid = (state: RootState): boolean => state.mail.isValid && isSet(state.mail.email)
const isFirstMailSet = (state: RootState): boolean => state.mail.isFirstMailSet

export const selectors = {
    getEmail,
    isFirstMailSet,
    isSetAndValid,
    isValid
}
