import { createSelector } from '@reduxjs/toolkit'

import formsSelectors from '@nickel/forms/redux/selectors'
import { HostTypes } from '@nickel/kyc/fields/hostTypes/types'
import {
    BIRTH_DATE,
    FIRST_NAME,
    HOST_TYPE,
    LAST_NAME,
    PROPERTY_STATUS,
    SECOND_LAST_NAME
} from '@nickel/kyc/fields/names'
import { PropertyStatus } from '@nickel/kyc/fields/propertyStatus/types'
import {
    LegalHostName,
    PropertyStatusFormName,
    PropertyStatusHostedFormName
} from '@nickel/kyc/forms/propertyStatus/constants'
import { areTrue, isSet } from '@nickel/utils/lib/common'

import { UpdatePropertyHostDataPayloadHostTypeEnum } from '../../services'

/* ------------- Types ------------- */

type HostInfo = {
    [BIRTH_DATE]?: string
    [FIRST_NAME]?: string
    [LAST_NAME]?: string
    [SECOND_LAST_NAME]?: string
}

/* ------------- Selectors ------------- */

const getIsHostBirthDateValid = (pathName?: string) =>
    formsSelectors.getFieldIsValid(
        `${pathName ? `${pathName}.` : ''}${PropertyStatusFormName}.${PropertyStatusHostedFormName}.${BIRTH_DATE}`
    )
const getIsHostFirstNameValid = (pathName?: string) =>
    formsSelectors.getFieldIsValid(
        `${pathName ? `${pathName}.` : ''}${PropertyStatusFormName}.${PropertyStatusHostedFormName}.${FIRST_NAME}`
    )
const getIsHostLastNameValid = (pathName?: string) =>
    formsSelectors.getFieldIsValid(
        `${pathName ? `${pathName}.` : ''}${PropertyStatusFormName}.${PropertyStatusHostedFormName}.${LAST_NAME}`
    )
const getIsHostSecondLastNameValid = (pathName?: string) =>
    formsSelectors.getFieldIsValid(
        `${pathName ? `${pathName}.` : ''}${PropertyStatusFormName}.${PropertyStatusHostedFormName}.${SECOND_LAST_NAME}`
    )

const getIsHostLegalNameValid = (pathName?: string) =>
    formsSelectors.getFieldIsValid(`${pathName ? `${pathName}.` : ''}${PropertyStatusFormName}.${LegalHostName}`)

const getPropertyStatus = (pathName?: string) =>
    formsSelectors.getFieldValue<PropertyStatus>(
        `${pathName ? `${pathName}.` : ''}${PropertyStatusFormName}.${PROPERTY_STATUS}`
    )

const getPropertyStatusHost = (pathName?: string) =>
    formsSelectors.getFieldValue<HostInfo>(
        `${pathName ? `${pathName}.` : ''}${PropertyStatusFormName}.${PropertyStatusHostedFormName}`
    )
const getHostType = (pathName?: string) =>
    formsSelectors.getFieldValue<HostTypes>(`${pathName ? `${pathName}.` : ''}${PropertyStatusFormName}.${HOST_TYPE}`)

const getLegalHostName = (pathName?: string) =>
    formsSelectors.getFieldValue<string>(`${pathName ? `${pathName}.` : ''}${PropertyStatusFormName}.${LegalHostName}`)

const getHostData = (pathName?: string) =>
    createSelector(
        [getPropertyStatusHost(pathName), getHostType(pathName), getLegalHostName(pathName)],
        (host, hostType, legalName) => {
            if (!host) return
            return hostType === HostTypes.PHYSICAL
                ? {
                      birthDate: host.birthDate ?? '',
                      firstName: host.firstName ?? '',
                      lastNames: [host.lastName ?? '', ...(host.secondLastName ? [host.secondLastName] : [])],
                      hostType: UpdatePropertyHostDataPayloadHostTypeEnum.Physical
                  }
                : {
                      legalName,
                      hostType: UpdatePropertyHostDataPayloadHostTypeEnum.Legal
                  }
        }
    )

const isHostValid = (pathName?: string) =>
    createSelector(
        [
            getPropertyStatus(pathName),
            getPropertyStatusHost(pathName),
            getHostType(pathName),
            getIsHostBirthDateValid(pathName),
            getIsHostFirstNameValid(pathName),
            getIsHostLastNameValid(pathName),
            getIsHostLegalNameValid(pathName),
            getIsHostSecondLastNameValid(pathName),
            getLegalHostName(pathName)
        ],
        (
            propertyStatus,
            propertyStatusHost,
            hostType,
            isHostBirthDateValid,
            isHostFirstNameValid,
            isHostLastNameValid,
            isHostLegalNameValid,
            isHostSecondLastNameValid,
            legalHostName
        ) => {
            if (propertyStatus !== PropertyStatus.HOSTED) return false
            if (hostType === HostTypes.LEGAL) return areTrue(isSet(legalHostName), isHostLegalNameValid)
            return (
                areTrue(isHostBirthDateValid, isHostFirstNameValid, isHostLastNameValid) &&
                (!propertyStatusHost?.secondLastName || isHostSecondLastNameValid)
            )
        }
    )

const isPropertyStatusValid = (pathName?: string) =>
    createSelector(
        [getPropertyStatus(pathName), isHostValid(pathName)],
        (propertyStatus, _isHostValid) =>
            _isHostValid || propertyStatus === PropertyStatus.OWNER || propertyStatus === PropertyStatus.TENANT
    )

export const selectors = {
    getHostData,
    getPropertyStatus,
    isHostValid,
    isPropertyStatusValid
}
