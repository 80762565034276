import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { areSet } from '@nickel/utils/lib/common'

import { RootState } from '..'
import { NameFormatEnum } from '../../components/BankCardCustomizationComponents/types'
import { CardTypeEnum } from '../../components/BankCardTypeDetail/types'
import { CardReceptionModeEnum } from '../../screens/CardReceptionMode/types'
import { actions as registrationActions } from '../registration'

import { BankCardInfoState, CardReceptionModePayload, CardTypePayload, CardVisualPayload } from './types'

export const INITIAL_STATE: BankCardInfoState = {
    tagReceptionMode: CardReceptionModeEnum.CLASSIC,
    cardReceptionMode: CardReceptionModeEnum.CLASSIC,
    cardType: CardTypeEnum.Basic,
    cardCustomization: {
        color: '',
        nameFormat: NameFormatEnum.FirstnameLastname,
        region: '',
        visualCode: ''
    }
}

/* ------------- Slice & Reducers ------------- */

const SLICE_NAME = '@@bankCard'

const slice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        setTagReceptionMode: (state, { payload }: PayloadAction<CardReceptionModePayload>): BankCardInfoState => ({
            ...state,
            tagReceptionMode: payload.value
        }),
        setReceptionMode: (state, { payload }: PayloadAction<CardReceptionModePayload>): BankCardInfoState => ({
            ...state,
            cardReceptionMode: payload.value
        }),
        setCardType: (state, { payload }: PayloadAction<CardTypePayload>): BankCardInfoState => ({
            ...state,
            cardType: payload.value
        }),
        setCardVisual: (state, { payload }: PayloadAction<CardVisualPayload>): BankCardInfoState => ({
            ...state,
            cardCustomization: {
                ...state.cardCustomization,
                color: payload.color ?? state.cardCustomization.color,
                region: payload.region ?? state.cardCustomization.region,
                visualCode: payload.visualCode
            }
        }),
        setCardNameFormat: (state, { payload }: PayloadAction<NameFormatEnum>): BankCardInfoState => ({
            ...state,
            cardCustomization: { ...state.cardCustomization, nameFormat: payload }
        })
    },
    extraReducers: (builder) => {
        builder.addCase(registrationActions.reset, (): BankCardInfoState => INITIAL_STATE)
    }
})

export default slice.reducer

/* ------------- Actions ------------- */

export const { actions } = slice

/* ------------- Selectors ------------- */

const getBankCardInfo = (state: RootState) => state.bankCardInfo
const getTagReceptionMode = createSelector([getBankCardInfo], (bankCardInfo) => bankCardInfo.tagReceptionMode)
const getCardReceptionMode = createSelector([getBankCardInfo], (bankCardInfo) => bankCardInfo.cardReceptionMode)
const getCardType = createSelector([getBankCardInfo], (bankCardInfo) => bankCardInfo.cardType)
const getCardCustomization = createSelector([getBankCardInfo], (bankCardInfo) => bankCardInfo.cardCustomization)

const getCardCustomizationPayload = createSelector([getBankCardInfo], (bankCardInfo) => ({
    cardName: bankCardInfo.cardCustomization.nameFormat,
    visualCode: bankCardInfo.cardCustomization.visualCode
}))

const isBasicCardType = createSelector([getCardType], (cardType) => cardType === CardTypeEnum.Basic)

const isCardCustomizationValid = createSelector([getCardCustomization], (cardDetails) =>
    areSet(cardDetails.nameFormat, cardDetails.visualCode)
)
export const selectors = {
    getTagReceptionMode,
    getCardReceptionMode,
    getCardType,
    getCardCustomization,
    getCardCustomizationPayload,
    isBasicCardType,
    isCardCustomizationValid
}
