import { createAction, createSelector } from '@reduxjs/toolkit'

import formsSelectors from '@nickel/forms/redux/selectors'
import { createHasStringNChar } from '@nickel/utils/lib/common'

/* ------------- Types and Actions ------------- */

export const actions = {
    checkChallenge: createAction<string>('challenge/CHECK_CHALLENGE'),
    resetChallenge: createAction('challenge/RESET_CHALLENGE')
}

/* ------------- Selectors ------------- */

const getSmsChallengeCode = formsSelectors.getFieldValue<string>('challenge')

const getIsSmsChallengeCodeValid = createSelector([getSmsChallengeCode], createHasStringNChar(6))

export const selectors = {
    getIsSmsChallengeCodeValid,
    getSmsChallengeCode
}
