import React, { useContext } from 'react'

import { noop } from 'lodash'
import { useTranslation, Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Modal from '@nickel/core-ui/components/Modal'
import useL10n from '@nickel/l10n/useL10n'
import { ModalTemplateType } from '@nickel/stimulus/stimulus'

import { selectors as countrySelectors } from '../../redux/form/country'
import { modalTemplateMap } from '../../templates'

import { CustomUiModalContext } from './CustomModalContextProvider'

export declare type CustomUIModalAction = {
    text: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    action?: any
    testId?: string
}

export declare type CustomUiDataType = {
    title?: string
    message?: string
    primaryAction?: CustomUIModalAction
    secondaryAction?: CustomUIModalAction
    showCloseButton?: boolean
    height?: string
    width?: string
    template?: {
        type: ModalTemplateType
        payload?: number
    }
}

interface CustomUiModalProps {
    width: string
    height: string
}

const CustomUiModal = ({ width, height }: CustomUiModalProps) => {
    const { t } = useTranslation()
    const { l } = useL10n()
    const dispatch = useDispatch()
    const {
        visible,
        setVisible,
        uiData: { title, showCloseButton, primaryAction, secondaryAction, message, template }
    } = useContext(CustomUiModalContext)

    const localeLanguage = useSelector(countrySelectors.getLanguage)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onModalAction = (action?: any, shouldClose = true) => () => {
        if (action && action?.type) dispatch(action)
        else if (action) action()
        if (shouldClose) setVisible(false)
    }
    const getMessage = () => {
        if (!message) return ''
        if (Array.isArray(message)) return t(message[0], message[1])
        return (
            <Trans i18nKey={message} components={{ underline: <u /> }}>
                <b>Text_0</b>
            </Trans>
        )
    }
    const modalContent = () => {
        if (!template) {
            return getMessage()
        }
        const { type, payload } = template
        if (type && !modalTemplateMap.has(type)) return
        if (payload) return modalTemplateMap.get(type)({ t, payload })
        if (message) return modalTemplateMap.get(type)({ t, message })
        return modalTemplateMap.get(type)({ dispatch, isVisible: visible, t, localeLanguage, l })
    }

    return (
        <Modal
            open={visible}
            title={title ? t(title) : ''}
            showCloseButton={showCloseButton}
            primaryButton={
                primaryAction
                    ? {
                          text: t(primaryAction.text) ?? '',
                          onClick: onModalAction(primaryAction.action) ?? noop,
                          testId: primaryAction.testId ?? 'ModalPrimaryActionButton'
                      }
                    : undefined
            }
            secondaryButton={
                secondaryAction
                    ? {
                          text: t(secondaryAction.text) ?? '',
                          onClick: onModalAction(secondaryAction.action) ?? noop,
                          testId: secondaryAction.testId ?? 'ModalSecondaryActionButton',
                          variant: 'ghost'
                      }
                    : undefined
            }
            testId="CustomModal"
            onClose={() => setVisible(false)}
            width={width}
            height={height}
        >
            {modalContent()}
        </Modal>
    )
}

export default CustomUiModal
