import { createSelector } from '@reduxjs/toolkit'

import formsSelectors from '@nickel/forms/redux/selectors'
import { isTrue } from '@nickel/utils/lib/common'

import {
    TERMS_AND_CONDITIONS,
    USE_OF_DATA_NICKEL,
    USE_OF_DATA_PARTNERS,
    ACKNOWLEDGED_DOWNLOADED_DOCUMENTS
} from '../../screens/TermsAndConditions/constants'

/* ------------- Selectors ------------- */

const getUseOfDataNickel = formsSelectors.getFieldValue<boolean>(`${TERMS_AND_CONDITIONS}.${USE_OF_DATA_NICKEL}`)
const getUseOfDataPartners = formsSelectors.getFieldValue<boolean>(`${TERMS_AND_CONDITIONS}.${USE_OF_DATA_PARTNERS}`)
const getAcknowledgedDownloadedDocuments = formsSelectors.getFieldValue<boolean>(
    `${TERMS_AND_CONDITIONS}.${ACKNOWLEDGED_DOWNLOADED_DOCUMENTS}`
)

const isFormValid = createSelector([getAcknowledgedDownloadedDocuments], (acknowledgement) => isTrue(acknowledgement))

export const selectors = {
    getUseOfDataNickel,
    getUseOfDataPartners,
    getAcknowledgedDownloadedDocuments,
    isFormValid
}
