import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { persistReducer } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'

import { FORMS_STORE_NAME } from '@nickel/forms/redux/constants'
import { store as formsStore } from '@nickel/forms/redux/reducer'
import { INITIAL_STATE as stimulusInitialState, reducer as ui } from '@nickel/stimulus/ui'

import ReduxPersist from '../config/reduxPersist'
import barcode, { INITIAL_STATE as barcodeInitialState } from '../screens/Barcode/slice'
import rehydration from '../services/rehydration'

import app, { INITIAL_STATE as appInitialState } from './app'
import auth, { INITIAL_STATE as authInitialState } from './auth'
import bankCardInfo, { INITIAL_STATE as bankCardInfoInitialState } from './bankCardInfo'
import biometricConsent, { INITIAL_STATE as biometricConsentState } from './biometricConsent'
import captcha, { INITIAL_STATE as captchaInitialState } from './captcha'
import documents, { INITIAL_STATE as documentsInitialState } from './documents'
import eidPdfDocuments, { INITIAL_STATE as eidPdfDocumentsInitialState } from './eidPdfDocuments'
import finalization, { INITIAL_STATE as finalizationInitialState } from './finalization'
import guardianTerms, { INITIAL_STATE as guardianTermsInitialState } from './form/guardianTerms'
import profession, { INITIAL_STATE as professionInitialState } from './form/profession'
import history from './history'
import identityDocuments, { INITIAL_STATE as identityDocumentsInitialState } from './identityDocuments'
import liveness, { INITIAL_STATE as livenessInitialState } from './liveness'
import mail, { INITIAL_STATE as mailState } from './mail'
import reopening, { INITIAL_STATE as reopeningInitialState } from './reopening'
import reopeningKyc, { INITIAL_STATE as reopeningKycInitialState } from './reopeningKyc'
import rootSaga from './sagas'
import scanner, { INITIAL_STATE as scannerInitialState } from './scanner'
import tracking, { INITIAL_STATE as trackingInitialState } from './tracking'

const router = connectRouter(history)

const rootReducer = combineReducers({
    app,
    auth,
    mail,
    bankCardInfo,
    biometricConsent,
    barcode,
    documents,
    finalization,
    guardianTerms,
    identityDocuments,
    liveness,
    profession,
    reopening,
    reopeningKyc,
    router,
    scanner,
    tracking,
    ui,
    eidPdfDocuments,
    ...formsStore,
    captcha
})

const persistedRootReducer = persistReducer(ReduxPersist.rootConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware()
const middlewares = [routerMiddleware(history), sagaMiddleware]
const store = configureStore({ reducer: persistedRootReducer, middleware: middlewares })

export const sagasManager = sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const initialRootState: RootState = {
    app: appInitialState,
    auth: authInitialState,
    mail: mailState,
    bankCardInfo: bankCardInfoInitialState,
    barcode: barcodeInitialState,
    documents: documentsInitialState,
    eidPdfDocuments: eidPdfDocumentsInitialState,
    finalization: finalizationInitialState,
    guardianTerms: guardianTermsInitialState,
    biometricConsent: biometricConsentState,
    identityDocuments: identityDocumentsInitialState,
    liveness: livenessInitialState,
    profession: professionInitialState,
    reopening: reopeningInitialState,
    reopeningKyc: reopeningKycInitialState,
    captcha: captchaInitialState,
    router: {
        action: 'POP',
        location: {
            hash: '',
            key: 'key',
            pathname: '/path',
            query: {},
            search: '',
            state: ''
        }
    },
    scanner: scannerInitialState,
    tracking: trackingInitialState,
    ui: stimulusInitialState,
    [FORMS_STORE_NAME]: {},
    _persist: { version: 0, rehydrated: false }
}

// configure persistStore and check reducer version number
rehydration.updateReducers()

if (window.Cypress) {
    window.store = store
}

export default store
