import styled from 'styled-components'

import Button from '@nickel/core-ui/components/Button'

const BackButton = styled(Button)<{ visible: boolean }>`
    visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`

export default {
    BackButton
}
