import { createSelector, Selector } from '@reduxjs/toolkit'
import { always } from 'ramda'

import formsSelectors from '@nickel/forms/redux/selectors'
import { isValid as isIdentityDocumentTypeValid } from '@nickel/kyc/fields/identityDocumentType/utils'
import { IDENTITY_DOCUMENT_TYPE } from '@nickel/kyc/fields/names'
import { SupportedCountryISO2 } from '@nickel/l10n/types'
import { areSet, areTrue, isTrue } from '@nickel/utils/lib/common'

import { RootState } from '../..'
import { Role } from '../../../constants/roles'
import { Step } from '../../../constants/steps'
import { selectors as barcodeSelectors } from '../../../screens/Barcode/slice'
import { IS_PRIVATE_LIFE_CERTIFICATION_APPROVED } from '../../../screens/PrivateLifeCertification/constants'
import { getShouldDisplayRegion } from '../../../screens/ReopeningInfo/utils'
import { isDefined } from '../../../utils'
import { selectors as bankCardInfoSelectors } from '../../bankCardInfo'
import { DOCUMENT_TYPE } from '../../documents'
import { selectors as eidPdfDocumentsSelectors } from '../../eidPdfDocuments'
import { selectors as accountTypeSelectors } from '../../form/accountType'
import { selectors as accountUseSelectors } from '../../form/accountUse'
import { selectors as addressSelectors } from '../../form/address'
import { selectors as birthSelectors } from '../../form/birth'
import { selectors as challengeSelectors } from '../../form/challenge'
import { selectors as civilSelectors } from '../../form/civil'
import { selectors as formDocumentsSelectors } from '../../form/documents'
import { selectors as familyStatusSelectors } from '../../form/familyStatus'
import { selectors as financialSelectors } from '../../form/financial'
import { selectors as fiscalAddressSelectors } from '../../form/fiscalAddress'
import { selectors as phoneNumberSelectors } from '../../form/phoneNumber'
import { selectors as employmentSelectors } from '../../form/profession'
import { selectors as propertyStatusSelectors } from '../../form/propertyStatus'
import { selectors as registrationTypeSelectors } from '../../form/registrationType'
import { selectors as reopeningInfoSelectors } from '../../form/reopeningInfo'
import { selectors as reopeningMatchMultySelectors } from '../../form/reopeningMatchMulty'
import { selectors as reopeningMatchZeroSelectors } from '../../form/reopeningMatchZero'
import { selectors as taxResidenceSelectors } from '../../form/taxResidence'
import { selectors as termsAndConditionsSelectors } from '../../form/termsAndConditions'
import { selectors as usPersonSelectors } from '../../form/usPerson'
import { selectors as livenessSelectors } from '../../liveness'
import { selectors as mailSelectors } from '../../mail'

const getDocumentType = (roleKey: Role) =>
    formsSelectors.getFieldValue<DOCUMENT_TYPE>(`${roleKey}.${IDENTITY_DOCUMENT_TYPE}`)

const createDocumentChoice = (roleKey: Role) =>
    createSelector(
        [getDocumentType(roleKey)],
        (documentType) => isDefined(documentType) && isIdentityDocumentTypeValid(documentType)
    )

const privateLifeCertification = createSelector(
    [formsSelectors.getFieldValue<boolean>(IS_PRIVATE_LIFE_CERTIFICATION_APPROVED)],
    isTrue
)

const isReopeningInfoValid = createSelector(
    [
        reopeningInfoSelectors.getReopeningFirstNameIsValid,
        reopeningInfoSelectors.getReopeningLasttNameIsValid,
        reopeningInfoSelectors.getReopeningBirthDateIsValid,
        reopeningInfoSelectors.getReopeningBirthCountry,
        reopeningInfoSelectors.getReopeningBirthCountryIsValid,
        reopeningInfoSelectors.getReopeningBirthRegionIsValid
    ],
    (firstNameIsValid, lastNameIsValid, birthDateIsValid, birthCountry, birthCountryIsValid, birthRegionIsValid) =>
        areTrue(firstNameIsValid, lastNameIsValid, birthDateIsValid, birthCountryIsValid) &&
        (!getShouldDisplayRegion(birthCountry as SupportedCountryISO2) || birthRegionIsValid)
)

const reopeningMatchMulty = createSelector([reopeningMatchMultySelectors.getReopeningSelected], (cardInfo) =>
    isTrue(cardInfo?.complete)
)

const reopeningMatchZero = createSelector(
    [reopeningMatchZeroSelectors.getReopeningDocumentType, reopeningMatchZeroSelectors.getReopeningDocumentNumber],
    (cardType, cardNumber) => areSet(cardType, cardNumber)
)

const validators: { [key in Step]?: Selector<RootState, boolean> } = {
    [Step.ACCOUNT_TYPE]: accountTypeSelectors.isAccountTypeValid(),
    [Step.ACCOUNT_USE]: accountUseSelectors.isAccountUseValid(),
    [Step.ADDRESS_CHILD]: addressSelectors.isAddressValid(Role.CHILD),
    [Step.ADDRESS_GUARDIAN]: addressSelectors.isAddressValid(Role.GUARDIAN),
    [Step.ADDRESS]: addressSelectors.isAddressValid(Role.ADULT),
    [Step.ALL_ELEMENTS]: always(true),
    [Step.BARCODE]: barcodeSelectors.getIsBarcodeValid,
    [Step.BIRTH_DATA_CONFIRMATION_CHILD]: birthSelectors.isBirthValid(Role.CHILD),
    [Step.BIRTH_DATA_CONFIRMATION_GUARDIAN]: birthSelectors.isBirthValid(Role.GUARDIAN),
    [Step.BIRTH_DATA_CONFIRMATION]: birthSelectors.isBirthValid(Role.ADULT),
    [Step.CHOICE_CHILD]: createDocumentChoice(Role.CHILD),
    [Step.CHOICE_GUARDIAN]: createDocumentChoice(Role.GUARDIAN),
    [Step.CHOICE]: createDocumentChoice(Role.ADULT),
    [Step.CIVILITY_CONFIRMATION_CHILD]: civilSelectors.isCivilValid(Role.CHILD),
    [Step.CIVILITY_CONFIRMATION_GUARDIAN]: civilSelectors.isCivilValid(Role.GUARDIAN),
    [Step.CIVILITY_CONFIRMATION]: civilSelectors.isCivilValid(Role.ADULT),
    [Step.DOCUMENT_CHILD]: formDocumentsSelectors.getIsChildIdentityDocumentValid,
    [Step.DOCUMENT_GUARDIAN]: formDocumentsSelectors.getIsGuardianIdentityDocumentValid,
    [Step.DOCUMENT]: formDocumentsSelectors.getIsAdultIdentityDocumentValid,
    [Step.EMPLOYMENT]: employmentSelectors.isProfessionValid,
    [Step.MANDATORY_EMAIL]: mailSelectors.isSetAndValid,
    [Step.FAMILY_REGISTER]: formDocumentsSelectors.getIsFamilyRegisterValid,
    [Step.FAMILY_STATUS]: familyStatusSelectors.isFamilyStatusValid(),
    [Step.FINANCIAL]: financialSelectors.isFinancialValid(),
    [Step.FISCAL_ADDRESS]: fiscalAddressSelectors.isFiscalStepValid,
    [Step.HOSTED]: propertyStatusSelectors.isHostValid(),
    [Step.LIVENESS]: livenessSelectors.getIsCompleted(Role.ADULT),
    [Step.LIVENESS_CHILD]: livenessSelectors.getIsCompleted(Role.CHILD),
    [Step.LIVENESS_GUARDIAN]: livenessSelectors.getIsCompleted(Role.GUARDIAN),
    [Step.LIVENESS_VIDEO]: always(true),
    [Step.MAIL]: mailSelectors.isValid,
    [Step.PHONE]: phoneNumberSelectors.getPhoneNumberIsValid,
    [Step.PRIVATE_LIFE_CERTIFICATION]: privateLifeCertification,
    [Step.PROPERTY_STATUS]: propertyStatusSelectors.isPropertyStatusValid(),
    [Step.REGISTRATION_TYPE]: registrationTypeSelectors.isRegistrationTypeValid,
    [Step.REOPENING_INFO]: isReopeningInfoValid,
    [Step.REOPENING_MATCH_MULTY]: reopeningMatchMulty,
    [Step.REOPENING_MATCH_ZERO]: reopeningMatchZero,
    [Step.SMS_CHALLENGE]: challengeSelectors.getIsSmsChallengeCodeValid,
    [Step.TAX_RESIDENCE]: taxResidenceSelectors.isTaxResidenceValid(),
    [Step.TERMS_AND_CONDITIONS]: termsAndConditionsSelectors.isFormValid,
    [Step.US_PERSON]: usPersonSelectors.isUsPersonValid(),
    [Step.CARD_RECEPTION_MODE]: always(true),
    [Step.BANK_CARD_TYPE]: always(true),
    [Step.BANK_CARD_CUSTOMIZATION]: bankCardInfoSelectors.isCardCustomizationValid,
    [Step.FALLBACK_CARD_TYPE]: always(true),
    [Step.TERMS_AND_CONDITIONS_EID]: eidPdfDocumentsSelectors.isTermsAndConditionsValid,
    [Step.PRIVACY_POLICY_EID]: eidPdfDocumentsSelectors.isPrivacyValid
}

export default validators
