import { useEffect, useRef, useState } from 'react'

const useInterval = (callback: () => void, delay: number, isRunning = true) => {
    const savedCallback = useRef<() => void>()
    const [isDocumentHidden, setIsDocumentHidden] = useState<boolean>(false)

    useEffect(() => {
        savedCallback.current = callback
        document.addEventListener('visibilitychange', () => setIsDocumentHidden(document.hidden))
    }, [callback])

    useEffect(() => {
        if (isRunning && !isDocumentHidden) {
            const interval = setInterval(() => {
                if (savedCallback.current) savedCallback.current()
            }, delay)
            return () => {
                clearInterval(interval)
            }
        }
    }, [delay, isDocumentHidden, isRunning])
}

export default useInterval
