import React, { useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import ChevronLeftIcon from '@nickel/ui/components/icons/ChevronLeft'

import useNavigation from '../../../hooks/useNavigation'
import { selectors as navigationSelectors } from '../../../redux/navigation'

import Styled from './style'

const BackButton = () => {
    const { t } = useTranslation()
    const isFirstStep = useSelector(navigationSelectors.getIsFirstStep)
    const { goToPreviousStep } = useNavigation()
    const goBack = useCallback(() => {
        if (!isFirstStep) goToPreviousStep()
    }, [goToPreviousStep, isFirstStep])

    return (
        <Styled.BackButton
            Icon={ChevronLeftIcon}
            onClick={goBack}
            testId="BackButton"
            text={t('common:back')}
            variant="ghost"
            visible={!isFirstStep}
        />
    )
}

export default BackButton
