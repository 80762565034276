import { createSelector } from '@reduxjs/toolkit'

import formsSelectors from '@nickel/forms/redux/selectors'
import { IDENTITY_DOCUMENT_TYPE } from '@nickel/kyc/fields/names'

import { RootState } from '..'
import { Role } from '../../constants/roles'
import { selectors as documentsSelectors, DOCUMENT_TYPE, Document, getDocumentStoreId } from '../documents'

/* ------------- Selectors ------------- */

const createGetIdentityDocumentType = (role: Role) =>
    formsSelectors.getFieldValue<DOCUMENT_TYPE>(`${role}.${IDENTITY_DOCUMENT_TYPE}`)

const createGetIsIdentityDocumentValid = (role: Role) =>
    createSelector([createGetIdentityDocumentType(role), (state: RootState) => state], (documentType, state) => {
        if (!documentType) return false
        return documentsSelectors.getIsUploaded(getDocumentStoreId(role, documentType))(state)
    })

const createGetIdentityDocumentData = (role: Role) =>
    createSelector(
        [createGetIdentityDocumentType(role), (state: RootState) => state],
        (documentType, state): Partial<Pick<Document, 'accessToken' | 'documentId' | 'documentType'>> => {
            if (!documentType) return {}
            const document = documentsSelectors.getDocument(getDocumentStoreId(role, documentType))(state)
            return {
                accessToken: document?.accessToken,
                documentId: document?.documentId,
                documentType: document?.documentType
            }
        }
    )

const getIsFamilyRegisterValid = documentsSelectors.getIsUploaded(
    getDocumentStoreId(Role.GUARDIAN, DOCUMENT_TYPE.FAMILY_REGISTER)
)

const getFamilyRegisterData = createSelector(
    [documentsSelectors.getDocument(getDocumentStoreId(Role.GUARDIAN, DOCUMENT_TYPE.FAMILY_REGISTER))],
    (document): Pick<Document, 'accessToken' | 'documentId' | 'documentType'> => ({
        accessToken: document?.accessToken,
        documentId: document?.documentId,
        documentType: document?.documentType
    })
)

export const selectors = {
    createGetIdentityDocumentType,
    getAdultIdentityDocumentData: createGetIdentityDocumentData(Role.ADULT),
    getChildIdentityDocumentData: createGetIdentityDocumentData(Role.CHILD),
    getFamilyRegisterData,
    getGuardianIdentityDocumentData: createGetIdentityDocumentData(Role.GUARDIAN),
    getIsAdultIdentityDocumentValid: createGetIsIdentityDocumentValid(Role.ADULT),
    getIsChildIdentityDocumentValid: createGetIsIdentityDocumentValid(Role.CHILD),
    getIsFamilyRegisterValid,
    getIsGuardianIdentityDocumentValid: createGetIsIdentityDocumentValid(Role.GUARDIAN)
}
