import React, { useCallback, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import useNavigation from '../../hooks/useNavigation'
import { selectors as navigationSelectors } from '../../redux/navigation'

import Styled from './style'
import { Props } from './types'

const NextButton = ({ disabled, label, onClick, pending, pendingOnClick = false, fullScreen = false }: Props) => {
    const { t } = useTranslation()
    const { goToNextStep } = useNavigation()
    const isNextButtonEnabled = useSelector(navigationSelectors.getIsNextButtonEnabled)
    const [clicked, setClicked] = useState(false)

    const _onClick = useCallback(() => {
        if (!clicked) setClicked(true)

        if (onClick) return onClick()
        goToNextStep()
    }, [clicked, goToNextStep, onClick])

    return (
        <Styled.NextButtonContainer>
            <Styled.NextButton
                disabled={disabled ?? !isNextButtonEnabled ?? (pendingOnClick && clicked)}
                size="large"
                onClick={_onClick}
                pending={pending ?? (pendingOnClick && clicked)}
                testId="next-button"
                text={label || t('common:next')}
                fullScreen={fullScreen}
            />
        </Styled.NextButtonContainer>
    )
}

export default NextButton
