import React, { useEffect, useState } from 'react'

import noop from 'lodash/noop'

import useInterval from '../../hooks/useInterval'

import Styled from './style'

const PERIMETER = 534

type Props = {
    duration: number
    isAnimating?: boolean
    onComplete?: () => void
}

const ProgressCircle = ({ duration, isAnimating = true, onComplete = noop, ...props }: Props) => {
    const [count, setCount] = useState<number>(0)

    useEffect(() => {
        if (!isAnimating) setCount(0)
        else if (count >= duration) onComplete()
    }, [count, duration, isAnimating, onComplete, setCount])

    useInterval(() => setCount(count + 1), 1000, isAnimating && count < duration)

    return (
        <Styled.Container {...props}>
            <svg height="180" viewBox="0 0 180 180" width="180">
                <Styled.Circle strokeDasharray={PERIMETER} />
                <Styled.Circle
                    indicator
                    strokeDasharray={PERIMETER}
                    strokeDashoffset={(count / duration) * PERIMETER}
                />
            </svg>
            {isAnimating && <Styled.Label>{duration - count}</Styled.Label>}
        </Styled.Container>
    )
}

export default ProgressCircle
