import { PayloadAction } from '@reduxjs/toolkit'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import formsActions from '@nickel/forms/redux/actions'
import { FIELDS_META_NAME } from '@nickel/forms/redux/constants'
import { SupportedLocale } from '@nickel/i18n/lib/types'
import {
    ACCOUNT_TYPE,
    ACCOUNT_USE,
    ADDRESS,
    BIRTH_DATE,
    CIVILITY,
    FAMILY_STATUS,
    FIRST_NAME,
    FISCAL_ADDRESS,
    LAST_NAME,
    PHONE_NUMBER,
    PROPERTY_STATUS,
    REGISTRATION_TYPE,
    SEX,
    TAX_IDENTIFICATION_NUMBER,
    TAX_RESIDENCE_COUNTRIES
} from '@nickel/kyc/fields/names'
import { RegistrationType } from '@nickel/kyc/fields/registrationType/types'
import { AddressFieldNames } from '@nickel/kyc/forms/address/constants'
import { BirthFieldNames, BirthFormName } from '@nickel/kyc/forms/birth/constants'
import { CivilFormName } from '@nickel/kyc/forms/civil/constants'
import { EmploymentFieldNames, EmploymentFormName } from '@nickel/kyc/forms/employment/constants'
import { FinancialFieldNames, FinancialFormName } from '@nickel/kyc/forms/financial/constants'
import { FiscalAddressFieldNames } from '@nickel/kyc/forms/fiscalAddress/constants'
import { PropertyStatusHostedFormName } from '@nickel/kyc/forms/propertyStatus/constants'
import { UsPersonFieldNames, UsPersonFormName } from '@nickel/kyc/forms/usPerson/constants'

import { actions as registrationActions, InitRegistrationPayload } from '..'
import { REOPENING_INFO } from '../../../components/ReopeningInfo/constants'
import {
    TAX_RESIDENCES_INFOS_NAME,
    TAX_RESIDENT_IN_CURRENT_COUNTRY_CHOICE
} from '../../../components/TaxResidenceInformations/constant'
import { FeatureFlag, isSyncFeatureEnabled } from '../../../config/features'
import { Role } from '../../../constants/roles'
import { ALREADY_CUSTOMER, COUNTRY, FISCAL_ADDRESS_CHOICE, LANGUAGE } from '../../../fields/names'
import { setGlobalLanguage } from '../../../i18n'
import { setGlobalISO2 } from '../../../l10n/config'
import { getSupportedCountryISO2 } from '../../../l10n/utils'
import { IS_PRIVATE_LIFE_CERTIFICATION_APPROVED } from '../../../screens/PrivateLifeCertification/constants'
import { REOPENING_KYC_ACCOUNT_USE, REOPENING_KYC_SITUATION } from '../../../screens/ReopeningKyc/constants'
import { trackCountryAndLocale } from '../../../utils/tracking'
import { actions as authActions } from '../../auth'
import { createRegistrationForm } from '../../auth/sagas'
import { getLanguageFromCountry } from '../../form/utils'

import { send } from './send'

function* reset() {
    yield put(
        formsActions.changeFormMeta({
            meta: { [FIELDS_META_NAME]: DEFAULT_METAS }
        })
    )
    yield put(formsActions.resetForm())
}

function* init(action: PayloadAction<InitRegistrationPayload>) {
    yield call(reset)
    yield put(authActions.setIsInitializing(true))

    const { country, language, registrationType } = action.payload

    setGlobalISO2(getSupportedCountryISO2(country))
    const lang = getLanguageFromCountry(country, language)
    setGlobalLanguage(lang)
    if (sessionStorage.getItem('CROWDIN_IN_CONTEXT') === 'true') {
        setGlobalLanguage(SupportedLocale.ACH_UG)
    }

    trackCountryAndLocale()
    yield put(
        formsActions.changeFieldValue({
            fieldPath: COUNTRY,
            fieldValue: country
        })
    )
    yield put(
        formsActions.changeFieldValue({
            fieldPath: LANGUAGE,
            fieldValue: lang
        })
    )
    yield put(
        formsActions.changeFormMeta({
            meta: { [FIELDS_META_NAME]: DEFAULT_METAS }
        })
    )
    const isCnjFeatureEnabled = isSyncFeatureEnabled(FeatureFlag.CNJ, country)
    if (!isCnjFeatureEnabled || registrationType) {
        yield put(
            formsActions.changeFieldValue({
                fieldPath: REGISTRATION_TYPE,
                fieldValue: !isCnjFeatureEnabled ? RegistrationType.ADULTE : registrationType ?? RegistrationType.ADULTE
            })
        )
    }

    yield call(createRegistrationForm) // TODO: useCreateRegistrationForm when removing this saga
}

/**
 * Force tous les champs du formulaire à passer isTouched à false. C'est moche mais on n'a pas trouvé de meilleure solution.
 * Sans cette fonction, lorsqu'on recommence une souscription isTouched est à true pour tous ces champs, ce qui fait que le message "Champs requis" est systématiquement affiché
 */
function buildMeta() {
    const meta = {
        [REGISTRATION_TYPE]: { isTouched: false },
        [ALREADY_CUSTOMER]: { isTouched: false },
        [`${TAX_RESIDENCES_INFOS_NAME}.${TAX_RESIDENT_IN_CURRENT_COUNTRY_CHOICE}`]: { isTouched: false },
        [FISCAL_ADDRESS_CHOICE]: { isTouched: false },
        [IS_PRIVATE_LIFE_CERTIFICATION_APPROVED]: { isTouched: false },
        [ACCOUNT_TYPE]: { isTouched: false },
        [ACCOUNT_USE]: { isTouched: false },
        [PHONE_NUMBER]: { isTouched: false },
        [`${REOPENING_INFO}.${LAST_NAME}`]: { isTouched: false },
        [`${REOPENING_INFO}.${FIRST_NAME}`]: { isTouched: false },
        [`${REOPENING_INFO}.${BIRTH_DATE}`]: { isTouched: false },
        [`${REOPENING_INFO}.${COUNTRY}`]: { isTouched: false },
        [`${REOPENING_INFO}.${BirthFieldNames.REGION}`]: { isTouched: false },
        [`${REOPENING_KYC_SITUATION}.${TAX_RESIDENCE_COUNTRIES}`]: { isTouched: false },
        [`${REOPENING_KYC_ACCOUNT_USE}.${ACCOUNT_TYPE}`]: { isTouched: false },
        [`${REOPENING_KYC_ACCOUNT_USE}.${ACCOUNT_USE}`]: { isTouched: false }
    }
    const openingCases = ['', `${REOPENING_KYC_SITUATION}.`]
    openingCases.forEach((openingCase) => {
        Object.defineProperties(meta, {
            [`${openingCase}${FAMILY_STATUS}`]: { value: { isTouched: false } },
            [`${openingCase}${PROPERTY_STATUS}.${PROPERTY_STATUS}`]: { value: { isTouched: false } },
            [`${openingCase}${PROPERTY_STATUS}.${PropertyStatusHostedFormName}.${LAST_NAME}`]: {
                value: { isTouched: false }
            },
            [`${openingCase}${PROPERTY_STATUS}.${PropertyStatusHostedFormName}.${FIRST_NAME}`]: {
                value: { isTouched: false }
            },
            [`${openingCase}${PROPERTY_STATUS}.${PropertyStatusHostedFormName}.${BIRTH_DATE}`]: {
                value: { isTouched: false }
            },
            [`${openingCase}${EmploymentFormName}.${EmploymentFieldNames.STATUS}`]: { value: { isTouched: false } },
            [`${openingCase}${EmploymentFormName}.${EmploymentFieldNames.SECTOR}`]: { value: { isTouched: false } },
            [`${openingCase}${EmploymentFormName}.${EmploymentFieldNames.SUB_SECTOR}`]: { value: { isTouched: false } },
            [`${openingCase}${EmploymentFormName}.${EmploymentFieldNames.ACCOUNT_PROFESSIONAL_USAGE}`]: {
                value: { isTouched: false }
            },
            [`${openingCase}${EmploymentFormName}.${EmploymentFieldNames.JOB}`]: { value: { isTouched: false } },
            [`${openingCase}${EmploymentFormName}.${EmploymentFieldNames.EMPLOYER}`]: { value: { isTouched: false } },
            [`${openingCase}${EmploymentFormName}.${EmploymentFieldNames.FORMER_EMPLOYMENT_STATUS}`]: {
                value: { isTouched: false }
            },
            [`${openingCase}${EmploymentFormName}.${EmploymentFieldNames.FORMER_JOB}`]: {
                value: { isTouched: false }
            },
            [`${openingCase}${FinancialFormName}.${FinancialFieldNames.EARNINGS_RANGE}`]: {
                value: { isTouched: false }
            },
            [`${openingCase}${FinancialFormName}.${FinancialFieldNames.ASSET_VALUE_RANGE}`]: {
                value: { isTouched: false }
            },
            [`${openingCase}${UsPersonFormName}.${UsPersonFieldNames.CHOICE}`]: { value: { isTouched: false } },
            [`${openingCase}${UsPersonFormName}.${UsPersonFieldNames.FATCA}`]: { value: { isTouched: false } }
        })
    })
    Object.keys(Role).forEach((role) =>
        Object.defineProperties(meta, {
            [`${role}.${ADDRESS}.${ADDRESS}`]: { value: { isTouched: false } },
            [`${role}.${ADDRESS}.${ADDRESS}.${AddressFieldNames.LOCALITY}`]: { value: { isTouched: false } },
            [`${role}.${ADDRESS}.${ADDRESS}.${AddressFieldNames.POSTAL_CODE}`]: { value: { isTouched: false } },
            [`${role}.${ADDRESS}.${ADDRESS}.${AddressFieldNames.STREET_NAME}`]: { value: { isTouched: false } },
            [`${role}.${ADDRESS}.${ADDRESS}.${AddressFieldNames.STREET_NUMBER}`]: { value: { isTouched: false } },
            [`${role}.${FISCAL_ADDRESS}.${FISCAL_ADDRESS}`]: { value: { isTouched: false } },
            [`${role}.${FISCAL_ADDRESS}.${FiscalAddressFieldNames.COUNTRY}`]: {
                value: { isTouched: false }
            },
            [`${role}.${FISCAL_ADDRESS}.${FISCAL_ADDRESS}.${FiscalAddressFieldNames.POSTAL_CODE}`]: {
                value: { isTouched: false }
            },
            [`${role}.${FISCAL_ADDRESS}.${FISCAL_ADDRESS}.${FiscalAddressFieldNames.LOCALITY}`]: {
                value: { isTouched: false }
            },
            [`${role}.${FISCAL_ADDRESS}.${FISCAL_ADDRESS}.${FiscalAddressFieldNames.STREET_NAME}`]: {
                value: { isTouched: false }
            },
            [`${role}.${FISCAL_ADDRESS}.${FISCAL_ADDRESS}.${FiscalAddressFieldNames.STREET_NUMBER}`]: {
                value: { isTouched: false }
            },
            [`${role}.${CivilFormName}.${CIVILITY}`]: { value: { isTouched: false } },
            [`${role}.${CivilFormName}.${SEX}`]: { value: { isTouched: false } },
            [`${role}.${CivilFormName}.${FIRST_NAME}`]: { value: { isTouched: false } },
            [`${role}.${CivilFormName}.${LAST_NAME}`]: { value: { isTouched: false } },
            [`${role}.${CivilFormName}.${TAX_IDENTIFICATION_NUMBER}`]: { value: { isTouched: false } },
            [`${role}.${BirthFormName}.${BirthFieldNames.DATE}`]: { value: { isTouched: false } },
            [`${role}.${BirthFormName}.${BirthFieldNames.COUNTRY}`]: { value: { isTouched: false } },
            [`${role}.${BirthFormName}.${BirthFieldNames.REGION}`]: { value: { isTouched: false } },
            [`${role}.${BirthFormName}.${BirthFieldNames.PLACE}`]: { value: { isTouched: false } },
            [`${role}.${BirthFormName}.${BirthFieldNames.NATIONALITY}`]: { value: { isTouched: false } }
        })
    )
    return meta
}

export const DEFAULT_METAS = buildMeta()

export default function* root() {
    yield all([takeLatest(registrationActions.init, init), takeEvery(registrationActions.send, send)])
}
