import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { areSet } from '@nickel/utils/lib/common'

import { RootState } from '..'
import { Role } from '../../constants/roles'
import { actions as registrationActions } from '../registration'

/* ------------- Type & State ------------- */

type AuthState = {
    accessToken?: string
    challenge: {
        checked: boolean
        fetching: boolean
        id: null | string
        phoneNumber?: string
        recipients: string[]
        token?: string
    }
    onfido: {
        tokens: Record<Role, string | undefined>
        allowedDocument: Record<string, Set<string>>
    }
    registrationFormId?: string
    registrationFormCode?: string
    token?: null | string
    isInitializing: boolean | undefined
}

type RegistrationCodePayload = {
    registrationFormCode?: string
}
type CheckSmsChallengePayload = {
    token: string
}

type RegistrationFromPayload = {
    accessToken: string
    registrationFormId: string
}

type GenerateSmsChallengePayload = {
    token: string
    id?: string
    phone: string
}

type GetOnfidoTokenPayload = { role: Role; token: string; allowedDocument: AuthState['onfido']['allowedDocument'] }

export const INITIAL_STATE: AuthState = {
    accessToken: undefined,
    challenge: {
        checked: false,
        fetching: false,
        id: null,
        phoneNumber: undefined,
        recipients: [],
        token: undefined
    },
    onfido: {
        tokens: {
            [Role.ADULT]: undefined,
            [Role.CHILD]: undefined,
            [Role.GUARDIAN]: undefined
        },
        allowedDocument: {}
    },
    registrationFormId: undefined,
    token: null,
    isInitializing: false
}

/* ------------- Slice & Reducers ------------- */

const SLICE_NAME = '@@auth'

const slice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        generateSMSChallengeRequest: (state): AuthState => ({
            ...state,
            challenge: {
                ...state.challenge,
                fetching: true,
                checked: false,
                token: undefined
            }
        }),
        generateSMSChallengeSuccess: (state, { payload }: PayloadAction<GenerateSmsChallengePayload>): AuthState => ({
            ...state,
            challenge: {
                ...state.challenge,
                fetching: false,
                id: payload.id ?? '',
                recipients: [...(state.challenge?.recipients ?? []), payload.phone],
                token: payload.token
            }
        }),
        checkSMSChallengeRequest: (state): AuthState => ({
            ...state,

            challenge: {
                ...state.challenge,
                fetching: true
            }
        }),
        checkSMSChallengeSuccess: (state, { payload }: PayloadAction<CheckSmsChallengePayload>): AuthState => ({
            ...state,
            challenge: {
                ...state.challenge,
                fetching: false,
                checked: true
            },
            token: payload.token
        }),
        checkSMSChallengeFailure: (state): AuthState => ({
            ...state,

            challenge: {
                ...state.challenge,
                fetching: false
            }
        }),
        createRegistrationFormSuccess: (
            state,
            { payload: { accessToken, registrationFormId } }: PayloadAction<RegistrationFromPayload>
        ): AuthState => ({
            ...state,
            accessToken,
            registrationFormId
        }),
        setRegistrationFormCode: (state, { payload }: PayloadAction<RegistrationCodePayload>): AuthState => ({
            ...state,
            registrationFormCode: payload.registrationFormCode
        }),
        getOnfidoTokenRequest: (state, { payload: role }: PayloadAction<Role>): AuthState => ({
            ...state,
            onfido: { ...state.onfido, tokens: { ...state.onfido.tokens, [role]: undefined } }
        }),
        getOnfidoTokenSuccess: (
            state,
            { payload: { role, token, allowedDocument } }: PayloadAction<GetOnfidoTokenPayload>
        ): AuthState => ({
            ...state,
            onfido: { ...state.onfido, tokens: { ...state.onfido.tokens, [role]: token }, allowedDocument }
        }),
        setIsInitializing: (state, { payload }: PayloadAction<boolean>): AuthState => ({
            ...state,
            isInitializing: payload
        })
    },
    extraReducers: (builder) => {
        builder.addCase(registrationActions.reset, (): AuthState => INITIAL_STATE)
    }
})

export default slice.reducer

/* ------------- Actions ------------- */

export const actions = {
    ...slice.actions,
    getRegistrationForm: createAction(`${SLICE_NAME}/GET_REGISTRATION_FORM`)
}

/* ------------- Selectors ------------- */

const getAuth = (state: RootState) => state.auth
const _getAccessToken = createSelector([getAuth], (auth) => auth.accessToken)
const getAccessToken = createSelector([_getAccessToken], (token) => token && `Bearer ${token}`)
const getChallenge = createSelector([getAuth], (auth) => auth.challenge)
const getChallengePhoneNumber = createSelector([getAuth], (auth) => auth.challenge.phoneNumber)
const getChallengeToken = createSelector([getAuth], (auth) => auth.challenge.token)
const getIsChallengeFetching = createSelector([getAuth], (auth) => auth.challenge.fetching ?? false)
const getOnfidoAllowedDocument = createSelector([getAuth], (auth) => auth.onfido.allowedDocument)
const getOnfidoToken = (role: Role) => createSelector([getAuth], (auth) => auth.onfido.tokens[role])
const getRegistrationFormId = createSelector([getAuth], (auth) => auth.registrationFormId)
const getRegistrationFormCode = createSelector([getAuth], (auth) => auth.registrationFormCode)
const getIsAuthenticated = createSelector([getAccessToken, getRegistrationFormId], areSet)
const getIsInitializing = (state: RootState) => state.auth.isInitializing

export const selectors = {
    getAccessToken,
    getChallenge,
    getChallengePhoneNumber,
    getChallengeToken,
    getIsAuthenticated,
    getIsChallengeFetching,
    getIsInitializing,
    getOnfidoAllowedDocument,
    getOnfidoToken,
    getRegistrationFormId,
    getRegistrationFormCode
}
