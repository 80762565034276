import React, { createContext, useMemo, useState } from 'react'

import { noop } from 'lodash'

import { CustomUiDataType } from '.'

export declare type CustomUiModalContextType = {
    visible: boolean
    setVisible: (visible: boolean) => void
    uiData: CustomUiDataType
    setUiData: (uiData: CustomUiDataType) => void
}

export const CustomUiModalContext = createContext<CustomUiModalContextType>({
    visible: false,
    setVisible: noop,
    uiData: { title: '' },
    setUiData: noop
})

interface CustomUiModalContextProviderProps {
    children: React.ReactNode
}

const CustomUiModalContextProvider = ({ children }: CustomUiModalContextProviderProps) => {
    const [visible, setVisible] = useState(false)
    const [uiData, setUiData] = useState<CustomUiDataType>({ title: '' })

    const uiModalValue = useMemo(() => {
        return { visible, setVisible, uiData, setUiData }
    }, [visible, uiData])

    return <CustomUiModalContext.Provider value={uiModalValue}>{children}</CustomUiModalContext.Provider>
}

export default CustomUiModalContextProvider
