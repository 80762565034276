import { createAction, createSelector } from '@reduxjs/toolkit'
import { AxiosRequestConfig } from 'axios'

import { SupportedLocale } from '@nickel/i18n/lib/types'

import { Role } from '../../constants/roles'
import { Step } from '../../constants/steps'
import { GetAllowedCountriesCountriesEnum, UpdateLivenessPayloadFacialCaptureVariantEnum } from '../../services'
import {
    adultRegistrationFormResourceApi,
    childRegistrationFormResourceApi,
    registrationFormResourceApi
} from '../../services/utils'
import { selectors as referralCodeSelectors } from '../form/referralCode'
import { selectors as registrationTypeSelectors } from '../form/registrationType'
import { selectors as mailSelectors } from '../mail'

/* ------------- Type & State ------------- */

export type Address = {
    additionalInfo?: string
    formattedAddress?: string
    label?: string
    locality?: string
    postalCode?: string
    administrativeArea?: string
    streetName?: string
    streetNumber?: string
    country?: string
}

export type InitRegistrationPayload = {
    country: GetAllowedCountriesCountriesEnum
    language?: SupportedLocale
    registrationType?: string
    serial?: string
}
export type FiscalAddress = {
    additionalInfo?: string
    formattedAddress?: string
    label?: string
    locality?: string
    postalCode?: string
    administrativeArea?: string
    streetName?: string
    streetNumber?: string
    country?: string
}

/* ------------- Slice & Reducers ------------- */

const SLICE_NAME = '@@registration'

/* ------------- Actions ------------- */

export const actions = {
    init: createAction<InitRegistrationPayload>(`${SLICE_NAME}/INIT`),
    reset: createAction(`${SLICE_NAME}/RESET`),
    send: createAction<Step>(`${SLICE_NAME}/SEND`)
}

/* ------------- Selectors ------------- */

const getAccountTypeApi = createSelector([registrationTypeSelectors.getIsCnj], (isCnj) =>
    isCnj ? childRegistrationFormResourceApi.updateAccountType : adultRegistrationFormResourceApi.updateAccountType1
)

const getAccountUseApi = createSelector([registrationTypeSelectors.getIsCnj], (isCnj) =>
    isCnj ? childRegistrationFormResourceApi.updateAccountUses : adultRegistrationFormResourceApi.updateAccountUses1
)

const createGetAddressApi = (role: Role) => () => {
    switch (role) {
        case Role.CHILD:
            return childRegistrationFormResourceApi.updateChildPostalAddress
        case Role.GUARDIAN:
            return childRegistrationFormResourceApi.updateGuardianPostalAddress
        case Role.ADULT:
        default:
            return adultRegistrationFormResourceApi.updatePostalAddress
    }
}

const getAdultFiscalAddressApi = () => adultRegistrationFormResourceApi.updateTaxAddress1

const createGetBirthApi = (role: Role) => () => {
    switch (role) {
        case Role.CHILD:
            return childRegistrationFormResourceApi.updateChildBirthData
        case Role.GUARDIAN:
            return childRegistrationFormResourceApi.updateGuardianBirthData
        case Role.ADULT:
        default:
            return adultRegistrationFormResourceApi.updateBirthData
    }
}

const createGetCivilityApi = (role: Role) => () => {
    switch (role) {
        case Role.CHILD:
            return childRegistrationFormResourceApi.updateChildCivilData
        case Role.GUARDIAN:
            return childRegistrationFormResourceApi.updateGuardianCivilData
        case Role.ADULT:
        default:
            return adultRegistrationFormResourceApi.updateCivilData
    }
}

const createGetDocumentApi = (role: Role) => () => {
    switch (role) {
        case Role.CHILD:
            return childRegistrationFormResourceApi.addChildRegistrationDocument
        case Role.GUARDIAN:
            return childRegistrationFormResourceApi.addGuardianRegistrationDocument
        case Role.ADULT:
        default:
            return adultRegistrationFormResourceApi.addRegistrationDocument
    }
}

const getUpdateProfessionApi = createSelector([registrationTypeSelectors.getIsCnj], (isCnj) =>
    isCnj
        ? childRegistrationFormResourceApi.updateProfessionData
        : adultRegistrationFormResourceApi.updateProfessionData1
)

const getFamilyStatusApi = createSelector([registrationTypeSelectors.getIsCnj], (isCnj) =>
    isCnj
        ? childRegistrationFormResourceApi.updateChildFamilyStatus
        : adultRegistrationFormResourceApi.updateFamilyStatus
)

const getFinancialApi = createSelector([registrationTypeSelectors.getIsCnj], (isCnj) =>
    isCnj ? childRegistrationFormResourceApi.updateFinancialData : adultRegistrationFormResourceApi.updateFinancialData1
)

const getPropertyStatusApi = createSelector([registrationTypeSelectors.getIsCnj], (isCnj) =>
    isCnj
        ? childRegistrationFormResourceApi.updatePropertyStatus
        : adultRegistrationFormResourceApi.updatePropertyStatus1
)

const getTaxResidenceApi = createSelector([registrationTypeSelectors.getIsCnj], (isCnj) =>
    isCnj
        ? childRegistrationFormResourceApi.updateChildTaxResidences
        : adultRegistrationFormResourceApi.updateTaxResidences
)

const getTermsAndConditionsApi = createSelector([registrationTypeSelectors.getIsCnj], (isCnj) =>
    isCnj ? childRegistrationFormResourceApi.putTerms : adultRegistrationFormResourceApi.putTerms1
)

const getUsPersonApi = createSelector([registrationTypeSelectors.getIsCnj], (isCnj) =>
    isCnj
        ? childRegistrationFormResourceApi.updateChildUsPersonStatus
        : adultRegistrationFormResourceApi.updateUsPersonStatus
)

const getHostApi = createSelector([registrationTypeSelectors.getIsCnj], (isCnj) =>
    isCnj ? childRegistrationFormResourceApi.updateHost : adultRegistrationFormResourceApi.updateHost1
)

const getAddLivenessDocumentApi = createSelector([registrationTypeSelectors.getIsCnj], (isCnj) => {
    return (
        registrationFormId: string,
        data: { facialCaptureVariant: string; livePhotoId: string },
        options?: AxiosRequestConfig
    ) => {
        return isCnj
            ? childRegistrationFormResourceApi.addOnfidoLivePhoto(
                  registrationFormId,
                  data?.livePhotoId,
                  {
                      facialCaptureVariant:
                          (data?.facialCaptureVariant as UpdateLivenessPayloadFacialCaptureVariantEnum) ?? undefined
                  },
                  options
              )
            : adultRegistrationFormResourceApi.addOnfidoLivePhoto1(
                  registrationFormId,
                  data?.livePhotoId,
                  {
                      facialCaptureVariant:
                          (data?.facialCaptureVariant as UpdateLivenessPayloadFacialCaptureVariantEnum) ?? undefined
                  },
                  options
              )
    }
})

const getUpdateConsentApi = () => registrationFormResourceApi.updateConsent

const getUpdatePrivacyCertificationDateApi = () => registrationFormResourceApi.updatePrivacyCertificationDate

const getUpdateEmailAddressApi = createSelector(
    [registrationTypeSelectors.getIsCnj, mailSelectors.isSetAndValid],
    (isCnj, isMailSet) => {
        if (!isMailSet) return
        return isCnj
            ? childRegistrationFormResourceApi.updateGuardianEmailAddress
            : adultRegistrationFormResourceApi.updateEmailAddress
    }
)

const getUpdateReferralCodeApi = createSelector(
    [referralCodeSelectors.getReferralCode, registrationTypeSelectors.getIsCnj],
    (referralCode, isCnj) => {
        if (!referralCode) return
        return isCnj
            ? childRegistrationFormResourceApi.updateReferralCode
            : adultRegistrationFormResourceApi.updateReferralCode1
    }
)

const getUpdateRegistrationTypeApi = createSelector([registrationTypeSelectors.getIsCnj], (isCnj) => {
    return isCnj
        ? childRegistrationFormResourceApi.changeToChildRegistration
        : adultRegistrationFormResourceApi.changeToAdultRegistration
})

const getBarcodeApi = createSelector([registrationTypeSelectors.getIsCnj], (isCnj) =>
    isCnj ? childRegistrationFormResourceApi.updateBarcode : adultRegistrationFormResourceApi.updateBarcode1
)

const getFinalizeApi = createSelector([registrationTypeSelectors.getIsCnj], (isCnj) =>
    isCnj
        ? childRegistrationFormResourceApi.finalizeRegistration
        : adultRegistrationFormResourceApi.finalizeRegistration1
)

const getCardReceptionModeApi = () => adultRegistrationFormResourceApi.updateCardReceptionMode

const getBankCardTypeApi = () => adultRegistrationFormResourceApi.updateCardType

const getCardPersonalizationApi = () => adultRegistrationFormResourceApi.updateCardPersonalization

const validateEidTermsAndConditions = () => (registrationFormId: string) =>
    registrationFormResourceApi.validateEidPdfDocument(registrationFormId, 'TERMS_AND_CONDITIONS')
const validateEidPrivacyPolicy = () => (registrationFormId: string) =>
    registrationFormResourceApi.validateEidPdfDocument(registrationFormId, 'PRIVACY_POLICY')

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectors = {
    getAccountTypeApi,
    getAccountUseApi,
    getAddLivenessDocumentApi,
    getAdultAddressApi: createGetAddressApi(Role.ADULT),
    getAdultBirthApi: createGetBirthApi(Role.ADULT),
    getAdultCivilityApi: createGetCivilityApi(Role.ADULT),
    getAdultDocumentApi: createGetDocumentApi(Role.ADULT),
    getAdultFiscalAddressApi,
    getBarcodeApi,
    getChildAddressApi: createGetAddressApi(Role.CHILD),
    getChildBirthApi: createGetBirthApi(Role.CHILD),
    getChildCivilityApi: createGetCivilityApi(Role.CHILD),
    getChildDocumentApi: createGetDocumentApi(Role.CHILD),
    getFamilyStatusApi,
    getFinalizeApi,
    getFinancialApi,
    getGuardianAddressApi: createGetAddressApi(Role.GUARDIAN),
    getGuardianBirthApi: createGetBirthApi(Role.GUARDIAN),
    getGuardianCivilityApi: createGetCivilityApi(Role.GUARDIAN),
    getGuardianDocumentApi: createGetDocumentApi(Role.GUARDIAN),
    getHostApi,
    getPropertyStatusApi,
    getTaxResidenceApi,
    getTermsAndConditionsApi,
    getUpdateConsentApi,
    getUpdatePrivacyCertificationDateApi,
    getUpdateEmailAddressApi,
    getUpdateProfessionApi,
    getUpdateReferralCodeApi,
    getUpdateRegistrationTypeApi,
    getUsPersonApi,
    getCardReceptionModeApi,
    getBankCardTypeApi,
    getCardPersonalizationApi,
    validateEidTermsAndConditions,
    validateEidPrivacyPolicy
}
