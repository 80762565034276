/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootState } from '@nickel/forms/redux/reducer'

import { actions as registrationActions } from '../registration'

interface GuardianTerms {
    certifyBeingChildParent: boolean
    certifyChildFirstAccount: boolean
}

interface GuardianTermsState {
    values: GuardianTerms
}

export const INITIAL_STATE: GuardianTermsState = {
    values: {
        certifyBeingChildParent: false,
        certifyChildFirstAccount: false
    }
}

/* ------------- Slice & Reducers ------------- */

const SLICE_NAME = '@@guardianTerms'

const counterSlice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        setValues: (state, { payload }: PayloadAction<GuardianTerms>) => ({
            ...state,
            values: payload
        })
    },
    extraReducers: (builder) => {
        builder.addCase(registrationActions.reset, () => INITIAL_STATE)
    }
})

export default counterSlice.reducer

/* ------------- Actions ------------- */

export const { actions } = counterSlice

/* ------------- Selectors ------------- */

export const selectors = {
    getGuardianTermsData: (state: RootState) => state.guardianTerms.values
}
