import { createSelector } from '@reduxjs/toolkit'

import formsSelectors from '@nickel/forms/redux/selectors'

import { REFERRAL_CODE } from '../../fields/names'
import { REFFERAL_CONSTANTS } from '../../screens/AllElements/constants'

/* ------------- Selectors ------------- */

const getReferralCode = createSelector(
    [formsSelectors.getFieldValue<string>(REFERRAL_CODE)],
    (referralCode) => referralCode?.toUpperCase() ?? ''
)

const isReferralCodeValid = createSelector([getReferralCode], (referralCode) => checkRefferalCode(referralCode))
const isLengthTooShort = createSelector(
    [getReferralCode],
    (referralCode) => referralCode.length < REFFERAL_CONSTANTS.TEXT_SIZE
)
const isReferralCodeEmpty = createSelector([getReferralCode], (referralCode) => referralCode.trim() === '')
const isLengthTooLong = createSelector(
    [getReferralCode],
    (referralCode) => referralCode.length > REFFERAL_CONSTANTS.TEXT_SIZE
)
const isFormatCorrect = createSelector([getReferralCode], (referralCode) =>
    checkRefferalCodeIsAplhaNumeric(referralCode)
)
function checkRefferalCode(referralCode: string): boolean {
    return referralCode.trim() !== '' && checkRefferalCodeIsAplhaNumeric(referralCode)
}
function checkRefferalCodeIsAplhaNumeric(referralCode: string): boolean {
    const result = referralCode.match(/^[a-zA-Z0-9]*$/)
    return result == null ? false : result[0] === referralCode
}
const isRefferalCodeComplete = createSelector(
    [isLengthTooShort, isLengthTooLong, isReferralCodeValid],
    (tooshort, toolong, valid) => !tooshort && !toolong && valid
)
export const selectors = {
    getReferralCode,
    isReferralCodeValid,
    isLengthTooShort,
    isLengthTooLong,
    isFormatCorrect,
    isReferralCodeEmpty,
    isRefferalCodeComplete
}
