import { call, delay, put, select } from 'redux-saga/effects'

import scanner from '@nickel/scan'
import { actions as stimulusActions, createStimulus } from '@nickel/stimulus/stimulus'
import { setB64ToLocalForage } from '@nickel/utils/lib/file'
import { isTerminal } from '@nickel/utils/lib/web'

import { StimulusType } from '../../config/errors'

import { actions as scannerActions, ScanPayload, selectors as scannerSelectors } from '.'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let DWObject: any = null

if (isTerminal && !window.Cypress) scanner.install()

export function* scanSetup() {
    const isScannerWarm: boolean = yield select(scannerSelectors.getIsScannerWarm)
    if (isScannerWarm) return
    while (!DWObject) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        DWObject = yield call(scanner.load)
        yield delay(1000)
    }
    yield put(scannerActions.scanSetupSuccess())
}

export function* scan({ payload: { onComplete } }: { payload: ScanPayload }): Generator {
    const isScannerWarm = yield select(scannerSelectors.getIsScannerWarm)
    if (!isScannerWarm) {
        yield delay(1000)
        yield call(scan, { payload: { onComplete } })
        return
    }
    try {
        yield put(scannerActions.scanBegin())
        yield scanner.scan({
            scannedImage: DWObject,
            format: 'jpeg',
            resolution: 135
        })
        DWObject.GetSelectedImagesSize(1)
        DWObject.SetSelectedImageIndex(0, 0)
        const imagedata = DWObject.SaveSelectedImagesToBase64Binary()
        const dataURI = `data:image/png;base64,${imagedata}`
        // @ts-expect-error TODO
        const fileId: string = yield call(setB64ToLocalForage, dataURI)
        yield put(scannerActions.scanSuccess())
        onComplete(fileId)
    } catch (err) {
        yield put(scannerActions.scanFailure())
        yield put(stimulusActions.handleStimulus(createStimulus(StimulusType.SCANNER_FAILURE)))
    }
}
