import { createAction, createSlice, createSelector } from '@reduxjs/toolkit'

import { RootState } from '..'
import { actions as registrationActions } from '../registration'

/* ------------- Type & State ------------- */

type ScannerState = {
    status: 'COLD' | 'SCANNING' | 'WARM'
}

export interface ScanPayload {
    onComplete: (id: string) => unknown
}

export const INITIAL_STATE: ScannerState = {
    status: 'COLD'
}

/* ------------- Slice & Reducers ------------- */

const SLICE_NAME = '@@scanner'

const slice = createSlice({
    name: SLICE_NAME,
    initialState: INITIAL_STATE,
    reducers: {
        scanBegin: (state): ScannerState => ({
            ...state,
            status: 'SCANNING'
        }),
        scanSuccess: (state): ScannerState => ({
            ...state,
            status: 'WARM'
        }),
        scanFailure: (state): ScannerState => ({
            ...state,
            status: 'WARM'
        }),
        scanSetupSuccess: (state): ScannerState => ({
            ...state,
            status: 'WARM'
        })
    },
    extraReducers: (builder) => {
        builder.addCase(registrationActions.reset, (): ScannerState => INITIAL_STATE)
    }
})

export default slice.reducer

/* ------------- Actions ------------- */

export const actions = {
    ...slice.actions,
    scan: createAction<ScanPayload>(`${SLICE_NAME}/SCAN`),
    scanSetup: createAction(`${SLICE_NAME}/SCAN_SETUP`)
}

/* ------------- Selectors ------------- */

const getStatus = (state: RootState) => state.scanner.status
const getIsScannerWarm = createSelector([getStatus], (s) => s === 'WARM')
const getIsScanning = createSelector([getStatus], (s) => s === 'SCANNING')

export const selectors = {
    getIsScannerWarm,
    getIsScanning
}
