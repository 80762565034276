import React, { useCallback } from 'react'

import { useFormField } from '@nickel/forms/hooks'
import { FieldProps } from '@nickel/forms/types'
import { SupportedLocale } from '@nickel/i18n/lib/types'
import { requiredString } from '@nickel/kyc/fields/validators'
import Field from '@nickel/ui/components/legacy/form/Field'
import Select from '@nickel/ui/components/legacy/form/Select'
import { Options } from '@nickel/ui/types'

import { LANGUAGE } from './names'
import { LanguageContainer } from './styles'

interface Props extends FieldProps<SupportedLocale | string> {
    onSelectChange?: (value: SupportedLocale) => void
    options: Options<SupportedLocale | string>
    path?: string
}

const LanguageField = ({ onSelectChange, options, path = LANGUAGE, ...props }: Props) => {
    const {
        error,
        inputProps: { onChange, ...inputProps }
    } = useFormField<SupportedLocale | string>(path, [requiredString], props)

    const _onChange = useCallback(
        (value) => {
            onChange(value)
            onSelectChange?.(value)
        },
        [onChange, onSelectChange]
    )
    return (
        <LanguageContainer>
            <Field error={error} htmlFor={path}>
                <Select {...inputProps} name={path} onChange={_onChange} options={options} />
            </Field>
        </LanguageContainer>
    )
}

export default LanguageField
