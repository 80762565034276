import { AxiosError } from 'axios'
import { DefaultOptions } from 'react-query'

import { actions as stimulusActions, createStimulus } from '@nickel/stimulus/stimulus'

import store from '../../redux'
import { isAxiosError } from '../../redux/utils/sagas'
import { StimulusType } from '../errors'

export const getStimulusTypeFromApiResponse = (error: unknown) => {
    if (!isAxiosError(error)) return StimulusType.DEFAULT
    if (!error.response) return StimulusType.NICKEL_SERVICE_UNAVAIBLE
    switch (error.response.status) {
        case 401:
        case 403:
            return StimulusType.UNAUTHORIZED
        case 408:
            return StimulusType.NICKEL_SERVICE_UNAVAIBLE
        case 400:
            return StimulusType.BAD_REQUEST
        case 500:
            return StimulusType.SERVER_ERROR
        default:
            return StimulusType.DEFAULT
    }
}

export const handleQueryError = (error: unknown, name = getStimulusTypeFromApiResponse(error)) => {
    if (isAxiosError(error)) {
        const stimulus = createStimulus(name, error.response?.data)
        store.dispatch(stimulusActions.handleStimulus(stimulus))
    } else {
        store.dispatch(stimulusActions.handleStimulus(createStimulus()))
    }
}

export const handleMutationError = (
    error: unknown,
    _variables: unknown,
    _context: unknown,
    name = getStimulusTypeFromApiResponse(error)
) => {
    if (isAxiosError(error)) {
        const stimulus = createStimulus(name, error.response?.data)
        store.dispatch(stimulusActions.handleStimulus(stimulus))
    } else {
        store.dispatch(stimulusActions.handleStimulus(createStimulus()))
    }
}

export const handleErrorSilently = (_error: unknown) => {
    // Do nothing
}

const queryConfig: DefaultOptions<AxiosError> = {
    queries: {
        refetchOnWindowFocus: false,
        onError: handleQueryError
    },
    mutations: { onError: handleMutationError }
}

export default queryConfig
