import React, { useContext, useEffect } from 'react'

import { useSelector } from 'react-redux'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { SupportedCountryISO2 } from '@nickel/l10n/types'
import { SessionTokenContext } from '@nickel/location/sessionTokenContext'
import { isSet } from '@nickel/utils/lib/common'
import { isTerminal, isTerminalWeb } from '@nickel/utils/lib/web'

import useUiModal from '../components/CustomModal/useModal'
import userIdleModal from '../constants/modals/userIdle'
import { useCheckAllIdentityDocuments } from '../hooks/useCheckAllIdentityDocuments'
import useIdle from '../hooks/useIdle'
import useLocationTracking from '../hooks/useLocationTracking'
import usePreloadNextStep from '../hooks/usePreloadNextStep'
import { getAllowedCountryISO2, getSupportedCountryISO2 } from '../l10n/utils'
import { getNavigatorCountry } from '../redux/form/utils'
import { RouteMapping, selectors as stepSelectors } from '../redux/navigation'
import Country from '../screens/Country'

import screenMapper from './mapper'
import { Content } from './styles'
import { DefaultSupportedLocalePath, SupportedLocalePath } from './types'

const Router = () => {
    const isLastStep = useSelector(stepSelectors.getIsLastStep)
    const routesMapping = useSelector(stepSelectors.getRoutesMapping)
    const { generate, token } = useContext(SessionTokenContext)
    const navigatorCountry = getNavigatorCountry()
    const location = useLocation()
    const { refetchDocumentsIfNeeded } = useCheckAllIdentityDocuments()
    usePreloadNextStep()
    useLocationTracking()
    const { showModal } = useUiModal()

    useEffect(() => {
        refetchDocumentsIfNeeded()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    useEffect(() => {
        if (!isSet(token)) generate()
    }, [generate, token])

    useIdle(
        (idle) => {
            if (idle) showModal(userIdleModal())
        },
        window.REACT_APP_USER_IDLE_MODAL_DELAY,
        (isTerminal || isTerminalWeb) && !isLastStep
    )

    return (
        <Content>
            <Switch>
                {routesMapping.map(({ component, route }: RouteMapping) => (
                    // @ts-expect-error TODO
                    <Route component={screenMapper[component]} exact key={route} path={route} />
                ))}
                {Object.values(SupportedLocalePath).map((locale) => (
                    <Route path={`/${locale}`} key={locale} component={Country} />
                ))}
                {Object.keys(DefaultSupportedLocalePath).map((country) => (
                    <Redirect
                        key={country}
                        from={`/${country}`}
                        to={{
                            ...location,
                            pathname: `/${DefaultSupportedLocalePath[country as SupportedCountryISO2]}`
                        }}
                    />
                ))}
                <Redirect
                    from="/"
                    to={{
                        ...location,
                        pathname: `/${
                            DefaultSupportedLocalePath[getSupportedCountryISO2(getAllowedCountryISO2(navigatorCountry))]
                        }`
                    }}
                />
                <Redirect to={routesMapping[0].route} />
            </Switch>
        </Content>
    )
}

export default Router
